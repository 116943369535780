/*
 * workaround for materialize-css:
 * we want to use the validate class for CSS,
 * but we don't want the automatic validation behavior,
 * so we copy here all rules that involve the "validate" class, renamed to "my-validate"
 */
input:not([type]).my-validate + label,
input[type='text'].my-validate + label,
input[type='password'].my-validate + label,
input[type='email'].my-validate + label,
input[type='url'].my-validate + label,
input[type='time'].my-validate + label,
input[type='date'].my-validate + label,
input[type='datetime'].my-validate + label,
input[type='datetime-local'].my-validate + label,
input[type='tel'].my-validate + label,
input[type='number'].my-validate + label,
input[type='search'].my-validate + label,
input[type='checkbox'].my-validate + label,
textarea.materialize-textarea.my-validate + label,
.datepicker.my-validate + .picker + label {
  width: 100%;
  pointer-events: none;
}
.input-field.col .prefix ~ .my-validate ~ label {
  width: calc(100% - 3rem - 1.5rem);
}
.input-field .prefix ~ .my-validate ~ label {
  margin-left: 3rem;
  width: 92%;
  width: calc(100% - 3rem);
}
.input-field label {
  left: 0;
}
.input-field label:after {
  transform: translateY(-120%);
}
.input-field label.my-active {
  font-size: 0.8rem;
  -webkit-transform: translateY(-140%);
  transform: translateY(-140%);
}
.input-field label.active:after,
.input-field label.my-active:after {
  transform: translateY(0);
}
.select-wrapper + label {
  width: 100%;
}
.select-wrapper + label:after {
  content: attr(data-error);
  color: #f44336;
  display: block;
  position: absolute;
  top: 88px;
  font-size: 14px;
}
input.datepicker + .picker + label:after {
  display: block;
  content: '';
  position: absolute;
  top: 60px;
  opacity: 0;
  transition: 0.2s opacity ease-out, 0.2s color ease-out;
}
input.datepicker.invalid + .picker + label:after {
  content: attr(data-error);
  color: #f44336;
  opacity: 1;
  width: 500px;
}
.invalid + label:after,
.invalid + .picker + label:after {
  font-size: 1rem;
}
.collapsible > li .collapsible-body {
  display: block;
}
.form-autocomplete-select .autocomplete-content {
  position: absolute;
}
body input[type='text']:focus:not([readonly]).invalid {
  border-bottom: 1px solid #f44336;
  box-shadow: 0 1px 0 0 #f44336;
}
.picker {
  outline: none;
}
html,
body {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
}
.main-title-color {
  color: #283593;
}
.spinner-color {
  border-color: #283593;
}
.main-background-color {
  background-color: #283593;
}
.main-text-color {
  color: #ffffff !important;
}
.light-background-color {
  background-color: #eee;
}
.danger-text-color {
  color: #b71c1c !important;
}
.light-danger-background-color {
  background-color: #ffebee !important;
}
.dark-button-text {
  color: #283593;
}
.light-text {
  color: #9e9e9e;
}
.validation-error-text-color {
  color: #f44336 !important;
}
.preloader-wrapper {
  overflow: hidden;
}
.preloader-wrapper-center.preloader-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.preloader-wrapper-center.preloader-wrapper .spinner-layer {
  border-color: #283593;
}
h1 {
  color: #283593;
}
body h1 {
  font-size: 2.2rem;
}
strong {
  font-weight: bold;
}
nav.navigation {
  background-color: #283593;
}
button.btn:hover.primary,
button:focus.primary {
  background-color: #283593;
}
button.btn:hover.secondary,
button:focus.secondary {
  background-color: #eee;
}
button.primary {
  background-color: #283593;
}
button.solid {
  padding-left: 1rem;
}
button.secondary {
  background-color: #eee;
  color: #9e9e9e;
}
button.outlined {
  background: none;
  color: #283593;
  border: 1px solid #0000001f;
  border-radius: 4px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 15px;
}
button.outlined:focus {
  background: none;
}
button.btn-flat {
  background-color: #eee;
}
button.text {
  background-color: transparent;
  border: none;
  color: #283593;
  font-weight: bold;
}
button.text:hover {
  transform: scale(1.01);
}
button.text:active,
button.text:focus {
  background-color: transparent;
}
.remove > button.btn-flat,
.remove > button.primary {
  background-color: #ffebee !important;
  color: #343434;
}
.remove-btn > button.primary {
  background-color: #f44336 !important;
  color: #343434;
}
input[type='checkbox']:checked + label:before {
  border-color: #283593;
}
input[type='checkbox'].filled-in:checked + label:after {
  border-color: #283593;
  background-color: #283593;
}
body .switch label input[type='checkbox']:checked + .lever::after {
  background-color: #283593;
}
body .switch label input[type='checkbox']:checked + .lever {
  background-color: #283593;
}
.add-entity {
  position: fixed;
  right: 4em;
  bottom: 3em;
  z-index: 2;
}
@media (max-width: 600px) {
  body .add-entity {
    right: 1em;
    bottom: 1em;
  }
}
@media (min-width: 801px) {
  .small-screen-caption {
    display: none;
    visibility: hidden;
  }
}
@media (max-width: 800px) {
  .small-screen-caption {
    font-weight: bold;
    white-space: nowrap;
  }
  list-loader table:not(.always-table):not(.picker__table) th {
    display: none;
  }
  body .container > list-loader.uncontainer > .list-loader-content > table:not(.always-table) td,
  body .container > list-loader.uncontainer > .list-loader-content > table:not(.always-table) td:first-child,
  body .container > list-loader.uncontainer > .list-loader-content > table:not(.always-table) td:last-child {
    padding: 10px 1rem;
  }
  body .container > .uncontainer {
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  body .container > list-loader.uncontainer {
    padding-left: 0;
    padding-right: 0;
  }
  body .container > list-loader.uncontainer > *,
  body .container > list-loader.uncontainer td:first-child,
  body .container > list-loader.uncontainer td:last-child,
  body .container > list-loader.uncontainer th:first-child,
  body .container > list-loader.uncontainer th:last-child {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  body .container > list-loader.uncontainer > .list-loader-content {
    padding-left: 0;
    padding-right: 0;
  }
  .list-loader-content > table:not(.always-table) td.actions-cell {
    margin-left: -3px;
  }
  list-loader table:not(.always-table):not(.picker__table) td {
    padding: 10px 5px;
  }
  list-loader table:not(.always-table):not(.picker__table) td > * {
    display: inline-block;
  }
  list-loader table:not(.always-table):not(.picker__table) td:last-child:before {
    display: none;
    width: 0;
  }
  list-loader table:not(.always-table) tr:last-child > td:last-child {
    border-bottom: none;
  }
}
.no-results {
  font-style: italic;
  font-size: 1.2em;
}
i.action {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
i.action.action-view {
  border-radius: 2px;
  color: #283593;
}
i.action.action-remove {
  border-radius: 2px;
  color: #283593;
}
i.action.action-remove:hover {
  background-color: #eeeeee;
}
i.action.action-remove:active {
  background-color: #e0dede;
}
.missing-value {
  font-style: italic;
  color: #9e9e9e;
}
.placeholderSelectText {
  top: 0px !important;
}
.form-autocomplete-select {
  margin-top: 1em;
  margin-bottom: 0.5em;
}
.form-autocomplete-select .select-title {
  font-size: 1.2em;
}
.form-autocomplete-select .input-field {
  display: flex;
}
.form-autocomplete-select .autocomplete-content {
  margin-top: 3.5rem;
  left: 0;
}
.form-autocomplete-select label.active > i {
  display: none;
}
.form-autocomplete-select i.left {
  margin-right: 5px;
  margin-top: -3px;
}
.form-autocomplete-select i.clear {
  color: #9e9e9e;
  line-height: 3rem;
  cursor: pointer;
}
.form-autocomplete-select .form-select-search-action > span {
  color: #757575;
  font-style: italic;
}
.form-autocomplete-select .loading-results {
  padding: 0.5em;
  overflow-y: hidden;
}
.form-autocomplete-select .more-results :hover {
  background-color: white;
  cursor: default;
}
.form-autocomplete-select .more-results > span {
  color: #9e9e9e;
  font-style: italic;
}
.form-autocomplete-select .selected-entities {
  margin-top: -10px;
  margin-bottom: 1em;
}
.form-autocomplete-select .selected-entities > li > div {
  display: inline-block;
}
.form-autocomplete-select .selected-entities span.action {
  display: block;
  min-height: 36px;
  margin-top: 5px;
}
.form-autocomplete-select .selected-entities span.action > div {
  margin-left: 32px;
}
.validation-error {
  color: #f44336 !important;
}
.form-section-title {
  font-size: 1.2em;
  margin: 1em 0;
  color: #283593;
}
form {
  max-width: 600px;
}
form .checkbox-container {
  padding: 1em 0;
}
form .inline-input > div.input-field,
form .inline-input .checkbox-container {
  display: inline-block;
}
form .validation-error {
  margin-top: 1em;
}
form .collapsible-body {
  padding: 1em;
}
form .collapsible-header-with-actions {
  line-height: 1rem;
  padding: 1rem;
}
form .attribute-meta-actions {
  float: right;
  margin-top: -1rem;
}
form .info {
  margin: 0 24px;
}
form .add-attribute {
  margin: 1em 24px;
}
form .copy-dimensions {
  display: block;
  margin: 1em 24px;
}
form li.error .collapsible-header,
form li.error .collapsible-header .missing-value {
  color: #f44336 !important;
}
form .input-field label {
  white-space: nowrap;
}
.form-input-line {
  display: flex;
  align-items: center;
}
.form-input-line > * {
  margin-right: 2em;
}
.form-input-line > *:last-child {
  margin-right: 0;
}
.form-input-line > i.action {
  margin-right: 1em;
}
.form-input-line > .no-shrink {
  flex-shrink: 0;
}
.form-input-line input {
  min-width: 22ch;
}
.form-input-line.form-input-line-smaller input {
  min-width: 14ch;
}
.form-input-line-expand {
  display: flex;
  align-items: center;
}
.form-input-line-expand > * {
  margin-right: 2em;
}
.form-input-line-expand > *:last-child {
  margin-right: 0;
}
.form-input-line-expand > i.action {
  margin-right: 1em;
}
.form-input-line-expand > .no-shrink {
  flex-shrink: 0;
}
.form-input-line-expand input {
  min-width: 22ch;
}
.form-input-line-expand.form-input-line-smaller input {
  min-width: 14ch;
}
.form-input-line-expand > * {
  flex: 1;
}
.form-input-line-expand > *.no-expand {
  flex: 0;
}
.form-buttons {
  letter-spacing: 1em;
}
.form-buttons .validation-error {
  letter-spacing: normal;
  margin: 0 0 1em;
}
.form-buttons-bottom-sticky {
  position: sticky !important;
  bottom: 0;
  left: 20px;
  background-color: white !important;
}
@media (max-width: 600px) {
  form .form-input-line.form-input-line-breakable {
    display: block;
  }
  form .form-input-line.form-input-line-breakable > * {
    margin-right: 0;
  }
}
form.compact {
  display: flex;
  max-width: inherit;
  flex-wrap: wrap;
}
form.compact > * {
  margin-right: 2em;
}
form.compact .order-by {
  flex-wrap: wrap;
  margin-right: auto;
}
form.compact .order-by > span {
  color: #9e9e9e;
}
form.compact .form-input-line > * {
  margin-right: 1em;
}
form.compact .form-autocomplete-select {
  margin-top: 0;
}
form.compact .form-autocomplete-select .input-field,
form.compact form-text-input .input-field,
form.compact form-date-input .input-field {
  display: inline-flex;
}
form.compact .form-autocomplete-select .input-field label,
form.compact form-text-input .input-field label,
form.compact form-date-input .input-field label {
  top: 0;
}
form.compact .form-autocomplete-select .input-field label.active,
form.compact form-text-input .input-field label.active,
form.compact form-date-input .input-field label.active {
  top: 0.8rem;
}
form.compact .form-autocomplete-select .input-field i.clear,
form.compact form-text-input .input-field i.clear,
form.compact form-date-input .input-field i.clear {
  line-height: 2rem;
}
form.compact .form-autocomplete-select .autocomplete-content {
  margin-top: 2.5rem;
}
form.compact input,
form.compact .select-wrapper input.select-dropdown {
  height: 2rem;
  line-height: 2rem;
  min-width: 15rem;
}
form.compact label > i {
  margin-right: 5px;
}
form.compact input:not([type]) + label:after,
form.compact input[type='text'] + label:after {
  top: 46px;
}
form-date-time-input {
  display: flex;
}
form-date-time-input > * {
  margin-right: 1em;
}
form-date-time-input > *:last-child {
  margin-right: 0;
}
input[type='text']:focus:not([readonly]).no-matching-results,
input.no-matching-results {
  border-bottom: 1px solid #f44336;
  box-shadow: 0 1px 0 0 #f44336;
}
input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='text']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
body input:not([type]):focus:not([readonly]),
.input-field input:not([type]):focus:not([readonly]),
.input-field input[type='text']:focus:not([readonly]),
.input-field input[type='password']:focus:not([readonly]),
.input-field textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #283593;
  box-shadow: 0 1px 0 0 #283593;
}
.input-field input:not([type]):focus:not([readonly]) + label,
.input-field input[type='text']:focus:not([readonly]) + label,
.input-field input[type='password']:focus:not([readonly]) + label,
.input-field textarea.materialize-textarea:focus:not([readonly]) + label {
  color: #283593;
}
body .dropdown-content li > a,
body .dropdown-content li > span {
  color: #283593;
}
.input-field i.prefix.active {
  color: #283593;
}
.input-field input:not([type]).valid,
.input-field input[type='text'].valid,
.input-field input[type='password'].valid,
.input-field textarea.materialize-textarea.valid {
  border-bottom: 1px solid #9e9e9e;
  box-shadow: none;
}
.input-field input:not([type]):focus.valid,
.input-field input[type='text']:focus.valid,
.input-field input[type='password']:focus.valid,
.input-field textarea.materialize-textarea:focus.valid {
  border-bottom: 1px solid #283593;
  box-shadow: none;
}
body [type='radio']:checked + label:after,
[type='radio'].with-gap:checked + label:after {
  background-color: #283593;
}
body [type='radio']:checked + label:after,
[type='radio'].with-gap:checked + label:before,
[type='radio'].with-gap:checked + label:after {
  border: 2px solid #283593;
}
body [type='radio']:not(:checked) + label:before,
[type='radio']:not(:checked) + label:after {
  border: 2px solid #283593;
}
form-date-input .picker__day.picker__day--today,
form-date-time-input .picker__day.picker__day--today {
  color: #283593;
}
form-date-input .picker__day--selected,
form-date-time-input .picker__day--selected,
form-date-input .picker__day--selected:hover,
form-date-time-input .picker__day--selected:hover,
form-date-input .picker--focused .picker__day--selected,
form-date-time-input .picker--focused .picker__day--selected {
  background-color: #283593;
}
form-date-input .picker__date-display,
form-date-time-input .picker__date-display,
form-date-input .picker__weekday-display,
form-date-time-input .picker__weekday-display {
  background-color: #283593;
}
form-date-input .picker__today,
form-date-time-input .picker__today,
form-date-input .picker__close,
form-date-time-input .picker__close {
  background-color: #eee;
  color: #283593;
}
.container .collection a.collection-item {
  color: #283593;
}
span.no-matching-results {
  color: #f44336 !important;
  font-style: italic;
}
.tour-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.3);
}
.tour-highlight {
  position: relative;
  z-index: 100000;
  pointer-events: none;
}
#horizontal-nav-trials.tour-highlight,
#nav-menu.tour-highlight,
#horizontal-nav-invite.tour-highlight {
  background-color: #283593;
}
#first-trial-row-name.tour-highlight,
#first-trial-row-collected.tour-highlight {
  background-color: white;
}
.chart-group.tour-highlight {
  pointer-events: all;
}
#define-first-trial.tour-highlight {
  background-color: white;
}
#add-trial.tour-highlight {
  position: fixed;
}
div.hopscotch-bubble .hopscotch-bubble-container {
  z-index: 100000;
  background-color: white;
}
.server-error-popup h1 {
  color: #f44336 !important;
}
.server-error-popup primary-button {
  margin-right: 1em;
}
loading-indicator {
  display: flex;
  align-items: center;
}
loading-indicator .spinner-layer {
  border-color: #ddd;
}
loading-indicator > span {
  margin-left: 1em;
  font-style: italic;
  font-size: 1.2em;
  color: #9e9e9e;
}
loading-indicator.fill-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  justify-content: center;
  z-index: 1;
}
.load-more-results {
  display: block;
  margin-top: 2em;
  text-align: center;
}
.load-more-results span.action {
  color: #283593;
  font-weight: bold;
}
.google-play,
.app-store {
  display: inline-block;
  text-align: center;
}
.google-play div,
.app-store div {
  height: 40px;
}
.google-play div {
  background: url(/static/1f200695fb6d5cca47a886915a637af1.png) no-repeat 50% 50%;
  width: 133px;
}
.app-store div {
  background: url(/static/0a4b791352b1c12ba807eb78a991f822.png) no-repeat 50% 50%;
  width: 135px;
}
flat-button {
  outline: none;
  text-transform: uppercase;
}
flat-button span.action.flat-button-disabled {
  cursor: default !important;
  color: #9e9e9e;
}
list-loader a,
.striped a {
  color: #283593;
  cursor: pointer;
}
.fieldtrials-table a,
.striped a {
  color: #283593;
  cursor: pointer;
}
list-loader .anchor-with-link {
  display: inline-block;
}
list-loader flat-button > span.action {
  color: #757575;
}
body list-loader .dropdown-content li > a {
  color: #757575;
}
list-loader .list-loader-content > table > thead,
list-loader .list-loader-content > table > tbody > tr {
  border-bottom: 1px solid #d0d0d0;
  border-top: 1px solid #d0d0d0;
}
.container > .uncontainer {
  display: block;
  margin-left: -5.55555556%;
  margin-right: -5.55555556%;
  padding-left: 2rem;
  padding-right: 2rem;
}
.container > list-loader.uncontainer {
  padding-left: 0;
  padding-right: 0;
}
.container > list-loader.uncontainer > *,
.container > list-loader.uncontainer td:first-child,
.container > list-loader.uncontainer td:last-child:not(.actions-cell),
.container > list-loader.uncontainer th:first-child,
.container > list-loader.uncontainer th:last-child {
  padding-left: 2rem;
  padding-right: 2rem;
}
.container > list-loader.uncontainer .picker__table td:last-child,
.container > list-loader.uncontainer .picker__table td:first-child {
  padding-left: 0;
  padding-right: 0;
}
.container > list-loader.uncontainer > .list-loader-content {
  padding-left: 0;
  padding-right: 0;
}
list-loader.uncontainer {
  margin-bottom: 6em;
}
@media only screen and (min-width: 601px) {
  .container > .uncontainer {
    display: block;
    margin-left: -8.82352941%;
    margin-right: -8.82352941%;
  }
}
@media only screen and (min-width: 993px) {
  .container > .uncontainer {
    display: block;
    margin-left: -21.42857143%;
    margin-right: -21.42857143%;
  }
}
flat-button > span {
  margin-right: 1em;
}
flat-button.remove > span.action > i {
  border-radius: 4px;
}
flat-button.remove > span.action > i:hover {
  background-color: #eeeeee;
}
flat-button.remove > span.action > i:active {
  background-color: #e0dede;
}
flat-button.no-margin > span.action > div {
  margin-left: 0;
  margin-right: 0;
}
flat-button.no-icon > span.action {
  border-radius: 4px;
}
flat-button.no-icon > span.action > div {
  margin-left: 0;
  padding: 0 8px;
}
flat-button.no-icon > span.action:hover {
  background-color: #eeeeee;
}
flat-button.no-icon > span.action:active {
  background-color: #e0dede;
}
.browser-not-supported {
  background: url(/static/17e38cb39ce94d5e7880382f335097df.png) no-repeat 50% 0%;
  background-size: contain;
  max-width: 500px;
  margin: 1em auto;
  padding: 120px 2em;
  font-size: 1.2em;
  text-align: center;
}
.info {
  color: #9e9e9e;
  font-style: italic;
}
.info > i.left {
  margin-right: 0;
}
.info > div {
  margin-left: 2.5em;
}
span.action {
  display: inline-block;
  line-height: 26px;
  cursor: pointer;
  color: #283593;
}
span.action > i {
  margin-right: 5px;
  line-height: inherit;
}
span.action > div {
  margin-left: 32px;
  line-height: inherit;
}
span.action.remove {
  color: inherit;
}
span.action.remove > i {
  border-radius: 2px;
  color: #283593;
}
span.action.remove > i:hover {
  background-color: #eeeeee;
}
span.action.remove > i:active {
  background-color: #e0dede;
}
table th > i.material-icons {
  display: none;
  font-size: 20px;
  margin-right: 5px;
}
table th {
  color: #757575;
}
table th.is-sorted {
  color: inherit;
}
table th.is-sorted > i.material-icons {
  display: inline-block;
}
table actions-menu flat-button > .action {
  width: auto;
  margin: 0;
}
table td.actions-cell {
  position: relative;
  padding: 5px;
}
table td.actions-cell actions-menu .dropdown-content {
  top: 5px;
  right: 5px;
  min-width: 220px;
}
.actions-menu-container {
  position: relative;
}
actions-menu {
  float: right;
}
actions-menu .dropdown-content {
  top: 0;
  right: 0;
  opacity: 1;
  display: block;
}
actions-menu a.remove {
  color: #b71c1c !important;
}
body .progress {
  background-color: rgba(0, 200, 83, 0.2);
}
body .progress .determinate {
  background-color: #283593;
}
forms-stack qt-popup {
  z-index: 99998;
}
forms-stack nav {
  box-shadow: none;
  background-color: transparent;
}
forms-stack nav .breadcrumb {
  color: #9e9e9e;
}
forms-stack nav .breadcrumb:last-child {
  color: #283593;
}
forms-stack nav .breadcrumb:before {
  color: #9e9e9e;
}
forms-stack h1 {
  display: none;
}
forms-stack h1.popup-title {
  display: block;
  margin: 0 0 1em;
  font-size: 2rem;
}
forms-stack form.card {
  margin: 0;
  box-shadow: none;
}
forms-stack form.card .card-content {
  padding: 0 0 20px 0;
}
forms-stack form.card .card-action {
  padding: 20px 0;
}
forms-stack .picker--opened .picker__holder {
  background: none;
}
body .card-tabs {
  display: flex;
  border-bottom: 1px solid #eee;
}
.tabs-decorator {
  display: flex;
  align-items: center;
  padding-left: 0.5em;
}
.tabs-decorator .new {
  color: #283593;
  margin-right: 0.5em;
  cursor: pointer;
}
body .tabs {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  height: auto;
}
body .tabs .tab.error > a {
  color: #f44336;
}
body .tabs .tab.active {
  border-bottom: 2px solid #283593;
}
body .tabs .tab.active > a {
  color: #283593;
}
body .tabs .tab.active > a:hover {
  color: #283593;
  cursor: initial;
}
body .tabs .tab a {
  color: #283593;
  display: flex;
}
body .tabs .tab a:hover {
  color: #283593;
  cursor: pointer;
}
body .tabs .tab a i {
  line-height: inherit;
  margin-right: 5px;
}
@media only screen and (max-width: 992px) {
  body .tabs .tab {
    flex: initial;
  }
}
#define-first-trial {
  font-family: 'Shadows Into Light Two', cursive;
  font-size: 40px;
  color: #9e9e9e;
  max-width: 551px;
  margin: 0 auto;
  margin-top: -4rem;
  cursor: pointer;
}
#define-first-trial-arrow {
  cursor: pointer;
}
#define-first-trial-arrow path.arrow {
  fill: none;
  stroke: #eee;
  stroke-width: 8;
  stroke-linecap: round;
  stroke-dasharray: 10, 20;
}
#define-first-trial-arrow marker path {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #eee;
}
@media only screen and (max-width: 800px) {
  body #define-first-trial {
    font-size: 20px;
    margin-top: -5rem;
    margin-right: 4rem;
  }
}
@media (max-width: 830px), (max-height: 810px) {
  #define-first-trial-arrow {
    display: none;
  }
}
body div.hopscotch-bubble .hopscotch-content {
  padding-top: 2rem;
}
.dialog-title {
  color: #283593;
  font-weight: 400;
  font-size: 2em;
}
.dialog-title-select-dataset {
  margin-bottom: 1em;
}
form-select.allow-empty .dropdown-content li.selected:first-child {
  background-color: inherit;
}
form-select.allow-empty .dropdown-content li:first-child > span {
  color: #9e9e9e;
}
form-select.spacing-top {
  display: block;
  margin-top: 2em;
}
form-select.filled > div.input-field.input-field-select {
  margin: 0;
}
form-select.filled > div.input-field.input-field-select > div input {
  background-color: #eee;
  border-radius: 4px;
  border: none;
  padding: 4px 8px;
  box-sizing: border-box;
  margin: 0;
}
form-select.filled > div.input-field.input-field-select > div span.caret {
  color: #000;
  z-index: 20;
  margin-right: 1em;
}
.row {
  display: flex;
  flex-direction: row;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.load-more-container {
  margin-top: 2rem;
}
.load-more-container .load-more-results {
  margin-top: 0;
}
i.clear-icon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.26);
}
i.clear-icon:hover {
  color: #9e9e9e;
}
#items-per-load {
  gap: 0.5rem;
  margin: 0;
}
#items-per-load p {
  font-size: 1rem;
}
#items-per-load input {
  height: 2rem;
  width: 4.5rem;
}
.allow-empty.empty input {
  color: #9e9e9e;
}
.form-lines li {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.form-lines li > * {
  margin-right: 0.5em;
  margin-left: 0.5em;
}
.form-lines li > :first-child {
  width: 250px;
}
.form-lines li flat-button {
  align-self: center;
}
.form-lines li input {
  min-width: 0;
}
.preserve-newlines {
  white-space: pre;
}
loading-indicator.loading-indicator-small-inline {
  line-height: 36px;
  display: inline-flex;
}
loading-indicator.loading-indicator-small-inline > span {
  font-size: 1em;
}
loading-indicator.loading-indicator-small-inline .preloader-wrapper.small {
  width: 24px;
  height: 24px;
}
.sticky-headers .list-loader-content > table > thead > tr > th,
.sticky-table-header {
  top: 0px;
  position: sticky;
  background-color: white;
  z-index: 1;
}
.sticky-headers .list-loader-content > table > thead > tr > th::after,
.sticky-table-header::after {
  content: '';
  display: block;
  height: 1px;
  background-color: #d0d0d0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
}
flat-button.disabled span.action {
  color: #9e9e9e;
}
i.success-icon {
  color: #4caf50;
}
.inline-tag {
  display: inline-block;
  padding: 0.2em 0.5em;
  border-radius: 2em;
  border: 1px solid #d0d0d0;
  margin: 0 0.2em 0.2em 0;
}
form-textarea .validation-error {
  margin-top: -1em;
}
.app-button {
  background-color: #283593;
  padding: 5px 10px;
  color: white;
  border-radius: 6px;
  border: none;
}
.app-button:focus {
  background-color: #283593;
}
.app-button:active {
  animation: app-button-press 0.2s ease-in;
  background-color: rgba(40, 53, 147, 0.5);
}
.app-button:disabled {
  background-color: #ababab;
  animation: none;
}
@keyframes app-button-press {
  0% {
    background-color: #283593;
  }
  100% {
    background-color: rgba(40, 53, 147, 0.5);
  }
}
.text-area-autoresize-container {
  position: relative;
}
.text-area-autoresize-container > iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border: none;
  z-index: -1;
}
.firebase-login {
  width: 420px;
}
.error {
  color: #f44336;
}
/*****************************************************************
** This styling should be used for warning messages **
*****************************************************************/
.warning-messages-container {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
.warning-message {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 14px 16px;
  gap: 4px;
  background: #ffee94;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  flex: none;
  order: 2;
  flex-grow: 0;
}
/*****************************************************************
** END **
*****************************************************************/
.navigation-bar {
  background-color: #283593;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.navigation-bar #header-left {
  display: flex;
  align-items: center;
  color: white;
}
.navigation-bar #header-logo {
  background: url(/static/97b53e1d8575e42c403570f51f41b075.png) no-repeat 50% 50%;
  height: 60px;
  width: 44px;
  margin-left: 15px;
  margin-right: 15px;
}
.navigation-bar #username-placeholder {
  font-size: 16px;
}
.navigation-bar #tenant-name-placeholder {
  font-size: 14px;
}
.navigation-bar #email-placeholder {
  display: none;
  font-size: 16px;
}
.navigation-bar #user-info:hover #username-placeholder {
  display: none;
}
.navigation-bar #user-info:hover #email-placeholder {
  display: initial;
}
.navigation-bar #qt-header-logo {
  margin-right: 24px;
  margin-left: 24px;
}
@media (min-width: 1201px) {
  .navigation-bar #qt-header-logo {
    background: url(/static/abb440d8684bad37ebd67f99f207defa.png) no-repeat center;
    background-size: 172px 41px;
    width: 172px;
    height: 41px;
  }
}
@media (max-width: 1200px) {
  .navigation-bar #qt-header-logo {
    background: url(/static/e0358e23b85050b5949629a45dbef7ff.png) no-repeat 50% 50%;
    background-size: 46px 46px;
    width: 46px;
    height: 46px;
  }
}
.navigation-bar #co-brand-logo {
  margin-right: 24px;
}
@media (min-width: 1201px) {
  .navigation-bar #co-brand-logo {
    background: url(/static/36334346d60e0767d7f6a916033b6914.png) no-repeat 50% 50%;
    background-size: 168px 35px;
    width: 168px;
    height: 35px;
  }
}
@media (max-width: 1200px) {
  .navigation-bar #co-brand-logo {
    background: url(/static/b033fb51517502576aee7069b2ec1258.png) no-repeat 50% 50%;
    background-size: 42.5px 42.5px;
    width: 42.5px;
    height: 42.5px;
  }
}
@media (min-width: 1200px) {
  .navigation-bar .white-line {
    border: solid 1px white;
    height: 40px;
    margin-right: 13px;
  }
}
.navigation-bar ul {
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.navigation-bar ul li {
  transition: background-color 0.3s;
  display: none;
}
.navigation-bar ul li.important,
.navigation-bar ul li.expand {
  display: inline-block;
}
.navigation-bar ul li a {
  color: #ffffff !important;
  transition: background-color 0.3s;
  font-size: 1rem;
  padding: 1.2rem 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.navigation-bar ul li a i {
  max-width: 1ch;
}
.navigation-bar ul .selectable:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}
.navigation-bar ul.vertical-menu {
  display: none;
}
.navigation-bar.vertical-menu-open ul.vertical-menu {
  background-color: #283593;
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  z-index: 100000;
  animation: slide-in 0.2s forwards;
  min-width: 250px;
}
.navigation-bar.vertical-menu-open ul.vertical-menu li {
  display: block;
}
.navigation-bar.vertical-menu-open ul.vertical-menu li.expand {
  display: none;
}
.navigation-bar.vertical-menu-open ul.vertical-menu li a,
.navigation-bar.vertical-menu-open ul.vertical-menu li .nav-group-title {
  padding: 0.5rem 15px;
}
.navigation-bar.vertical-menu-open ul.vertical-menu li .nav-group-title {
  color: white;
}
.navigation-bar.vertical-menu-open ul.vertical-menu li .nav-group-item {
  padding-left: 2rem;
}
.navigation-bar.vertical-menu-open ul.vertical-menu li.nav-group-expanded {
  background-color: rgba(0, 0, 0, 0.05);
}
.navigation-bar.vertical-menu-open .navigation-bar-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.3);
}
.navigation-bar.vertical-menu-open-rtl ul.vertical-menu {
  background-color: #283593;
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  z-index: 100000;
  animation-name: slideInLeft;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  min-width: 250px;
}
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
.navigation-bar.vertical-menu-open-rtl ul.vertical-menu li {
  display: block;
}
.navigation-bar.vertical-menu-open-rtl ul.vertical-menu li.expand {
  display: none;
}
.navigation-bar.vertical-menu-open-rtl ul.vertical-menu li a,
.navigation-bar.vertical-menu-open-rtl ul.vertical-menu li .nav-group-title {
  padding: 0.5rem 15px;
}
.navigation-bar.vertical-menu-open-rtl ul.vertical-menu li .nav-group-title {
  color: white;
}
.navigation-bar.vertical-menu-open-rtl ul.vertical-menu li .nav-group-item {
  padding-left: 2rem;
}
.navigation-bar.vertical-menu-open-rtl ul.vertical-menu li.nav-group-expanded {
  background-color: rgba(0, 0, 0, 0.05);
}
.navigation-bar.vertical-menu-open-rtl .navigation-bar-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.3);
}
@media (max-width: 1200px) {
  body .navigation-bar ul > li.important {
    display: none;
  }
  body .navigation-bar.vertical-menu-open ul.vertical-menu > li.important {
    display: block;
  }
}
@media print {
  .navigation-bar {
    display: none;
  }
}
@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
@-webkit-keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
.tenant-expiring,
.need-page-reload,
.network-outage {
  width: 100%;
  padding: 5px;
  background-color: #ffee94;
  text-align: center;
}
.tenant-expiring a,
.need-page-reload a,
.network-outage a {
  cursor: pointer;
  margin-left: 1em;
}
.tenant-expiring a:first-child,
.need-page-reload a:first-child,
.network-outage a:first-child {
  margin-left: 0;
}
.tenant-expiring .prefer-nowrap,
.need-page-reload .prefer-nowrap,
.network-outage .prefer-nowrap {
  display: inline-block;
}
.tenant-expiring .network-outage-inner,
.need-page-reload .network-outage-inner,
.network-outage .network-outage-inner {
  text-align: left;
  padding-left: 33%;
}
.loading-dots:after {
  display: inline-block;
  animation: dotty steps(1, end) 3.33s infinite;
  content: '';
}
@keyframes dotty {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}
.banner {
  top: 8px;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
  display: flex;
  z-index: 1400;
  position: fixed;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.banner-validation-error {
  background-color: #ffee94;
}
.banner-message {
  padding: 4px 16px;
}
.banner-inner {
  display: flex;
  padding: 6px 16px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  border-radius: 4px;
  letter-spacing: 0.01071em;
}
.login-logo {
  background: url(/static/497b77670d43d4c9a096a40220392d1e.png) no-repeat 50% 0%;
  height: 105px;
  max-width: 350px;
  width: 100%;
  background-size: 100%;
}
p.motto {
  margin: 2rem 0 2rem;
  font-size: 28px;
  font-weight: 300;
  max-width: 400px;
}
.login {
  max-width: 650px;
  margin: 0 auto;
  padding-top: 1em;
  padding-bottom: 140px;
  font-size: 18px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login .secondary-links {
  border-top: 1px solid #414141;
  margin-top: 4rem;
  padding-top: 1rem;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.login .secondary-links > a {
  color: #283593;
  font-size: 20px;
}
.login .select-team-title {
  font-size: 1.2em;
  margin-bottom: 1em;
  margin-top: 1em;
}
.login a.collection-item {
  text-align: left;
  cursor: pointer;
}
.login #sign-in {
  display: inline-block;
}
.login .footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 120px;
  background: url(/static/9627be59fdf151c5edb7987df3d35338.png) repeat-x 0% 100%;
  z-index: 2;
}
#ui-sign-in-email-input,
#ui-sign-in-password-input {
  margin-bottom: 0 !important;
}
#ui-sign-in-email-input:focus,
#ui-sign-in-password-input:focus {
  box-shadow: none;
}
.firebaseui-id-submit {
  background-color: #283593 !important;
}
.firebaseui-id-submit:hover {
  background-color: #283593;
  box-shadow: 5px 10px 9px -2px #cfcfcf;
}
.firebaseui-id-secondary-link {
  color: #283593 !important;
}
.firebaseui-textfield.mdl-textfield .firebaseui-label:after {
  background-color: #283593 !important;
}
@media (max-height: 600px) {
  body .login p {
    margin: 3rem 0;
    font-size: 24px;
  }
  body .login .secondary-links {
    margin-top: 3rem;
  }
}
@media (max-height: 510px) {
  body .login {
    padding-bottom: 1em;
  }
  body .login .footer {
    display: none;
  }
}
.email-password-sign-in {
  font-size: 1rem;
  text-align: left;
  margin-top: 2em;
}
.email-password-sign-in > :first-child {
  margin-bottom: 1.5em;
  font-weight: 300;
}
.email-password-sign-in button.primary {
  margin-top: 1em;
  padding: 0 1rem;
  line-height: 2rem;
  height: 2rem;
}
.email-password-sign-in .validation-error {
  max-width: 200px;
}
.email-password-sign-in .signin-in-email-next {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.resend-email {
  font-family: Roboto;
  font-weight: 700;
  color: #283593;
  background: none !important;
  border: none;
  padding: 0 !important;
}
.welcome {
  font-weight: 500;
  color: #283593;
}
.firebaseui-idp-button[data-provider-id='microsoft.com'] {
  background: #ffff !important;
}
.firebaseui-idp-button[data-provider-id='microsoft.com'] .firebaseui-idp-text {
  color: #757575 !important;
}
.login-cobranding-logo {
  background: url(/static/6e95ec37ccb76b927749513168fd57c6.png) no-repeat center;
  height: 54px;
  width: 243px;
  background-size: 243px 54px;
  margin-top: 2rem;
  margin-bottom: 1.68rem;
}
p.cobranded-motto {
  margin-bottom: 2rem;
  font-size: 28px;
  font-weight: 300;
  max-width: 400px;
}
.trial-edit {
  width: auto;
  max-width: none;
}
.dimensions-and-measurements-container {
  display: flex;
}
.dimensions-and-measurements-container > * {
  margin-right: 2em;
}
.dimensions-and-measurements-container > *:last-child {
  margin-right: 0;
}
.dimensions-and-measurements-container > * {
  flex: 1;
}
.warning {
  color: #f9a825;
  margin-bottom: 1em;
  font-style: italic;
}
@media (max-width: 800px) {
  body .dimensions-section .form-input-line {
    display: block;
  }
  body .dimensions-section .form-input-line > * {
    display: block;
    margin-right: 0;
  }
  body .dimensions-section .form-input-line .remove-ddm {
    text-align: left;
    margin-left: -2px;
  }
}
.dimensions-section {
  border: 1px solid #d0d0d0;
}
.dimensions-section .remove-ddm {
  flex: 1;
  text-align: right;
}
.dimensions-section > * {
  display: block;
  margin: 0 1em;
}
.dimensions-section > .form-input-line {
  display: flex;
}
.dimensions-section > form-select-search {
  max-width: 250px;
}
.dimensions-section > ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 1em;
}
.dimensions-section > ul > li {
  padding: 1em;
  cursor: pointer;
  background-color: #eee;
  margin: -1px;
  border: 1px solid #d0d0d0;
  font-weight: bold;
  flex: 1;
  text-align: center;
}
.dimensions-section > ul > li flat-button {
  font-weight: normal;
  white-space: nowrap;
}
.dimensions-section > ul > li.active {
  background-color: white;
  border-bottom: none;
}
.dimensions-section > ul > li.error {
  color: #f44336;
}
.dimensions-section > ul > li.error.active {
  color: inherit;
}
.dimensions-section > dimensions-table {
  margin: 0;
}
.dimensions-section > dimensions-table > form-select-search {
  display: block;
  margin: 0 1em;
}
dimensions-table > table td,
.dimensions-table > table td,
dimensions-table > table th,
.dimensions-table > table th {
  padding: 0.5em;
}
dimensions-table > table th,
.dimensions-table > table th {
  background-color: #eee;
}
dimensions-table > table tr,
.dimensions-table > table tr {
  border-bottom: 1px solid #eee;
}
dimensions-table > table tr > td:last-child,
.dimensions-table > table tr > td:last-child,
dimensions-table > table tr > th:last-child,
.dimensions-table > table tr > th:last-child {
  text-align: right;
}
dimensions-table > table flat-button,
.dimensions-table > table flat-button {
  font-weight: normal;
}
dimensions-table > table flat-button > span,
.dimensions-table > table flat-button > span {
  margin-right: 0;
}
.dimensions-table-move > i {
  cursor: pointer;
}
.dimensions-table-drag-handle {
  cursor: move;
}
.dimensions-table-dragging {
  border: 2px solid #d0d0d0;
  border-radius: 5px;
  background-color: #eee;
  width: fit-content !important;
  height: fit-content !important;
}
@media (max-width: 800px) {
  body dimensions-table > table td,
  .dimensions-table > table td,
  body dimensions-table > table th,
  .dimensions-table > table th {
    display: none;
  }
  body dimensions-table > table td:first-child,
  .dimensions-table > table td:first-child,
  body dimensions-table > table th:first-child,
  .dimensions-table > table th:first-child,
  body dimensions-table > table td:last-child,
  .dimensions-table > table td:last-child,
  body dimensions-table > table th:last-child,
  .dimensions-table > table th:last-child {
    display: table-cell;
  }
}
@media (max-width: 800px) {
  .dataset-edit .dimensions-and-measurements-container {
    display: block;
  }
  .dataset-edit .dimensions-and-measurements-container .add-attribute {
    margin: 1em 0;
  }
  .dataset-edit .dimensions-and-measurements-container .copy-dimensions {
    margin: 1em 0;
  }
  .dataset-edit .dimensions-and-measurements-container .collapsible.popout > li {
    margin: 0;
  }
}
.no-plots {
  font-style: italic;
  font-size: 1.2em;
  margin-top: 1em;
}
ul.plots.collection {
  font-size: 0;
  border: none;
}
ul.plots.collection li.collection-item {
  font-size: initial;
  width: 200px;
  border: 1px dotted #d0d0d0;
}
ul.plots.collection li.collection-item:not(:last-child) {
  border-right: none;
}
.plot-item {
  display: inline-block;
}
.plot-number {
  font-size: 1em;
  font-weight: 500;
  cursor: default;
}
.plot-subheadings {
  font-size: 0.8em;
  font-style: italic;
  word-break: break-all;
}
.plot-number-title {
  position: absolute;
  margin-left: -30px;
  margin-top: 20px;
  background: white;
  padding: 5px;
  border: 1px solid gray;
  border-radius: 5px;
}
.rcb-params {
  margin-bottom: 1em;
  margin-top: 2em;
}
.dimension-dependency-warning {
  margin-top: -1em;
  margin-bottom: 1em;
  color: #9e9e9e;
}
form.dataset-edit {
  max-width: inherit;
}
.uncontainer.trial-wizard-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.1rem;
  margin-bottom: 0.5rem;
}
.uncontainer.trial-wizard-title-container > h1 {
  margin: 0;
}
.hover-tooltip {
  position: relative;
  display: inline-block;
}
.hover-tooltip .hover-tooltiptext {
  visibility: hidden;
  background-color: white;
  right: 24px;
  border: 1px solid #d0d0d0;
  max-width: 400px;
  padding: 0.5em;
  z-index: 2;
  top: 24px;
  text-transform: none;
  position: absolute;
}
.hover-tooltip:hover .hover-tooltiptext {
  visibility: visible;
}
.w-15 {
  width: 15em;
}
.trial-wizard-action-icons-group {
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: 18px;
}
.trial-wizard-action-icons-group > flat-button > span > i {
  color: #000000BD;
  border-radius: 50%;
  padding: 6px;
  width: 36px;
  height: 36px;
  margin-right: 0;
}
.trial-wizard-action-icons-group > flat-button > span > i:hover {
  background: #F2F2F2;
}
.trial-wizard-action-icons-group .wizard-actions-menu {
  cursor: pointer;
  position: absolute;
  right: 100px;
  top: 36px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  width: 260px;
  z-index: 9;
}
.trial-wizard-action-icons-group .wizard-actions-menu .wizard-actions-menu-item {
  color: #001F24;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  padding: 12px 16px;
}
.trial-wizard-action-icons-group .wizard-actions-menu .wizard-actions-menu-item:hover {
  background: #F2F2F2;
  border-radius: 4px;
}
.trial-wizard-action-icons-group .wizard-actions-menu .wizard-actions-menu-item > span {
  vertical-align: middle;
}
.trial-wizard-action-icons-group .wizard-actions-menu .wizard-actions-menu-item :first-child {
  margin-right: 8px;
}
.draggable-li {
  list-style-type: none;
}
.wizard h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.wizard h1.step-1-title {
  margin-bottom: 2rem;
}
.wizard h2 {
  font-size: 1.2em;
  margin-top: 2.5rem;
  color: #9e9e9e;
}
.wizard h2.subtitle .material-icons + div {
  margin-left: 40px;
}
.wizard h2.subtitle .material-icons + div .highlight {
  color: #283593;
}
.wizard > loading-indicator {
  margin: 3rem auto 0;
  width: 300px;
}
.wizard form {
  max-width: initial;
}
.wizard form [type='radio'] + label {
  color: black;
}
.wizard .test-subject ul.test-subject-list {
  display: flex;
  flex-wrap: wrap;
}
.wizard .test-subject ul.test-subject-list li {
  cursor: pointer;
  margin-right: 2rem;
  margin-bottom: 2rem;
}
.wizard .test-subject ul.test-subject-list li > div {
  max-width: 110px;
  text-align: center;
}
.wizard .test-subject ul.test-subject-list li img {
  width: 90px;
  box-sizing: content-box;
  margin: 0 6px;
  border: 4px solid transparent;
  background-color: #eee;
}
.wizard .test-subject ul.test-subject-list li label {
  display: block;
  font-size: initial;
}
.wizard .test-subject ul.test-subject-list li.selected img {
  border-color: #283593;
  background-color: rgba(40, 53, 147, 0.4);
}
.wizard .test-subject ul.test-subject-list li.selected label {
  font-weight: bold;
  color: #283593;
}
.wizard .test-subject ul.test-subject-list li.disabled {
  cursor: initial;
  opacity: 0.5;
}
.wizard .test-subject ul.test-subject-list li flat-button {
  white-space: nowrap;
}
.wizard .layout ul.layout-list {
  display: flex;
  flex-wrap: wrap;
}
.wizard .layout ul.layout-list li {
  cursor: pointer;
  margin-right: 2rem;
  margin-bottom: 1rem;
  padding: 1rem;
  font-size: initial;
  width: 200px;
  text-align: center;
  border: 1px dashed rgba(0, 200, 83, 0.4);
  color: rgba(0, 200, 83, 0.4);
}
.wizard .layout ul.layout-list li.selected {
  border: 1px dashed #283593;
  color: #283593;
}
.wizard .layout ul.layout-list li.disabled {
  cursor: initial;
  opacity: 0.5;
}
.wizard .layout .count-info {
  margin-bottom: 2em;
  color: #9e9e9e;
  font-style: italic;
}
.wizard .saving-overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
}
.wizard .save-btn {
  float: right;
}
.wizard .save-btn.saving span.action {
  color: #9e9e9e;
}
.wizard .save-btn > span {
  margin-right: 0;
}
.wizard .advanced-options {
  display: block;
  position: absolute;
  top: 3em;
  right: 0;
}
.wizard .advanced-options > span {
  margin-right: 0;
}
.wizard .tabs .tab.management.active {
  border-bottom: 2px solid #ff9800;
}
.wizard .tabs .tab.management.active.error > a,
.wizard .tabs .tab.management.active > a,
.wizard .tabs .tab.management.active > a:hover {
  color: #ff9800;
}
.wizard .tabs .tab.management.error > a {
  color: #f44336;
}
.wizard .tabs .tab.management > a,
.wizard .tabs .tab.management > a:hover {
  color: rgba(255, 152, 0, 0.8);
}
.wizard .assessment-tab-container {
  position: relative;
}
.wizard .assessment-tab-container .dataset-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1em;
  margin-bottom: -1em;
}
.wizard .state-label {
  color: #283593;
}
.wizard .state-info {
  font-style: italic;
  color: #9e9e9e;
  margin-left: 1em;
}
.wizard .subtitle .congrats {
  display: flex;
  align-items: center;
  color: #283593;
}
.wizard .subtitle .congrats i {
  font-size: 40px;
  margin-right: 1rem;
}
.wizard .subtitle .help-text {
  margin-top: 2em;
  font-size: 1rem;
}
.wizard .layout-button-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.wizard .change-grid-size {
  width: 12rem;
}
.wizard .change-grid-size .flat-button > div {
  width: 100%;
}
.wizard .site-sorted-plots ul > li {
  padding: 5px 0;
  touch-action: none;
}
.wizard .plot-grid .max-size tbody > tr > th {
  height: 125px;
}
.wizard .plot-grid .max-size td {
  height: 125px;
  padding: 5px 5px;
  touch-action: none;
  cursor: grab;
}
.wizard .plot-grid .max-size td .plot-widget,
.wizard .plot-grid .max-size td .plot-not-planted {
  width: 200px;
  min-height: 125px;
}
.wizard .plot-grid .max-size th {
  padding: 15px 5px;
  max-width: 200px;
}
.wizard .plot-grid .max-size td.hsplit-left {
  padding-left: 1em;
}
.wizard .plot-grid .max-size td.hsplit-right {
  padding-right: 1em;
}
.wizard .plot-grid .max-size tr.vsplit-top > td {
  padding-top: 1em;
}
.wizard .plot-grid .max-size tr.vsplit-bottom > td {
  padding-bottom: 1em;
}
.wizard .plot-grid .mid-size tbody > tr > th {
  height: 62px;
}
.wizard .plot-grid .mid-size .expand-less {
  font-size: 10px;
  top: 5px;
}
.wizard .plot-grid .mid-size .expand-more {
  font-size: 10px;
  bottom: 5px;
}
.wizard .plot-grid .mid-size .moved .plot-subheadings {
  display: none;
}
.wizard .plot-grid .mid-size td {
  height: 62px;
  padding: 2px 2px;
  touch-action: none;
  cursor: grab;
}
.wizard .plot-grid .mid-size td .plot-widget,
.wizard .plot-grid .mid-size td .plot-not-planted {
  width: 100px;
  min-height: 62px;
}
.wizard .plot-grid .mid-size td .plot-subheadings,
.wizard .plot-grid .mid-size td .plot-not-draggable {
  display: none;
}
.wizard .plot-grid .mid-size th {
  padding: 7px 2px;
  max-width: 100px;
}
.wizard .plot-grid .mid-size td.hsplit-left {
  padding-left: 0.5em;
}
.wizard .plot-grid .mid-size td.hsplit-right {
  padding-right: 0.5em;
}
.wizard .plot-grid .mid-size tr.vsplit-top > td {
  padding-top: 0.5em;
}
.wizard .plot-grid .mid-size tr.vsplit-bottom > td {
  padding-bottom: 0.5em;
}
.wizard .plot-grid .small-size tbody > tr > th {
  height: 33px;
  max-width: 45px;
}
.wizard .plot-grid .small-size .expand-less,
.wizard .plot-grid .small-size .expand-more {
  display: none;
}
.wizard .plot-grid .small-size td {
  height: 33px;
  padding: 1px 1px;
  touch-action: none;
  cursor: grab;
}
.wizard .plot-grid .small-size td .plot-widget,
.wizard .plot-grid .small-size td .plot-not-planted {
  width: 20px;
  min-height: 33px;
}
.wizard .plot-grid .small-size td .plot-subheadings,
.wizard .plot-grid .small-size td .plot-not-draggable,
.wizard .plot-grid .small-size td .plot-number {
  display: none;
}
.wizard .plot-grid .small-size td .plot-not-planted > span {
  display: none;
}
.wizard .plot-grid .small-size th {
  padding: 2px 1px;
  max-width: 20px;
}
.wizard .plot-grid .small-size td.hsplit-left {
  padding-left: 0.1em;
}
.wizard .plot-grid .small-size td.hsplit-right {
  padding-right: 0.1em;
}
.wizard .plot-grid .small-size tr.vsplit-top > td {
  padding-top: 0.1em;
}
.wizard .plot-grid .small-size tr.vsplit-bottom > td {
  padding-bottom: 0.1em;
}
.wizard .plot-grid table#layout-table {
  width: initial;
  margin-top: 1em;
  margin-bottom: 2em;
  overflow: scroll !important;
  width: 100%;
  display: block;
}
.wizard .plot-grid table#layout-table thead th {
  position: sticky;
  top: 0;
  z-index: 15;
  background-color: #f5f5f5;
}
.wizard .plot-grid table#layout-table thead {
  border-bottom: none;
}
.wizard .plot-grid table#layout-table tbody th {
  padding: 5px 15px;
  position: sticky;
  left: 0;
  z-index: 14;
  background-color: #f5f5f5;
  width: 25px;
}
.wizard .plot-grid table#layout-table th {
  color: #9e9e9e;
  font-size: 1rem;
  text-align: center;
  font-weight: normal;
  user-select: none;
  cursor: grab;
}
.wizard .plot-grid table#layout-table th,
.wizard .plot-grid table#layout-table td {
  border-right: 1px solid transparent;
}
.wizard .plot-grid table#layout-table tr {
  border-bottom: 1px solid transparent;
}
.wizard .plot-grid table#layout-table .row-drag-source {
  opacity: 0.5;
}
.wizard .plot-grid table#layout-table .col-drag-target {
  border-right: 1px solid #d0d0d0;
}
.wizard .plot-grid table#layout-table .row-drag-target {
  border-bottom: 1px solid #d0d0d0;
}
.wizard .plot-grid ul {
  margin-left: 2.5rem;
  display: flex;
  flex-wrap: wrap;
}
.wizard .plot-grid ul li {
  padding: 5px;
  touch-action: none;
}
.wizard .plot-grid ul li.empty {
  padding: 0;
}
.wizard .plot-grid .gu-transit {
  display: none;
}
.select-site-label {
  color: #283593;
  font-size: 1.2em;
}
.select-site-input .input-field {
  margin-top: 0;
}
.grid-size-edit-container {
  position: absolute;
  max-width: 100%;
  vertical-align: text-top;
  background-color: white;
  border: 1px solid #d0d0d0;
  z-index: 2;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  aling-items: center;
}
.plot-content {
  display: block;
  width: 200px;
  height: 100%;
  padding: 0px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}
.plot-content :first-child {
  margin-top: auto;
}
.plot-content :last-child {
  margin-bottom: auto;
}
.plot-widget {
  display: block;
  width: 200px;
  height: 100%;
  padding: 0px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  cursor: pointer;
  position: relative;
  height: 100% !important;
  overflow: hidden;
  transition: transform 0.5s;
}
.plot-widget :first-child {
  margin-top: auto;
}
.plot-widget :last-child {
  margin-bottom: auto;
}
.plot-widget.excluded {
  opacity: 0.5;
}
.plot-widget > i {
  padding: 3px;
}
.plot-widget .input-field input[type='text'] {
  font-weight: normal;
  height: 1.5em;
  background-color: white;
  padding: 0 0.5em;
  width: 160px;
  margin-bottom: 0.5em;
  border-bottom: none;
  box-shadow: none;
}
.plot-widget .input-field input[type='text']:focus:not([readonly]) {
  border-bottom: none;
  box-shadow: none;
}
.plot-widget input[type='text'] {
  font-weight: normal;
  height: 1.5em;
  background-color: white;
  padding: 0 0.5em;
  width: 160px;
  margin-bottom: 0.5em;
  border-bottom: none;
  box-shadow: none;
}
.plot-widget input[type='text']:focus:not([readonly]) {
  border-bottom: none;
  box-shadow: none;
}
.plot-widget > div {
  order: 3;
  margin: auto;
}
.plot-widget-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.plot-widget > .plot-widget-edit-details {
  position: absolute;
  align-self: flex-end;
  margin: 0;
  font-size: 20px;
  color: black;
  cursor: pointer;
  padding: 7px 3px;
  order: 2;
}
.plot-widget > .expand-more {
  margin: 0;
  font-size: 20px;
  color: #283593;
  background-color: white;
  border-radius: 20px;
  cursor: pointer;
  order: 4;
  bottom: 10px;
  position: absolute;
}
.plot-widget > .expand-less {
  margin: 0;
  font-size: 20px;
  color: #283593;
  background-color: white;
  border-radius: 20px;
  cursor: pointer;
  order: 1;
  position: absolute;
  top: 10px;
}
.plot-not-planted {
  display: block;
  width: 200px;
  height: 100%;
  padding: 0px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  color: #9e9e9e;
  font-style: italic;
  border: 1px dashed #d0d0d0;
  cursor: pointer;
}
.plot-not-planted :first-child {
  margin-top: auto;
}
.plot-not-planted :last-child {
  margin-bottom: auto;
}
.plot-remove-target {
  color: #283593;
  font-style: italic;
}
flat-button.cancel-wizard {
  display: block;
  text-align: right;
}
flat-button.cancel-wizard > span {
  color: #9e9e9e;
  margin-right: 0;
}
ul.stepper {
  margin-top: 0;
}
@media (max-width: 800px) {
  body .wizard .advanced-options {
    position: relative;
    top: initial;
    right: initial;
    margin-bottom: 1em;
  }
}
dataset-measurement-metas {
  display: block;
  margin: 2em 0;
}
dataset-measurement-metas measurements-meta {
  display: block;
  margin-top: 1em;
}
form .form-section-title.plot-ddm-selection {
  margin-bottom: 0;
}
ul.stepper .step.disabled .step-title {
  cursor: initial;
  opacity: 0.5;
}
ul.stepper .step.disabled .step-title:hover {
  background: none;
}
ul.stepper.horizontal .step.error {
  color: #f44336;
}
ul.stepper.horizontal .step.error .step-title:before,
ul.stepper .step.error:before {
  background-color: rgba(244, 67, 54, 0.6);
}
/**
 * Wizard style for materializecss
 *
 * Copied from:  https://github.com/Kinark/Materialize-stepper
 * and adapter for our use case
 */
ul.stepper {
  counter-reset: section;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #eee;
}
@media only screen and (min-width: 993px) {
  ul.stepper.horizontal {
    position: relative;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 993px) {
  ul.stepper.horizontal .step {
    padding-left: 0.5em;
  }
}
ul.stepper .step {
  position: relative;
}
@media only screen and (min-width: 993px) {
  ul.stepper.horizontal .step {
    position: static;
    margin: 0;
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    height: 4em !important;
    flex: 1;
  }
  ul.stepper.horizontal > .step:last-of-type,
  ul.stepper.horizontal > .step[data-last='true'] {
    width: auto !important;
  }
  ul.stepper.horizontal .step:not(:last-of-type):after,
  ul.stepper.horizontal > .step.active:not(:last-of-type):after {
    content: '';
    background-color: rgba(0, 0, 0, 0.3);
    position: static;
    display: inline-block;
    width: 100%;
    height: 1px;
  }
}
ul.stepper > li:not(:last-of-type) {
  -webkit-transition: margin-bottom 0.4s;
  transition: margin-bottom 0.4s;
}
ul.stepper .step:before {
  position: absolute;
  top: 12px;
  counter-increment: section;
  content: counter(section);
  height: 2em;
  width: 2em;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  text-align: center;
  line-height: 2em;
  font-weight: 400;
}
ul.stepper .step.active:before,
ul.stepper .step.done:before {
  background-color: #283593;
}
ul.stepper .step.done:before {
  content: '\e5ca';
  font-size: 16px;
  font-family: 'Material Icons';
}
ul.stepper .step.wrong:before {
  content: '\e001';
  font-size: 24px;
  font-family: 'Material Icons';
  background-color: red !important;
}
ul.stepper .step-title {
  margin: 0 -24px;
  cursor: pointer;
  padding: 15.5px 44px 24px 64px;
  display: block;
}
@media only screen and (min-width: 993px) {
  ul.stepper.horizontal .step.active .step-title:before,
  ul.stepper.horizontal .step.done .step-title:before {
    background-color: #283593;
  }
  ul.stepper.horizontal .step.done .step-title:before {
    content: '\e5ca';
    font-size: 16px;
    font-family: 'Material Icons';
  }
  ul.stepper.horizontal .step.wrong .step-title:before {
    content: '\e001';
    font-size: 24px;
    font-family: 'Material Icons';
    background-color: red !important;
  }
  ul.stepper.horizontal .step-title {
    line-height: 4em;
    height: 4em;
    margin: 0;
    padding: 0 0.5em;
    display: inline-block;
    max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  ul.stepper.horizontal .step:before {
    content: none;
  }
  ul.stepper.horizontal .step .step-title:before {
    display: inline-block;
    counter-increment: section;
    content: counter(section);
    height: 2em;
    width: 2em;
    margin-right: 0.5em;
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    text-align: center;
    line-height: 2em;
    font-weight: 400;
  }
}
ul.stepper .step-title:after {
  content: attr(data-step-label);
  display: block;
  position: absolute;
  font-size: 12.8px;
  font-size: 0.8rem;
  color: #424242;
  font-weight: 400;
}
@media only screen and (min-width: 993px) {
  ul.stepper.horizontal .step-title:after {
    top: 15px;
  }
}
ul.stepper .step-title:hover {
  background-color: rgba(0, 0, 0, 0.06);
}
ul.stepper .step.active .step-title {
  font-weight: 500;
}
.wizard .step-content {
  position: relative;
  overflow: visible;
  margin-right: 24px;
}
@media only screen and (min-width: 993px) {
  .wizard .step-content {
    width: 100%;
    padding: 1em 0;
  }
}
.wizard .step-actions {
  padding-top: 16px;
  margin-bottom: 1rem;
  -webkit-display: flex;
  -moz-display: flex;
  -ms-display: flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  flex-direction: row;
  -ms-flex-direction: row;
}
.wizard .step-actions :first-child {
  margin-right: auto;
}
.wizard .wizard-errors-notice {
  color: #f44336 !important;
  text-align: right;
  margin-top: 1rem;
  transition: opacity 1s linear;
  opacity: 0;
}
.wizard .wizard-errors-notice.visible {
  opacity: 1;
  transition: none;
}
qt-popup.select-site p {
  margin-bottom: 2em;
}
qt-popup.wizard-import-popup h1 {
  margin-bottom: 1.68rem;
}
.observation-help-picture {
  border: 1px solid #d0d0d0;
  padding: 1em;
  margin-top: 1em;
}
.observation-help-picture .no-icon {
  float: right;
}
.wizard h2.experimental-design-subtitle {
  margin-bottom: 2em;
}
.experimental-design form-select {
  margin-top: 3em;
  display: block;
}
.form-input-line.walking-order input {
  min-width: 32ch;
}
.visits-container {
  display: flex;
}
.visits-edit {
  width: 350px;
}
.timeline {
  flex: 1;
  position: relative;
  display: flex;
  align-self: center;
  margin-left: 1em;
}
.timeline-background {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2em;
  height: 6px;
  background-color: #283593;
}
.timeline-item {
  cursor: pointer;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
.timeline-item:hover .timeline-item-popup,
.timeline-item.active .timeline-item-popup {
  display: block;
}
.timeline-item:hover .timeline-tick,
.timeline-item.active .timeline-tick {
  background-color: #283593;
}
.timeline-item-popup {
  display: none;
  position: absolute;
  bottom: 3.5em;
  background-color: white;
  min-width: 280px;
}
.timeline-item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.timeline-tick {
  height: 24px;
  width: 6px;
  background-color: #414141;
  border-radius: 3px;
}
.timeline-label-start {
  align-self: flex-end;
  margin-right: 1em;
}
.timeline-label-end {
  align-self: flex-end;
  margin-left: 1em;
}
.timeline-item-detail {
  border: 1px solid #7986cb;
  padding: 0.5em;
}
.timeline-item-detail-separator {
  border-bottom: 1px solid #d0d0d0;
  margin: 0.5em 0;
}
.timeline-item-detail-observations {
  margin: 0;
  padding: 0;
  list-style-type: disc;
  list-style-position: inside;
  font-weight: 500;
}
.visit-observations {
  display: flex;
  align-items: center;
}
.visit-observations > i {
  cursor: pointer;
}
.visit-observations > span {
  margin-left: 8px;
}
.derived-scheduled-observation {
  color: #9e9e9e;
}
.trial-wizard-loading-preview {
  opacity: 0.5;
}
.trial-name-prefix-input-field {
  display: flex;
  align-items: flex-start;
}
.trial-name-prefix {
  line-height: 3rem;
  border-bottom: 1px solid #9e9e9e;
  color: #525252;
}
.trial-wizard-open-custom-layout-actions {
  display: flex;
  margin: 1em 0;
}
.ft-field-group {
  background-color: #eaeaea;
  border-radius: 10px;
  padding: 20px;
  margin: 2em 0;
}
.ft-field-group label {
  color: #525252;
}
.ft-field-group .input-field label {
  color: #525252;
}
.ft-field-group-title {
  font-weight: bold;
  margin-bottom: 20px;
}
.ft-field-line {
  display: flex;
  align-items: baseline;
}
.ft-field-line > * {
  flex: 1;
}
.ft-field-line > *:not(:last-child) {
  margin-right: 2em;
}
.ft-field-line > *.trial-name-prefix {
  margin-right: 0;
  flex: initial;
}
.ft-field-line.ft-field-line-center {
  align-items: center;
}
.ft-info {
  color: #525252;
  font-style: italic;
  display: flex;
}
.ft-info > i {
  margin-right: 10px;
}
.selected-traits-table {
  margin-bottom: 1em;
}
.selected-traits-table > thead {
  border-bottom: none;
}
.selected-traits-table > thead > tr > th {
  padding: 10px 5px;
  background-color: #eee;
  border-radius: 0;
}
.selected-traits-table > tbody > tr {
  border-bottom: 1px solid #eee;
}
.selected-traits-table > tbody > tr > td {
  padding: 5px;
}
.selected-traits-move {
  width: 1rem;
}
td.drag-indicator-cell {
  width: 1rem;
}
td.drag-indicator-cell .trait-drag-indicator-handle {
  cursor: grab;
}
td.drag-indicator-cell .trait-drag-indicator-handle-disabled {
  cursor: not-allowed !important;
}
.selected-traits-table-dragging {
  border: 2px solid #d0d0d0;
  border-radius: 5px;
  background-color: #eee;
  width: 65% !important;
  height: fit-content !important;
  overflow: hidden;
}
.primary-pointer {
  color: #283593;
  cursor: pointer;
}
.selected-traits-open {
  color: #283593;
  cursor: pointer;
}
.edit-limit-to {
  color: #283593;
  cursor: pointer;
  margin-right: 5px;
}
.remove-limit-to {
  color: #283593;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 4px;
}
.remove-limit-to:hover {
  background-color: #eeeeee;
}
.remove-limit-to:active {
  background-color: #e0dede;
}
.zoom-container {
  padding: 5px 0 5px 0;
}
.moved {
  max-height: 125px;
}
.display-none {
  display: none !important;
}
.plot-not-draggable {
  position: absolute;
  bottom: 45px;
  font-size: smaller;
  font-style: italic;
  text-align: center;
  display: flex;
  padding: 0 10px 0 10px;
}
.grid-size-select .grid-size-topbar {
  justify-content: flex-start;
  margin-top: -1px;
}
.grid-size-select table {
  background-color: white;
  margin: auto;
  max-width: 600px;
  border-collapse: separate;
  display: block;
  overflow-x: scroll;
}
.grid-size-select thead,
.grid-size-select tbody {
  display: inline-block;
}
.grid-size-select thead {
  position: sticky;
  top: 1px;
  z-index: 9999;
}
.grid-size-select tbody {
  max-height: 400px;
}
.grid-size-select tbody tr {
  white-space: pre;
}
.grid-size-select thead tr {
  display: flex;
  justify-content: space-around;
  background-color: white;
}
.grid-size-select thead th {
  padding: 0px;
}
.grid-size-select th {
  width: 40px;
  height: 30px;
  text-align: center;
}
.grid-size-select .fixed {
  position: sticky;
  left: 0;
  top: auto;
  z-index: 999;
  background-color: white;
  height: auto;
  text-align: center;
  padding: 0px 5px 0px 5px;
}
.grid-size-select tbody tr th.fixed {
  border-right: 1px solid #d0d0d0;
}
.grid-size-select .grid-size-topbar-header {
  width: auto;
  margin-left: 5px;
  left: 5px;
}
.grid-size-select td:not(.fixed) {
  z-index: 0;
}
@-moz-document url-prefix() {
  #layout-table > tbody > tr {
    height: 100% !important;
  }
  #layout-table > tbody > tr > td {
    height: 100% !important;
  }
  #layout-table > tbody > tr > th {
    height: 100% !important;
  }
}
.plots-edit-topbar {
  height: 60px;
  color: white;
  background-color: #283593;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  -webkit-transform: translate3d(0, 0, 0);
}
.plots-edit-topbar div {
  flex: 1 1 0px;
  text-align: center;
}
.plots-edit-topbar .current-plot-info {
  text-align: left;
  font-size: small;
  word-break: break-all;
  background: white;
  color: black;
  padding: 5px;
  border-radius: 9px;
}
.plots-edit-topbar .close-button {
  cursor: pointer;
  padding: 7px;
  float: right;
}
.plots-edit-topbar .zoom-actions {
  display: flex;
  justify-content: space-evenly;
}
.plots-edit-topbar .zoom-actions i {
  margin: auto;
  padding: 5px;
}
.plots-edit-topbar .zoom-actions span {
  display: inline-block;
  width: 35px;
  text-align: center;
}
.plots-edit-topbar .zoom-actions button {
  display: flex;
  color: black;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  font-size: 14px;
}
.plots-edit-topbar .zoom-actions .button-text {
  display: flex;
  flex-direction: column;
}
.plots-edit-topbar .zoom-actions .help-text {
  color: grey;
  font-size: x-small;
}
.plots-edit-topbar .site-name .close-popup {
  margin: auto;
  display: contents;
  padding: 0 10px 0 10px;
}
.plots-edit-grid {
  background-color: whitesmoke;
  position: absolute;
  left: 0;
  top: 60px;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  overflow: auto;
  max-height: 100vh;
}
.sidebar-right {
  position: relative !important;
  background-color: inherit !important;
}
.sidebar-right div {
  height: inherit;
}
.sidebar-right .popup-content {
  top: 70px;
  right: 10px !important;
  left: auto;
  width: 320px;
  height: 100%;
}
.sidebar-right primary-button,
.sidebar-right secondary-button,
.sidebar-right button {
  width: 100%;
}
.sidebar-right primary-button {
  margin-right: 5px;
}
.sidebar-right secondary-button {
  margin-left: 5px;
}
.sidebar-right .actions {
  display: flex;
  justify-content: space-between;
}
.sidebar-right .form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sidebar-right .remove-container {
  padding-top: 10px;
  display: contents;
}
.highlight-plot {
  transition: transform 0.5s;
  transition-delay: 0.2s;
  z-index: 50;
  position: relative;
}
.highlight-plot .plot-widget {
  transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
  box-shadow: 0px 0px 0px #f9f9fb00, 0px 0 0px 0 #22215103, 0px 20px 20px 0 #22215140;
  border: 5px #ff8a25 solid;
}
.unhighlight-plot {
  transition: transform 1s;
  transition-delay: 0.3s;
  -webkit-transition: transform 1s;
  -webkit-transition-delay: 0.3s;
  -webkit-transform: none;
  -moz-transform: none;
  transform: none;
}
.unhighlight-plot .plot-widget {
  box-shadow: none;
}
.font-size-2em {
  font-size: 2em !important;
}
.fixed-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.max-size-plot-moved {
  height: 125px;
  max-height: 125px;
}
.mid-size-plot-moved {
  height: 62px !important;
  max-height: 62px !important;
}
.mid-size-plot-moved .plot-subheadings,
.mid-size-plot-moved .expand-less,
.mid-size-plot-moved .expand-more,
.mid-size-plot-moved .plot-not-draggable {
  display: none;
}
.small-size-plot-moved {
  height: 33px;
  max-height: 33px !important;
}
.small-size-plot-moved .expand-less,
.small-size-plot-moved .expand-more,
.small-size-plot-moved .plot-subheadings,
.small-size-plot-moved .plot-not-draggable,
.small-size-plot-moved .plot-number {
  display: none;
}
.help-text {
  color: #004085;
}
ul.collapsible > li.disabled div.collapsible-header {
  background-color: #ababab;
}
#scan-code-container {
  padding: 24px;
}
#scan-code-header {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
#scan-code-header .scan-code {
  margin-left: 0;
  align-items: center;
}
#scan-code-header .scan-code label input[type='checkbox']:checked + .lever {
  background-color: rgba(104, 159, 56, 0.38);
}
#scan-code-header .scan-code span.lever {
  background-color: rgba(0, 0, 0, 0.12);
  margin-right: 8px;
  margin-left: 0;
  margin-bottom: 2px;
}
#scan-code-header .scan-code span.lever::after.disabled-state {
  background-color: #c2bfbf;
}
#scan-code-header .scan-code span.disabled-state {
  color: #8a8a8a;
}
#scan-code-header .scan-code label {
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: #414141;
  margin-right: 8px;
}
#scan-code-header .form-edit-help {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #d0d0d0;
  max-width: 400px;
  padding: 0.5em;
  z-index: 2;
  top: 24px;
  left: 160px;
}
#scan-code-header .form-edit-help > p {
  margin: 1em 0;
}
#scan-code-header > .material-icons:hover + .form-edit-help,
#scan-code-header > .material-icons-outlined:hover + .form-edit-help,
#scan-code-header .form-edit-help:hover {
  display: block;
}
#scan-code-body {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
#scan-code-body > :first-child {
  margin-top: 2rem;
}
#scan-code-body .trait-action-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
#scan-code-body .trait-action-header h5 {
  color: #283593;
}
#scan-code-body .scan-code-select {
  width: 50%;
}
#scan-code-body .scan-code-block {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 2rem;
}
#scan-code-body .sub-label-help {
  color: rgba(0, 0, 0, 0.38);
  font-size: 14px;
}
#scan-code-body .without-margin {
  margin-top: -14px;
}
#scan-code-body .with-margin {
  margin-top: 28px;
}
#scan-code-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}
td.section-header-cell {
  color: #757686;
  background-color: #f7f7f7;
  font-weight: bold;
}
.sign-up > * {
  max-width: 50%;
}
.sign-up .right-container {
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  width: 50%;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #283593;
  padding-left: 3em;
  padding-right: 3em;
}
.sign-up .right-container .right-image {
  background: url(/static/76784347249eb85c20a22b3bc772ea16.png) no-repeat 50% 0%;
  width: 379px;
  height: 365px;
}
.sign-up .right-container p {
  color: white;
  max-width: 70ch;
  font-size: 1.2em;
}
.sign-up .right-container a {
  color: white;
  text-decoration: underline;
  font-weight: bold;
}
.sign-up .sign-up-logo {
  background: url(/static/497b77670d43d4c9a096a40220392d1e.png) no-repeat 50% 0%;
  width: 300px;
  height: 72px;
  margin-top: 160px;
}
.sign-up h1 {
  font-size: 2rem;
  margin-top: 1em;
}
.sign-up .steps {
  padding-right: 1em;
  margin-bottom: 3em;
}
.sign-up .step {
  display: inline-block;
  width: 33%;
  max-width: 120px;
  padding-bottom: 5px;
  border-bottom: 8px solid #eee;
  color: #9e9e9e;
  text-align: center;
}
.sign-up .step.active {
  border-bottom: 8px solid #283593;
  color: #283593;
}
.sign-up .step-description {
  font-size: 1.2em;
}
.sign-up .step-description-details {
  font-size: 0.9em;
}
.sign-up .step-description-details > .email {
  color: #283593;
}
.sign-up form-text-input,
.sign-up form-checkbox {
  display: block;
  margin-right: 1em;
}
.sign-up input {
  max-width: 360px;
}
.sign-up primary-button {
  display: block;
  margin-top: 1em;
}
.sign-up .firebaseui-container {
  margin-left: 0;
}
.sign-up .firebaseui-container .firebaseui-card-content {
  padding-left: 0;
}
.sign-up .firebaseui-container .firebaseui-list-item {
  text-align: left;
}
@media (max-width: 740px) {
  body .sign-up {
    max-width: 400px;
    margin: 0 auto;
  }
  body .sign-up > * {
    max-width: none;
  }
  body .sign-up .sign-up-logo {
    display: inline-block;
    margin-top: 1em;
  }
  body .sign-up .right-container {
    position: relative;
    width: 100%;
    margin-top: 2em;
    margin-bottom: 1em;
  }
  body .sign-up .right-container .right-image {
    display: none;
  }
}
.extend-subscription {
  background-color: #283593;
  margin-bottom: 2em;
}
.extend-subscription h1 {
  color: white;
  margin-top: 1rem;
  font-weight: bold;
}
.extend-subscription > .container {
  color: white;
  display: flex;
  align-items: center;
}
.extend-subscription .expired-icon {
  min-width: 170px;
  height: 170px;
  background: url(/static/e84ff062defaf3e0dc10ec44d152390d.png) no-repeat;
  margin-right: 2em;
  background-color: white;
  border-radius: 12px;
}
.extend-subscription a {
  color: white;
  text-decoration: underline;
}
@media (max-width: 600px) {
  .extend-subscription > .container {
    flex-direction: column;
    padding-top: 1em;
  }
}
.current-subscription-plan {
  font-size: 1.2em;
  margin-bottom: 2em;
  margin-top: 2em;
}
.current-subscription-plan em {
  font-style: normal;
  font-weight: bold;
}
.current-subscription-plan .remove {
  float: right;
  font-size: 1rem;
}
.current-subscription-plan .progress-label {
  margin-top: 1em;
  color: #9e9e9e;
  font-size: 1rem;
}
@media (max-width: 800px) {
  body .current-subscription-plan {
    margin: 0;
  }
}
.subscription-plans {
  margin: 0 -10px;
  border-collapse: separate;
}
.subscription-plans th {
  padding: 10px;
}
.subscription-plans th > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 20px;
  font-weight: bold;
  color: white;
  padding: 5px 0px;
  border-radius: 4px;
  height: 70px;
}
.subscription-plans div.small {
  background-color: #8bc34a;
}
.subscription-plans div.medium {
  background-color: #4caf50;
}
.subscription-plans div.large {
  background-color: #ffc107;
}
.subscription-plans div.xlarge {
  background-color: #ff9800;
}
.subscription-plans div.enterprise {
  background-color: #03a9f4;
}
.subscription-plans td {
  padding: 15px;
  text-align: center;
  border-top: 1px solid #eee;
  width: 1%;
}
.subscription-plans td.selected,
.subscription-plans th.selected {
  background-color: #eee;
  border-radius: 0;
}
.subscription-plans .striked {
  text-decoration: line-through;
}
.subscription-plans .special-discount {
  font-size: 0.5em;
}
.subscription-plans tr:last-child > td,
.subscription-plans tr:first-child > td {
  border-top: none;
}
.subscription-plans .subscribe-buttons > td {
  padding-top: 2em;
}
.yearly-subscription em {
  font-weight: bold;
  font-style: normal;
}
.coupon-input {
  display: inline-block;
  margin: 1rem 1rem 1rem 0;
}
.coupon-input input {
  max-width: 10em;
  text-transform: uppercase;
}
.coupon-apply {
  margin-right: 1rem;
}
.coupon-description {
  white-space: nowrap;
}
.coupon-description em {
  font-weight: bold;
  font-style: normal;
}
@media (max-width: 600px) {
  .coupon-description {
    display: block;
    white-space: normal;
  }
}
.subscription-notes {
  text-align: center;
  color: #9e9e9e;
  margin-top: 1em;
}
.payment-section-title {
  font-size: 1.2em;
  color: #283593;
}
.payment-card {
  margin: 2em 0 0;
}
.payment-buttons {
  margin-top: 3em;
  display: flex;
  justify-content: space-between;
}
.subscriptions-add-on-header {
  margin-top: 1em;
  font-size: 35px;
  color: #333;
  text-align: center;
}
.subscription-add-on-options-container {
  margin: 1em 0;
}
.subscriptions-add-on-title {
  font-size: 20px;
  color: #333;
}
.subscriptions-add-on-weather-icon {
  background: url(/static/d351f26020b784f1e191ef1af676f1a3.png) no-repeat 50% 0%;
  width: 120px;
  height: 40px;
  margin: 0.5em 0;
}
.subscriptions-add-on-last-weather-activation {
  color: #283593;
  font-weight: bold;
  margin-bottom: 0.5em;
}
.subscriptions-add-on-description {
  color: #6b6b6b;
}
.subscriptions-add-on-link {
  color: #283593;
  text-decoration: underline;
  cursor: pointer;
}
.subscription-add-on-separator {
  margin: 1em 0;
  border: 1px solid #d0d0d0;
}
.subscription-form-select {
  display: block;
  margin-bottom: 2.5rem;
}
.group-edit {
  max-width: 700px;
}
.group-edit .conjunction {
  background-color: #eee;
  padding: 1em;
  border: 1px solid #9e9e9e;
  border-radius: 2px;
}
.group-edit .conjunction .input-field.input-field-select {
  margin-top: 0;
}
.group-edit .conjunction .conjunction-label {
  margin: -1.5em 0 0 0;
  text-align: center;
}
.group-edit .conjunction .conjunction-label > span {
  background-color: white;
  border: 1px solid #9e9e9e;
  border-radius: 2px;
  padding: 0 0.5em;
  display: inline-block;
}
.group-edit .conjunction i.action.action-remove {
  margin-top: 0.5em;
}
.group-edit .conjunctions-separator {
  text-align: center;
  margin: 1em 0;
  font-weight: bold;
  font-size: 1.2em;
}
.group-edit .conjunction-add {
  text-align: center;
  margin: 1em 0;
}
.group-edit .collapsible .info {
  margin: 0 0 1em 0;
}
.group-edit .form-input-line {
  display: block;
  margin-left: 3em;
}
.group-edit .form-input-line > *:last-child {
  margin-right: 2em;
}
.group-edit .form-input-line > * {
  display: block;
}
.group-edit .group-edit-select-trial {
  display: block;
  margin-left: 24px;
}
.welcome h1 {
  margin-bottom: 1rem;
}
.welcome h2 {
  font-weight: bold;
  margin: 0 0 3em 0;
  line-height: initial;
  font-size: 1rem;
}
.welcome .box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 1em;
  margin-bottom: 2em;
  border-bottom: 1px solid #d0d0d0;
}
.welcome .first-box {
  border-top: 1px solid #d0d0d0;
  padding-top: 1em;
}
.welcome .welcome-instructions {
  color: #757575;
}
.welcome .welcome-label {
  flex: 1;
  margin-right: 2em;
}
.welcome .welcome-step-title {
  color: #283593;
  font-weight: bold;
  text-align: left;
  font-size: 1.2em;
}
.welcome .welcome-img {
  margin-right: 2em;
  width: 160px;
  height: 90px;
}
.welcome .welcome-step1-img {
  background: url(/static/b041a757341d75905d70d12eeb31b7bb.png) no-repeat 50% 0%;
}
.welcome .welcome-step2-img {
  background: url(/static/1724ae19a955e3c9c1ec5335ec264354.png) no-repeat 50% 0%;
}
.welcome .welcome-step3-img {
  background: url(/static/29577f25c5f280ac224f09e6733b0fee.png) no-repeat 50% 0%;
}
.welcome .welcome-apps > div {
  margin-bottom: 0.5em;
  color: #757575;
}
.welcome .welcome-apps google-play {
  margin-right: 2em;
}
.welcome .start-doing-trials {
  text-align: center;
  margin: 3em;
}
@media (max-width: 800px) {
  .welcome .welcome-apps {
    margin-top: 2em;
  }
}
.dashboard h2 {
  font-size: 1em;
  font-weight: normal;
  color: #283593;
  margin-bottom: 2em;
}
@media (max-width: 1500px) {
  body .chart-group > .card {
    flex-basis: calc(33% - 2rem);
  }
}
@media (max-width: 1200px) {
  body .chart-group > .card {
    flex-basis: calc(50% - 2rem);
  }
}
@media (max-width: 800px) {
  body .chart-group > .card {
    flex-basis: calc(100% - 2rem);
  }
}
.chart-group {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1em;
}
.chart-group > .card {
  min-width: 0;
  margin: 0 2rem 2rem 0;
  flex: 0 0 calc(25% - 2rem);
  display: flex;
  flex-direction: column;
}
.chart-group > .card.fullscreen {
  flex: auto;
}
.chart-group > .card .chart {
  overflow-x: auto;
}
.chart-group > .card .card-action .chart-form-select {
  width: 100%;
}
.chart-group > .card .card-content .card-title {
  color: inherit;
  line-height: inherit;
  font-size: 1em;
}
.chart-group > .card svg {
  shape-rendering: crispEdges;
}
.chart-group > .card svg text,
.chart-group > .card svg line.axis,
.chart-group > .card svg polygon,
.chart-group > .card svg circle {
  shape-rendering: geometricPrecision;
}
.chart-group > .card svg .legend {
  cursor: pointer;
  pointer-events: bounding-box;
}
.chart-actions .remove {
  float: right;
}
.select-chart-observation-group {
  margin-top: 3em;
  max-width: 416px;
  display: block;
}
form-tabbed-entities {
  display: flex;
}
form-tabbed-entities > ul {
  margin: 0;
}
form-tabbed-entities > ul > li {
  padding: 0.5em;
  border: 1px solid #d0d0d0;
  margin: -1px;
  line-height: 36px;
  cursor: pointer;
}
form-tabbed-entities > ul > li.active {
  border-color: #283593;
  border-width: 2px;
  border-right: none;
  margin-right: -2px;
  z-index: 2;
  position: relative;
  background-color: white;
  color: #283593;
  font-weight: bold;
}
form-tabbed-entities > ul > li.active > span.missing-value {
  font-weight: normal;
}
form-tabbed-entities > ul > li.error,
form-tabbed-entities > ul > li.error > span {
  color: #f44336;
}
form-tabbed-entities > ul > li.description {
  border: none;
}
form-tabbed-entities > div {
  border: 2px solid #283593;
  padding: 1em 2em;
  margin-top: -1px;
  margin-bottom: -2px;
  flex: 1;
}
form-tabbed-entities > div .tabbed-actions {
  text-align: right;
}
@media (max-width: 800px) {
  body form-tabbed-entities {
    flex-direction: column;
  }
  body form-tabbed-entities > ul {
    margin-bottom: 1em;
  }
  body form-tabbed-entities > ul > li.active {
    border-right: 1px solid #d0d0d0;
  }
}
.trials-fact-dataset-title {
  font-size: 2em;
}
.uncontainer.top-level-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 0;
  gap: 5px;
  border-bottom: 1px solid #B9B9B9;
}
.uncontainer.top-level-tabs li {
  display: inline-block;
  position: relative;
  cursor: pointer;
  border-radius: 4px 4px 0px 0px;
  padding: 7px 16px 4px 16px;
  background: #EEE;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  height: 36px;
}
.uncontainer.top-level-tabs li.active {
  font-weight: 700;
  background: #FFF;
  border: 1px solid #B9B9B9;
  border-bottom: none;
}
.uncontainer.top-level-tabs li.active:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 4px;
  background: white;
}
.obs-groups-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 16px;
  margin-bottom: 36px;
}
.obs-groups-tabs li {
  display: inline-block;
  cursor: pointer;
  color: #283593;
  padding: 0.5em 1.3em;
}
.obs-groups-tabs li.active {
  color: #283593;
  border-bottom: 2px solid #283593;
}
.obs-groups-tabs li.management.active {
  color: #ff9800;
  border-bottom: 2px solid #ff9800;
}
.obs-groups-tabs li.management {
  color: rgba(255, 152, 0, 0.8);
}
.uncontainer.dataset-facts-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 0;
}
.uncontainer.dataset-facts-tabs li {
  display: inline-block;
  cursor: pointer;
  color: #283593;
  padding: 0.5em 1em;
  padding-bottom: 0;
}
.uncontainer.dataset-facts-tabs li.active {
  color: #283593;
  border-bottom: 2px solid #283593;
}
.uncontainer.dataset-facts-tabs li.management.active {
  color: #ff9800;
  border-bottom: 2px solid #ff9800;
}
.uncontainer.dataset-facts-tabs li.management {
  color: rgba(255, 152, 0, 0.8);
}
.analysis-tab-item {
  background: #F2F2F2;
  border-radius: 2px;
  position: relative;
  width: 100%;
  height: 40px;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.analysis-tab-item-selected {
  background: rgba(104, 159, 56, 0.2);
  border-radius: 2px;
  position: relative;
  width: 100%;
  height: 40px;
  align-items: center;
  display: flex;
}
.analysis-item-icon {
  vertical-align: middle;
  position: relative;
  left: 16px;
  cursor: default;
}
.analisys-item-text {
  margin-left: 25px;
  position: relative;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.74);
  flex: none;
  order: 4;
  flex-grow: 0;
}
.stats-marker {
  background: rgba(0, 0, 0, 0.38);
  border-radius: 10px;
  right: 12px;
  position: absolute;
  width: 25px;
  display: flex;
  justify-content: center;
}
.search-input-container {
  display: flex;
  align-items: center;
  width: 100%;
}
.analysis-tab-item-selected {
  background: rgba(104, 159, 56, 0.2);
  border-radius: 2px;
  position: relative;
  width: 100%;
  height: 40px;
  align-items: center;
  display: flex;
}
.report-container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.report-container-header .add-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15px;
  color: #283593;
}
.report-container-header .add-button:hover {
  cursor: pointer;
}
.analysis-item-icon {
  vertical-align: middle;
  position: relative;
  left: 16px;
  cursor: default;
}
.analisys-item-text {
  margin-left: 25px;
  position: relative;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.74);
  flex: none;
  order: 4;
  flex-grow: 0;
}
.site-and-dimension-container {
  display: flex;
  align-items: baseline;
  gap: 30px;
}
.interaction-graph-filter {
  display: flex;
  align-items: center;
  gap: 2em;
}
.site-filter {
  width: 60%;
}
.site-filter > div > div.filters > div {
  width: 100%;
}
.site-filter > div > div.filters {
  background-color: transparent;
}
.site-filter > div > div > div > div > .search-input {
  width: 100%;
}
.site-filter > div > div.selections {
  overflow: auto;
  height: 0px;
}
.site-filter-map {
  width: 108px;
}
.site-filter-map > div > div.filters > div {
  width: 100%;
}
.site-filter-map > div > div.filters {
  background-color: transparent;
}
.site-filter-map > div > div > div > div > .search-input {
  width: 100%;
}
.site-filter-map > div > div.selections {
  overflow: auto;
  height: 0px;
}
.playback-button {
  background-color: transparent;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.playback-button:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.2);
}
.playback-button:focus {
  background-color: rgba(255, 255, 255, 0.5);
}
.big-play-icon {
  user-select: none;
  font-size: 9rem;
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.028), #090808);
  border-radius: 50%;
}
.big-play-icon:hover {
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5));
}
.playback-visits-container {
  background-color: white;
  width: 305px;
  height: 60%;
  margin-bottom: 90px;
  scrollbar-width: thin;
  border-radius: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
}
.playback-visits-container-header {
  font-weight: 500;
  font-size: 1.2rem;
  color: #424242;
  padding: 1rem;
  border-bottom: 1px solid #E8E8E8;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.playback-visits-container-header i {
  cursor: pointer;
}
.playback-visits-container-content {
  color: #424242;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  overflow-y: auto;
  scrollbar-width: thin;
}
.playback-visit-container {
  color: #424242;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 1rem;
  padding: 4px 8px 4px 8px;
}
.playback-visit-container-active {
  color: #424242;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 1rem;
  padding: 4px 8px 4px 8px;
  background-color: rgba(104, 159, 56, 0.2);
}
.playback-visit-container-active span:first-child {
  font-weight: 500;
}
.playback-visit-counters {
  color: white;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0 2px 0 2px;
  border-radius: 2px;
  height: 1.2rem;
  display: flex;
  flex-wrap: nowrap;
}
.late-collection-counter {
  background-color: #ff9800;
}
.in-progress-collection-counter {
  background-color: #2196f3;
}
.done-collection-counter {
  background-color: #689F38;
}
.big-playback-button {
  background-color: transparent;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.big-playback-button:focus {
  background-color: transparent;
}
.big-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: white;
  cursor: pointer;
  margin-left: 50%;
}
.playback-container {
  display: flex;
  flex-direction: row;
  width: 96%;
  padding-right: 2rem;
  gap: 4rem;
  background: linear-gradient(to right, #2a3c22, rgba(42, 60, 34, 0.8));
  height: 38px;
  margin-left: 1px;
}
.playback-controls {
  display: flex;
  width: fit-content;
}
.progress-bar-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.overview-playback-switch {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-top: 10px;
  margin-bottom: 21px;
  font-weight: 500;
  cursor: pointer;
  height: 46px;
}
.overview-playback-switch div {
  padding: 10px 80px;
  border: 1px solid #74796d;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 187px;
}
.overview-playback-switch div i {
  display: none;
}
.overview-playback-switch .active {
  background-color: #dae7c9;
  cursor: default;
}
.overview-playback-switch .active i {
  display: inline-block;
}
.overview-playback-switch .disabled {
  cursor: default;
  border-color: #ccc;
  color: #ccc;
}
.overview-playback-switch div:first-child {
  border-radius: 100px 0px 0px 100px;
}
.overview-playback-switch div:last-child {
  border-left: 0px;
  border-radius: 0px 100px 100px 0px;
}
.fact-playback-filters {
  display: flex;
  margin-right: auto;
  align-items: baseline;
}
.fact-playback-filters > :not(:last-child) {
  margin-right: 2em;
}
.map-filters {
  display: flex;
}
.dimension-filter {
  width: 20%;
}
.geo-zone-filter {
  width: 13%;
}
.stats-marker {
  background: rgba(0, 0, 0, 0.38);
  border-radius: 10px;
  right: 12px;
  position: absolute;
  width: 25px;
  display: flex;
  justify-content: center;
}
list-loader.uncontainer.trial-facts-list-loader .trial-facts-list-loader-table td.actions-cell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
list-loader.uncontainer.trial-facts-list-loader .trial-facts-list-loader-table td.actions-cell > i {
  color: #9e9e9e;
  line-height: 36px;
}
list-loader.uncontainer.trial-facts-list-loader .trial-facts-list-loader-table td.actions-cell > i.action {
  cursor: pointer;
}
list-loader.uncontainer.trial-facts-list-loader .trial-facts-list-loader-table td.actions-cell .spacer {
  flex: 1;
}
list-loader.uncontainer.trial-facts-list-loader .trial-facts-list-loader-table tr.expanded {
  border-bottom: none;
}
list-loader.uncontainer.trial-facts-list-loader .trial-facts-list-loader-table tr.expansion {
  border-top: none;
  cursor: initial;
}
list-loader.uncontainer.trial-facts-list-loader .trial-facts-list-loader-table tr.expansion .extra-title {
  font-weight: bold;
}
list-loader.uncontainer.trial-facts-list-loader .trial-facts-list-loader-table tr.expansion td > div {
  display: inline-block;
  margin: 0 1em 1em 0;
  width: 200px;
  vertical-align: top;
}
list-loader.uncontainer.trial-facts-list-loader .trial-facts-list-loader-table tr.expansion td .img-container {
  align-self: flex-start;
  display: inline-block;
  margin: 0 1em 1em 0;
}
list-loader.uncontainer.trial-facts-list-loader .trial-facts-list-loader-table tr.expansion td .img-container img {
  width: 200px;
}
list-loader.uncontainer.trial-facts-list-loader .trial-facts-list-loader-table tr.expansion td .img-container flat-button {
  display: block;
}
list-loader.uncontainer.trial-facts-list-loader .trial-facts-list-loader-table tr.expansion td .img-container .document-link {
  display: flex;
  align-items: center;
}
list-loader.uncontainer.trial-facts-list-loader .trial-facts-list-loader-table tr.expansion td .img-container .document-link > i {
  margin-right: 5px;
}
.trial-facts-actions {
  display: flex;
  justify-content: space-between;
}
.trial-facts-weather-export {
  display: flex;
  justify-content: flex-end;
  max-width: 1080px;
  margin: 0 auto;
}
trial-facts .charts {
  max-width: 1080px;
  margin: 0 auto;
}
trial-facts .charts > div {
  margin-bottom: 4rem;
}
.trial-facts-climate-info {
  max-width: 1080px;
  margin: 0 auto 1em;
  color: #9e9e9e;
}
.clickable-cell {
  cursor: pointer;
}
@media (min-width: 801px) {
  trial-facts .trial-facts-list-loader .trial-facts-list-loader-table thead tr:after {
    content: '';
    overflow-y: scroll;
    visibility: hidden;
    height: 0;
  }
  trial-facts .trial-facts-list-loader .trial-facts-list-loader-table thead th {
    flex: 1 auto;
    display: block;
    width: 1px;
    min-width: 100px;
  }
  trial-facts .trial-facts-list-loader .trial-facts-list-loader-table > tbody > tr {
    margin-top: -1px;
  }
  trial-facts .trial-facts-list-loader .trial-facts-list-loader-table tr {
    display: flex;
  }
  trial-facts .trial-facts-list-loader .trial-facts-list-loader-table td {
    flex: 1 auto;
    width: 1px;
    min-width: 100px;
    word-wrap: word-break;
  }
  trial-facts .trial-facts-list-loader .trial-facts-list-loader-table td.actions-cell > .material-icons {
    margin-left: 0.5em;
  }
  trial-facts .trial-facts-list-loader .trial-facts-list-loader-table td.actions-cell > i {
    line-height: initial;
  }
  trial-facts .trial-facts-list-loader .trial-facts-list-loader-table tbody {
    display: block;
    overflow-y: auto;
    max-height: 70vh;
  }
  trial-facts facts-table .load-more-results {
    margin-top: 1em;
  }
}
.with-comment-extra-marker,
.with-picture-or-document-extra-marker,
.value-edited-marker {
  position: relative;
  padding-left: 10px;
}
.show-icons-for-comments .with-comment-extra-marker .comment-extra-marker:after {
  position: absolute;
  top: 0px;
  right: 0px;
  padding-right: 4px;
  font-size: 0.7em;
  color: #283593;
  font-family: "Material Icons";
  content: "\e26c";
}
.show-icons-for-photos-and-documents .with-picture-or-document-extra-marker .picture-or-document-extra-marker::after {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translate(0, -50%);
  padding-right: 4px;
  font-size: 0.7em;
  color: #283593;
  font-family: "Material Icons Outlined";
  content: "\e3f4";
}
.show-icons-for-changed-data .value-edited-marker .edited-marker::after {
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding-right: 4px;
  font-size: 0.7em;
  font-family: "Material Icons";
  color: #283593;
  content: "\e042";
}
.dashboard-container {
  min-height: 0;
  flex: 1;
}
.dashboard-container > .dashboard {
  height: 89vh;
  display: flex;
  flex-direction: column;
}
.dashboard-container > .dashboard > trial-facts {
  min-height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.dashboard-container > .dashboard > trial-facts > * {
  flex-shrink: 0;
}
.dashboard-container > .dashboard > trial-facts .overview-table-container {
  position: relative;
  min-height: 0;
  flex: 1;
  overflow: auto;
  margin-bottom: 1em;
  overflow-anchor: none;
}
.dashboard-container > .dashboard > trial-facts .overview-table-container.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.dashboard-container > .dashboard > trial-facts .overview-table-container.full-screen .loading {
  opacity: 1;
  filter: brightness(70%);
}
.filters-row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.filters-row .btn-show-table-full-screen {
  display: block;
  margin-bottom: 12px;
}
.filters-row .btn-show-table-full-screen > button {
  background-color: #e2e2e2;
  color: rgba(0, 0, 0, 0.87);
}
.btn-table-exit-full-screen {
  position: fixed;
  top: 6px;
  right: 24px;
  z-index: 999;
}
.overview-table {
  color: #414141;
  table-layout: fixed;
}
.overview-table > colgroup > col {
  width: 10rem;
}
.overview-table > colgroup > col:first-child {
  width: 15rem;
}
.overview-table.loading {
  pointer-events: none;
  opacity: 0.5;
}
.overview-table thead {
  border: none;
}
.overview-table th {
  height: 32px;
  padding: 4px;
  text-align: center;
  font-weight: normal;
  background-color: #f4f4f4;
  border-radius: 0;
  outline: 1px solid white;
  color: #414141;
  white-space: nowrap;
  position: sticky;
  text-overflow: ellipsis;
  overflow-x: hidden;
  z-index: 12;
}
.overview-table th:first-child {
  left: 0;
  z-index: 13;
}
.overview-table th > .overview-header-errors-container {
  display: none;
}
.overview-table .overview-header-has-errors {
  color: #f44336;
  z-index: 15;
  overflow: inherit;
}
.overview-table th:hover > .overview-header-errors-container {
  display: block;
  position: relative;
}
.overview-table .overview-header-errors {
  position: absolute;
  left: -4px;
  right: -4px;
  top: 6px;
  padding: 5px;
  white-space: normal;
  background-color: white;
  border: 1px solid #f44336;
  text-align: left;
}
.overview-table .overview-header-edit-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.overview-table .overview-header-edit-action > span {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.overview-table .overview-header-edit-action > i {
  cursor: pointer;
  color: #283593;
  font-size: 18px;
  margin-left: 5px;
}
.overview-table .overview-header-edit-action > i:hover {
  cursor: pointer;
}
.overview-table .overview-header-edit-action.with-unit-switch {
  flex-direction: column;
}
.overview-table td {
  padding: 0;
  border-radius: 0;
  white-space: nowrap;
}
.overview-table td:first-child {
  background-color: #f4f4f4;
  position: sticky;
  left: 0;
  outline: 1px solid white;
  z-index: 11;
}
.overview-table td:not(:first-child) {
  background-color: #eff5eb;
  border: 1px solid white;
  text-align: center;
}
.overview-table td:not(:first-child) > div {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 12px;
}
.overview-table tbody > tr {
  height: 100%;
}
.overview-table tbody > tr > td {
  height: 0;
}
.overview-table tbody > tr > td > input {
  padding-right: 12px;
}
.overview-table .can-edit {
  cursor: pointer;
  position: relative;
  z-index: 10;
}
.overview-table a {
  cursor: pointer;
  color: #283593;
}
@-moz-document url-prefix() {
  .overview-table tbody > tr > td {
    height: 100%;
  }
}
.overview-header-group-title {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.overview-header-group-title > span {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.overview-header-group-title > i.material-icons {
  display: inline;
  font-size: 18px;
}
.overview-empty-value {
  height: 100%;
  background-color: #e2e2e2;
  outline: 1px solid rgba(185, 185, 185, 0.1);
  outline-offset: -2px;
}
.overview-have-history-value {
  height: 100%;
  background-color: #9c9c9c;
  outline: 1px solid rgba(185, 185, 185, 0.1);
  outline-offset: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.can-edit > .overview-empty-value {
  background-color: white;
  outline: 1px solid #f5a623;
  outline-offset: -2px;
}
.data-entry-not-allowed,
.data-entry-not-allowed > .overview-empty-value {
  background-color: #f4f4f4;
}
.overview-plot-header-name-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 10rem;
}
.overview-plot-header-name-container div:not(:first-child) {
  font-weight: normal;
  text-align: center;
  width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.overview-plot-header {
  display: flex;
  align-items: center;
  padding: 0 0.5em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.overview-plot-header > :first-child {
  font-weight: bold;
  text-align: left;
}
.overview-plot-header > :last-child {
  font-size: 12px;
  margin-left: 0.5em;
  overflow: hidden;
  flex: 1;
}
.overview-plot-header > :last-child > div {
  height: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.loading-cell {
  color: inherit;
}
.loading-cell:after {
  font-family: 'Material Icons';
  content: '\e2c3';
  position: absolute;
  top: 0px;
  left: 0px;
  padding-left: 3px;
}
.loaded-cell {
  background-color: #eff5eb;
}
.loaded-cell:after {
  font-family: 'Material Icons';
  content: '\e876';
  position: absolute;
  top: 0px;
  left: 0px;
  color: green;
  padding-left: 3px;
}
.chart-container {
  position: relative;
  margin-bottom: 10px;
}
.meteoblue-logo {
  background: url(/static/4af774fbd4ad002b423fc8f21602898c.png) no-repeat center / 80%;
  width: 60px;
  height: 60px;
  padding: 0px;
  margin: 0px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 80%;
  z-index: 50;
}
.right-side {
  width: 70%;
}
.filters-container {
  float: right;
}
.trial-technical-scorecard-table {
  min-height: 25rem;
}
.trial-technical-scorecard-table table {
  border-collapse: separate;
  border-spacing: 0;
}
.trial-technical-scorecard-table table tr > td:first-child,
.trial-technical-scorecard-table table th:first-child {
  position: sticky;
  left: 0px;
  background-color: inherit;
  z-index: 2;
}
.trial-technical-scorecard-table table thead > tr > th:first-child {
  background-color: white;
  z-index: 4;
}
.trial-technical-scorecard-table table tr.varieties > td {
  white-space: nowrap;
}
.trial-technical-scorecard-table table.striped > tbody > tr:nth-child(even) {
  background-color: white;
}
.trial-technical-scorecard-table .th-width {
  width: 185px;
}
.trial-technical-scorecard-table .trait-name {
  position: sticky;
  left: 196px;
  background: white;
  z-index: 3;
}
.filter-set-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
  left: 32px;
  top: 256px;
  float: left;
}
.trait-selection-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  overflow-y: hidden;
  /* identical to box height, or 150% */
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  /* QT/gray 74% */
  color: rgba(0, 0, 0, 0.74);
}
.unit-select {
  background-color: #EBEBEB;
  border-radius: 5px;
  display: block;
  height: fit-content;
  border-color: transparent;
  border-style: solid;
  border-width: 0 8px;
  text-align: center;
  text-overflow: ellipsis;
}
.unit-select:hover {
  background-color: #E1E1E1;
}
.trait-visit-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  gap: 10px;
}
.trait-visit-header .trait-visit-header-name {
  font-size: 12px;
  width: 70%;
}
.trait-visit-header .trait-visit-header-selector {
  margin-left: auto;
  width: 39%;
}
.trait-visit-header .trait-visit-header-selector > div {
  margin-top: 0;
}
.trait-visit-header .trait-visit-header-selector > div > div > input {
  margin: 0;
  height: 2rem;
  font-size: 12px;
}
.trait-visit-header .trait-visit-header-selector > div > div > span {
  font-size: 12px;
}
.trait-visit-header .trait-visit-header-selector > div > label {
  display: none;
}
.trait-container {
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: center;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.trait-container .trait-item {
  background: #F2F2F2;
  border-radius: 2px;
  position: relative;
  width: 85%;
  height: 40px;
  align-items: center;
  display: flex;
  overflow: hidden;
}
.trait-container .trait-item-selected {
  background: rgba(104, 159, 56, 0.2);
  border-radius: 2px;
  position: relative;
  width: 85%;
  height: 40px;
  align-items: center;
  display: flex;
  overflow: hidden;
}
.trait-container .trait-item-stats {
  background: #F2F2F2;
  width: 15%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.trait-container .trait-item-stats-selected {
  background: rgba(104, 159, 56, 0.2);
  width: 15%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.deselect-all {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #283593;
  width: 85%;
}
.deselect-all span {
  cursor: pointer;
}
.show-in-stats {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
  width: 15%;
}
.trait-icon {
  cursor: pointer;
}
.no-traits-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  /* QT/gray 74% */
  color: rgba(0, 0, 0, 0.74);
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.no-traits-text-main {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.18px;
  /* QT/gray 74% */
  color: rgba(0, 0, 0, 0.74);
}
.center-element-empty {
  display: flex;
  justify-items: center;
  width: 60%;
  margin-top: 20%;
  margin-left: 20%;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}
.trait-item-name {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  cursor: default;
  align-items: center;
  letter-spacing: 0.5px;
  /* QT/gray 74% */
  color: rgba(0, 0, 0, 0.74);
  margin-left: 10px;
  text-align: left;
  cursor: pointer;
}
.averages-table table,
.averages-table tr,
.averages-table td {
  border: none !important;
  border-collapse: collapse !important;
}
.analysis-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 18px;
  flex-wrap: nowrap;
}
.analysis-container.filters-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 0px;
  width: 25vw;
}
.analysis-container.filters {
  display: flex;
  flex-direction: column;
  gap: 4em;
  overflow-y: auto;
  width: 25vw;
  padding: 2px;
}
.analysis-container.text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-line;
  overflow: hidden;
  display: flex;
  letter-spacing: 0.25px;
  /* QT/gray 74% */
  color: rgba(0, 0, 0, 0.74);
  height: 10%;
  width: 25vw;
  flex-direction: column;
  gap: 0.2em;
}
.analysis-container.text:hover {
  width: 60vw;
  height: 20%;
  z-index: 20;
  background-color: white;
}
.trial-facts-enable-removal {
  display: block;
  margin-bottom: 1em;
}
.sites-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
}
.stats-title {
  font-family: 'Roboto';
  font-style: normal;
  font-size: 30px;
  margin-bottom: 2px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  justify-content: center;
}
.cell-aligned-left {
  text-align: left;
}
.cell-aligned-right {
  text-align: right;
}
.cell-aligned-center {
  text-align: center;
}
.cell-greyed-out {
  color: #ababab;
}
.w-50p {
  width: 50%;
}
.blank-row {
  height: 2.2em;
}
.chart-height {
  height: 250px;
  position: relative;
}
.chart-button {
  position: absolute;
  top: 10px;
  left: 70px;
  z-index: 10;
}
.chart-text {
  position: absolute;
  left: 100px;
  z-index: 10;
  width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.compare-to-control-zone-site-container {
  display: flex;
  margin: 2em;
  gap: 1em;
}
.zone-container {
  border: 4px solid;
  border-radius: 6px;
  padding: 5px;
  width: 20rem;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 25rem;
}
.zone-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
.site-alias {
  font-weight: bold;
}
.measurement-type-edit .file-upload,
.measurement-type-edit .file-upload-img,
.measurement-type-edit .file-upload-error {
  margin-left: 3em;
}
.filter-title {
  color: #283593;
}
.filter-summary {
  margin-bottom: 1em;
}
.filter-summary .filter-value {
  font-size: 0.8em;
}
.filter-summary .filter-value.not-set {
  color: #9e9e9e;
}
.filter-widget {
  display: flex;
  flex-direction: column;
}
list-loader.list-loader-left-filters > div {
  display: flex;
}
list-loader.list-loader-left-filters form.compact {
  flex-direction: column;
}
list-loader.list-loader-left-filters form.compact .filters-title {
  display: block;
  color: #283593;
  font-size: 1.2em;
  margin-bottom: 1em;
}
list-loader .filters-title {
  display: none;
}
list-loader form.compact {
  flex-direction: row;
  margin-bottom: 1rem;
}
list-loader form.compact input {
  width: 150px;
  min-width: inherit;
}
list-loader form.compact .input-field.input-field-select {
  margin-top: 1rem;
}
list-loader form.compact .input-field.input-field-select .select-wrapper input {
  margin-bottom: 0;
}
list-loader form.compact.no-sorting.new-filters {
  justify-content: flex-end;
}
list-loader form.compact.new-filters {
  align-items: flex-start;
  flex-wrap: nowrap;
}
list-loader form.compact.new-filters > list-filters {
  margin-right: 0;
}
list-loader .list-loader-content {
  flex: 1;
}
list-loader .list-loader-content .wide-column {
  min-width: 10rem;
}
list-loader .list-loader-content thead > tr > th {
  text-align: center;
}
list-loader .list-loader-content table.left-aligned-content thead > tr > th {
  text-align: left;
}
list-loader .list-loader-content table.left-aligned-content tbody tr td {
  text-align: left;
}
list-loader .list-loader-content thead > tr > th:first-child,
list-loader .list-loader-content td:first-child {
  text-align: left;
}
list-loader .list-loader-content table tbody td {
  text-align: center;
  padding: 8px 5px;
  vertical-align: top;
}
list-loader .list-loader-content table tbody td:first-child {
  padding-left: 5px !important;
}
list-loader .list-loader-content table thead th {
  position: sticky;
  top: -1px;
  z-index: 1;
  background-color: white;
  padding: 8px 5px !important;
  vertical-align: top !important;
}
list-loader .list-loader-content table thead th.center,
list-loader .list-loader-content table tbody th.center,
list-loader .list-loader-content table thead td.center,
list-loader .list-loader-content table tbody td.center {
  vertical-align: middle !important;
}
list-loader .list-loader-content table thead th.center input[type='checkbox'],
list-loader .list-loader-content table tbody th.center input[type='checkbox'],
list-loader .list-loader-content table thead td.center input[type='checkbox'],
list-loader .list-loader-content table tbody td.center input[type='checkbox'] {
  left: 0;
  opacity: 1;
  position: inherit;
  width: 1.25rem;
  height: 1.25rem;
  accent-color: #9ed670;
}
list-loader .list-loader-content table thead th:after,
list-loader .list-loader-content th:before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
}
list-loader .list-loader-content table thead th:after {
  bottom: 0;
  border-bottom: 1px solid #d0d0d0;
}
list-loader .list-loader-content table tr.selected-row {
  background-color: #edf5e3;
}
list-loader .list-loader-content table .text-left {
  text-align: left;
}
@media (max-width: 600px) {
  list-loader form.compact.new-filters {
    flex-wrap: wrap;
  }
  list-loader form.compact.new-filters > list-filters {
    margin-left: 0;
  }
}
@media (max-width: 800px) {
  list-loader table tbody td:first-child a {
    white-space: nowrap;
    font-size: 20px;
    width: 23rem;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px 11px !important;
  }
  list-loader table tbody td {
    padding: 4px 16px !important;
    text-align: left !important;
  }
}
@media (max-width: 800px) {
  list-loader.list-loader-left-filters > div {
    flex-direction: column;
  }
  .list-loader-content table tbody tr {
    position: relative;
  }
  .list-loader-content table td {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
  }
  .list-loader-content table td > span.small-screen-caption {
    flex-basis: 10em;
    flex-shrink: 0;
  }
  .list-loader-content table td.actions-cell {
    position: absolute;
    top: 5px;
    right: 0px;
  }
}
.uncontainer.list-loader-actions {
  float: right;
  display: flex;
}
.uncontainer.list-loader-actions loading-indicator {
  margin-right: 1em;
  font-size: 0.9em;
}
.cell-subtext {
  color: #9e9e9e;
  font-size: 0.8em;
}
.dimension-advanced-search-actions {
  display: flex;
  margin-bottom: 1em;
  justify-content: space-between;
  padding-top: 5px;
  background-color: white;
  position: sticky;
  top: 0px;
  align-items: center;
}
.dimension-advanced-search-actions span.action {
  color: #9e9e9e;
}
.dimension-advanced-search-actions .select-all span.action {
  color: #283593;
}
.dimension-advanced-search-actions .deselect-all span.action {
  color: #b71c1c;
}
.dimension-advanced-search-actions .select-all span.flat-button-disabled,
.dimension-advanced-search-actions .deselect-all span.flat-button-disabled {
  color: #9e9e9e;
  cursor: default;
}
.list-loader-selectable .list-loader-content > table > tbody > tr:hover {
  background-color: #eee;
  cursor: pointer;
}
.list-loader-selectable .list-loader-content > table > tbody > tr:hover > td.selection-indicator {
  background-color: white;
}
.list-loader-selectable .list-loader-content > table > thead > tr > th.selection-indicator {
  border-top: 1px solid white;
}
.list-loader-selectable .list-loader-content > table > thead > tr > th.selection-indicator::after {
  background-color: white;
}
.list-loader-selectable .list-loader-content > table > tbody > tr > td.selection-indicator {
  width: 48px;
  padding: 0px 5px;
  color: #283593;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  text-align: center;
}
.trial-print-plots > flat-button,
.trial-print-plots > form-checkbox {
  display: block;
  margin-bottom: 1em;
}
.trial-print-plots > primary-button {
  display: block;
  margin-bottom: 2em;
}
.trial-print-plots .plot-section-title {
  color: #283593;
  font-size: 1.2em;
  margin-top: 1em;
}
.trial-print-plots .pair {
  margin-bottom: 0.5em;
}
.trial-print-plots .pair > span:first-child {
  display: inline-block;
  font-weight: bold;
  color: #757575;
}
.trial-print-plots .plot-range {
  display: flex;
}
.trial-print-plots .plot-range > div {
  color: #757575;
  width: 100px;
  padding-top: 0.5em;
  flex-grow: 0;
  flex-shrink: 0;
}
.trial-print-plots ul.plots > li {
  display: inline-block;
  padding: 0.5em 0.3em;
  border: 1px dashed #d0d0d0;
  width: 120px;
  margin-right: 1em;
  margin-bottom: 1em;
  position: relative;
}
.trial-print-plots ul.plots > li .pair {
  margin: 0;
  padding: 1px;
}
.trial-print-plots ul.plots > li .near-qr {
  width: 100px;
}
.trial-print-plots ul.plots > li .qr {
  position: absolute;
  top: 0px;
  right: 0px;
  padding-right: 5px;
  padding-top: 5px;
}
.trial-print-plots ul.plots > li.empty {
  color: #9e9e9e;
  font-style: italic;
}
.trial-print-plots ul.plots.plots-large > li {
  width: 200px;
}
@media print {
  .trial-print-plots > h1 {
    display: none;
  }
  .trial-print-plots > flat-button,
  .trial-print-plots > form-checkbox {
    display: none;
  }
  .trial-print-plots > primary-button {
    display: none;
  }
}
form.chart-edit {
  max-width: none;
}
form.chart-edit .card-content {
  display: flex;
}
form.chart-edit .actions {
  margin-right: 3em;
  display: flex;
  flex-direction: column;
}
form.chart-edit .actions .select-wrapper input.select-dropdown {
  height: 2rem;
  line-height: 2rem;
}
form.chart-edit .actions-section {
  margin-bottom: 1em;
}
form.chart-edit .actions-section .actions-section-title {
  color: #283593;
  font-size: 0.8em;
}
form.chart-edit .actions-section:last-child {
  margin-bottom: 0;
}
form.chart-edit .preview {
  flex: 1;
  overflow-x: auto;
  position: relative;
}
form.chart-edit .preview .chart {
  overflow-x: auto;
}
form.chart-edit .preview .no-results {
  text-align: center;
}
form.chart-edit .preview .validation-error {
  text-align: center;
  margin-top: 0.5em;
}
@media only screen and (max-width: 600px) {
  form.chart-edit .card-content {
    flex-direction: column;
  }
}
.i18n-text-input .language-extra-translations {
  margin-left: 3em;
  margin-bottom: 2em;
}
.i18n-text-input .language-row {
  align-items: baseline;
}
.i18n-text-input .language-row-textarea {
  align-items: center;
}
.i18n-text-input .language-row,
.i18n-text-input .language-row-textarea {
  display: flex;
}
.i18n-text-input .language-row .input-field,
.i18n-text-input .language-row-textarea .input-field,
.i18n-text-input .language-row form-text-input,
.i18n-text-input .language-row-textarea form-text-input,
.i18n-text-input .language-row form-textarea,
.i18n-text-input .language-row-textarea form-textarea {
  flex: 1;
  margin-right: 1em;
}
.i18n-text-input .language-row .language-info,
.i18n-text-input .language-row-textarea .language-info {
  text-transform: uppercase;
  color: #9e9e9e;
}
.i18n-text-input .language-row flat-button .material-icons,
.i18n-text-input .language-row-textarea flat-button .material-icons {
  font-size: 14px;
}
.i18n-text-input .language-row flat-button .action div,
.i18n-text-input .language-row-textarea flat-button .action div {
  margin-left: 20px;
  text-transform: uppercase;
  font-size: 0.8em;
}
.i18n-text-input .language-row flat-button > span,
.i18n-text-input .language-row-textarea flat-button > span {
  margin-right: 0;
}
#download_excel_template_button {
  border-radius: 4px;
  padding: 4px 8px;
}
#download_excel_template_button span.action {
  margin: 0;
}
#download_excel_template_button span.action > div {
  margin: 0;
}
#download_excel_template_button:hover {
  background-color: #eeeeee;
}
.import-facts.go-back {
  color: #9e9e9e;
  display: block;
  margin-bottom: 1em;
  cursor: pointer;
}
.import-facts .description {
  margin-bottom: 1em;
}
.import-facts .download-template .error {
  color: #f44336;
  margin-left: 32px;
  margin-bottom: 0.5em;
}
.import-facts .success {
  color: #4caf50;
  margin-top: 1em;
}
.import-facts .import-errors {
  margin-left: 32px;
}
.import-facts .import-errors > span,
.import-facts .import-errors td:last-child {
  color: #f44336;
}
.import-facts loading-indicator {
  line-height: 36px;
}
.import-facts loading-indicator > span {
  font-size: 1em;
}
.import-facts .preloader-wrapper.small {
  width: 24px;
  height: 24px;
}
.import-facts .text-with-icon > i.left,
.import-facts.text-with-icon > i.left {
  margin-right: 10px;
}
.import-facts .text-with-icon > span,
.import-facts.text-with-icon > span {
  line-height: 26px;
}
.import-facts-links {
  display: flex;
  justify-content: space-between;
}
.import-facts-secondary-links {
  display: flex;
}
.import-facts-secondary-links a {
  color: #283593;
}
.import-update-facts-options {
  margin-top: 2em;
}
.file-upload .file-field {
  display: inline-block;
}
.file-upload .file-field span.action {
  position: relative;
}
.file-upload .file-field input[type='file'] {
  font-size: 0;
  height: 100%;
}
file-upload.spacing-top .file-upload {
  margin-top: 2rem;
}
.file-upload-img {
  max-width: 300px;
  display: block;
}
.file-upload-error {
  color: #f44336;
}
.file-upload-section {
  display: flex;
  align-items: center;
}
.file-upload-section file-upload {
  flex: 1;
  margin-right: 1em;
}
.map,
.map > div {
  height: 60vh;
  color: #44483e;
  font-weight: 400;
}
.map-popup-title {
  font-weight: 400;
  font-size: 1.4em;
  margin-bottom: 0.5em;
}
.metadata-row {
  font-weight: 400;
  display: flex;
  align-items: center;
}
.metadata-row i {
  font-size: 16px;
  color: #74796d;
  margin-right: 8px;
}
.metadata-dimension-row {
  font-weight: 400;
  display: flex;
  align-items: center;
  font-weight: 500;
}
.metadata-dimension-row i {
  font-size: 16px;
  color: #74796d;
  margin-right: 8px;
}
.map-popup-info {
  line-height: 1.3rem;
  font-weight: 400;
  color: #44483e;
}
.map-popup-delete {
  cursor: pointer;
  color: #b71c1c;
}
.map-btn {
  background-color: white;
  margin: 10px 11px 0px 0px;
  padding: 5px;
  cursor: pointer;
}
.map-btn.map-btn-active {
  border: 1px solid #757575;
  box-shadow: inset 0px 0px 4px #757575;
}
.map-popup-container {
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.pac-container {
  z-index: 99999;
}
.map-legend {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  width: 200px;
  margin-right: 10px;
  overflow: auto;
}
.map-legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.map-legend-color {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid black;
}
.map-legend-code {
  font-size: 14px;
}
.map-legend-title {
  font-size: 16px;
  font-weight: 500;
  margin: 0 auto 10px;
}
.container > .uncontainer.trial-nav {
  display: inline-block;
}
.trial-collection-cell {
  padding: 5px;
  align-items: center !important;
}
.trial-collection-cell a.late {
  color: #ff9800;
}
.trial-collection-cell a.in_progress {
  color: #2196f3;
}
.checkbox-hide-completed {
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 5px;
}
.trial-collection-due-today {
  font-size: 0.8rem;
  color: #9e9e9e;
}
.trial-collection-optional {
  font-size: 0.8rem;
  color: #8ba08e;
  padding-right: 0.5rem;
}
.clickable {
  cursor: pointer;
}
.trial-list {
  overflow: auto;
}
.trial-list thead th {
  position: sticky;
  top: -1px;
  z-index: 1;
  background-color: white;
  padding: 8px 5px !important;
  vertical-align: top !important;
}
.trial-list th:after,
.trial-list th:before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
}
.trial-list th:after {
  bottom: 0;
  border-bottom: 1px solid #d0d0d0;
}
.trial-list thead > tr > th {
  text-align: center;
}
.trial-list td:first-child {
  padding-left: 5px !important;
}
.trial-list td {
  text-align: center;
  padding: 8px 5px;
  vertical-align: top;
}
.trial-list td a > i {
  margin-right: 4px;
}
.trial-list td a.measurement {
  white-space: nowrap;
  padding-right: 0.5rem;
}
.search-container {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.search-container .show-archived {
  margin-left: 1rem;
}
.search-container .show-archived label input[type='checkbox']:checked + .lever {
  background-color: rgba(104, 159, 56, 0.38);
}
.search-container .show-archived span.lever {
  background-color: rgba(0, 0, 0, 0.12);
}
.search-container .show-archived label {
  font-size: inherit !important;
  color: inherit !important;
}
.input-search-container {
  background-color: #eee;
  border-radius: 20px;
  padding: 0px 16px;
  height: 39px;
  margin-bottom: 8px;
  flex-grow: 1;
  max-width: 600px;
}
.input-search-container .form-edit-help {
  height: fit-content;
}
.input-search-container div {
  height: inherit;
}
.input-search-container input {
  border-bottom: none !important;
  height: inherit !important;
  box-shadow: none !important;
  font-family: 'Roboto', 'Material Icons', sans-serif;
}
.input-search-container input:focus {
  border-bottom: none !important;
  box-shadow: none !important;
}
.input-search-container label {
  top: 0.6rem !important;
  margin-left: 16px;
}
.input-search-container label.active {
  transform: translateY(-150%) translateX(10%) !important;
}
@media (max-width: 800px) {
  table.trial-list td {
    padding: 4px 5px;
    vertical-align: top;
    text-align: left;
  }
  table.trial-list tr {
    background: #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
  .search-container .show-archived {
    margin-left: 0.5rem;
  }
}
@media screen and (max-width: 800px) {
  table.trial-list tbody td br {
    display: none !important;
  }
}
.settings .input-field.input-field-select {
  margin-top: 1rem;
}
.settings .api-key {
  flex: 1;
}
.create-trial-popup .form-input-line > * {
  flex: 1;
}
.create-trial-popup .template-select {
  margin-left: 35px;
  margin-right: 35px;
}
.create-trial-popup-edit-mode-container {
  margin-top: 2em;
}
.trial-export .export-section {
  border: 1px solid #d0d0d0;
  padding: 1em;
  margin: 1em 0 1em 0;
  overflow-x: auto;
}
.trial-export .export-section.overflow-x-visible {
  overflow-x: visible;
}
.trial-export .section-title {
  color: #283593;
  margin-bottom: 0.5em;
  font-size: 1.2em;
}
.trial-export .section-description {
  margin-bottom: 1em;
}
.trial-export .remember {
  display: block;
  float: right;
}
.trial-export .subsection-title {
  color: #757575;
  font-weight: bold;
  margin-top: 2em;
}
.trial-export .preparing-export {
  line-height: 36px;
  color: #9e9e9e;
}
.trial-export loading-indicator.preparing-export {
  line-height: 36px;
}
.trial-export loading-indicator.preparing-export > span {
  font-size: 1em;
}
.trial-export loading-indicator.preparing-export .preloader-wrapper.small {
  width: 24px;
  height: 24px;
}
.trial-export .form-input-line {
  margin-top: 1.5em;
}
.trial-export .form-input-line .input-field {
  margin-top: 0;
}
.trial-export .calc-agg-method .input-field {
  max-width: 280px;
  margin-top: 1rem;
}
.trial-export .validation-error {
  color: #f44336;
}
.trial-export .obs-line {
  border-bottom: 1px solid #eee;
  padding-bottom: 1em;
  margin-bottom: 2em;
}
.trial-export .calculated-col-title {
  min-width: 280px;
}
.trial-export .observation {
  min-width: 280px;
}
.trial-export .observation-option {
  min-width: 120px;
}
.trial-export .custom-export-button {
  display: inline-block;
  margin-top: 2em;
}
.trial-export .custom-export-validation-error {
  color: #f44336;
}
.fact-edit-popup > .popup-content {
  min-width: 730px;
}
.fact-edit {
  max-width: 1800px;
}
.fact-edit .fact-description {
  margin-bottom: 2em;
}
.fact-edit .fact-plot :first-child,
.fact-edit .fact-description :first-child {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
  color: rgba(0, 0, 0, 0.74);
}
.fact-edit .fact-plot :last-child,
.fact-edit .fact-description :last-child {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(0, 0, 0, 0.74);
}
.fact-edit .edit-extra-file {
  position: relative;
  width: fit-content;
}
.fact-edit .edit-extra-file a {
  padding-right: 32px;
}
.fact-edit .edit-extra-file img {
  aspect-ratio: 1;
  object-fit: cover;
  max-height: 100%;
  max-width: 320px;
  width: 100%;
}
.fact-edit .attribute-row {
  margin-bottom: 1rem;
}
.fact-edit .attribute-row .attribute-value {
  width: -webkit-fill-available;
}
.fact-edit .attribute-row .attribute-value textarea.materialize-textarea {
  margin: 0;
  min-height: 1.5rem;
  max-height: 214px;
  height: 1.5rem;
  padding: 0.8rem 0 0.5rem 0;
}
.fact-edit .attribute-row .attribute-change-reason {
  padding: 0px 5px;
}
.fact-edit .attribute-row .row.row-collection-details {
  margin: 0;
  gap: 0.5rem;
  align-items: center;
}
.fact-edit .attribute-row .row.row-collection-details label,
.fact-edit .attribute-row .row.row-collection-details i {
  color: rgba(0, 0, 0, 0.87);
}
.fact-edit .row-data {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.fact-edit .row-data :first-child {
  flex: 2;
}
.fact-edit .row-data :last-child {
  flex: 1;
}
.fact-edit .row-extra {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 30px;
}
.fact-edit .extra-item {
  display: flex;
  align-items: flex-end;
  gap: 25px;
}
.fact-edit .extra-item textarea.materialize-textarea {
  margin: 0;
  min-height: 1.5rem;
  max-height: 214px;
  height: 1.5rem;
  padding: 0.8rem 0 0.5rem 0;
}
.fact-edit .extra-item a {
  color: #283593;
  display: inline-flex;
  align-items: center;
}
.fact-edit .extra-item > * {
  flex: 1;
  gap: 1px;
}
.fact-edit .extra-item :first-child {
  flex: 1;
}
.fact-edit .extra-item :last-child {
  flex: 2;
}
.fact-edit .extra-edit-pictures-menu-btn {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
}
.fact-edit .extra-edit-pictures-menu-btn.extra-edit-pictures-menu-btn-img {
  top: 8px;
  right: 8px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
}
.fact-edit .edit-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 72px;
}
.fact-edit .edit-container .edit-attributes-container {
  flex: 1;
}
.fact-edit .edit-container .edit-dialog-helper-container {
  flex: 1;
  border-radius: 10px;
  background-color: #F9F9F9;
  padding: 0 30px;
  height: fit-content;
}
@media (max-width: 1280px) {
  .fact-edit .edit-container .edit-dialog-helper-container {
    display: none;
  }
}
.fact-edit .edit-container .edit-dialog-helper-container > h2 {
  color: #283593;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.18px;
}
.fact-edit .edit-container .edit-dialog-helper-container > p {
  color: rgba(0, 0, 0, 0.74);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.fact-edit .edit-container .edit-dialog-helper-container .edit-dialog-control-explanation-row {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 46px;
  align-items: flex-start;
}
.fact-edit .edit-container .edit-dialog-helper-container .edit-dialog-control-explanation-row > div {
  flex: 0 0 248px;
}
.fact-edit .edit-container .edit-dialog-helper-container .edit-dialog-control-explanation-row form-select input,
.fact-edit .edit-container .edit-dialog-helper-container .edit-dialog-control-explanation-row form-select .caret {
  color: rgba(0, 0, 0, 0.74);
}
.fact-edit .edit-container .edit-dialog-helper-container .edit-dialog-control-explanation-row .edit-history-switch {
  cursor: default;
  margin: 0;
}
.fact-edit .edit-container .edit-dialog-helper-container .edit-dialog-control-explanation-row .edit-history-switch > div {
  padding: 10px 8px;
  width: 120px;
}
.fact-edit .edit-container .edit-dialog-helper-container .edit-dialog-control-explanation-row .edit-history-switch > div > i {
  margin-right: 4px;
}
.fact-edit .edit-container .edit-dialog-helper-container .edit-dialog-control-explanation-row .edit-fact-action-bar-navigate-button {
  background-color: white;
  cursor: default;
}
.fact-edit .edit-container .edit-dialog-helper-container .edit-dialog-control-explanation-row .edit-fact-action-bar-navigate-button:first-child {
  margin-left: 0;
}
.fact-edit .edit-container .edit-dialog-helper-container .edit-dialog-control-explanation-row .fake-filter {
  background: #689F3833;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 8px;
  width: 150px;
}
.fact-edit .edit-container .edit-dialog-helper-container .edit-dialog-control-explanation-row .fake-data-cell {
  background: #689F3833;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 42px;
  min-width: 150px;
}
.fact-edit .edit-container .edit-dialog-helper-container .edit-dialog-control-explanation-row .fake-data-cell .cell-icons {
  position: absolute;
  top: 0;
  right: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 42px;
}
.fact-edit .edit-container .edit-dialog-helper-container .edit-dialog-control-explanation-row .fake-data-cell .cell-icons > i {
  color: #283593;
  font-size: 0.7em;
}
.fact-edit .edit-container .edit-dialog-helper-container .edit-dialog-control-explanation-row ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
}
.fact-edit .edit-container .edit-dialog-helper-container .edit-dialog-control-explanation-row ul li > i {
  color: #283593;
}
.compact-form .form-autocomplete-select {
  margin-top: 0;
}
.compact-form .form-autocomplete-select .input-field,
.compact-form form-text-input .input-field,
.compact-form form-date-input .input-field {
  display: inline-flex;
  white-space: nowrap;
}
.compact-form .form-autocomplete-select .input-field label,
.compact-form form-text-input .input-field label,
.compact-form form-date-input .input-field label {
  top: 0;
}
.compact-form .form-autocomplete-select .input-field label.active,
.compact-form form-text-input .input-field label.active,
.compact-form form-date-input .input-field label.active {
  top: 0.8rem;
}
.compact-form .form-autocomplete-select .input-field i.clear,
.compact-form form-text-input .input-field i.clear,
.compact-form form-date-input .input-field i.clear {
  line-height: 2rem;
}
.compact-form .form-autocomplete-select .autocomplete-content {
  margin-top: 2.5rem;
}
.compact-form input,
.compact-form .select-wrapper input.select-dropdown {
  height: 2rem;
  line-height: 2rem;
  min-width: 15rem;
}
.compact-form label > i {
  margin-right: 5px;
}
.compact-form input:not([type]) + label:after,
.compact-form input[type='text'] + label:after {
  top: 46px;
}
.uncontainer.overview {
  display: flex;
  margin-bottom: 2em;
}
.uncontainer.overview .actions {
  margin-top: 0.5em;
  margin-right: 2em;
  display: flex;
  flex-direction: column;
}
.uncontainer.overview .actions input {
  min-width: 10rem;
}
.uncontainer.overview .actions .form-autocomplete-select {
  margin-top: 0;
}
.uncontainer.overview .actions .form-autocomplete-select .input-field,
.uncontainer.overview .actions form-text-input .input-field,
.uncontainer.overview .actions form-date-input .input-field {
  display: inline-flex;
  white-space: nowrap;
}
.uncontainer.overview .actions .form-autocomplete-select .input-field label,
.uncontainer.overview .actions form-text-input .input-field label,
.uncontainer.overview .actions form-date-input .input-field label {
  top: 0;
}
.uncontainer.overview .actions .form-autocomplete-select .input-field label.active,
.uncontainer.overview .actions form-text-input .input-field label.active,
.uncontainer.overview .actions form-date-input .input-field label.active {
  top: 0.8rem;
}
.uncontainer.overview .actions .form-autocomplete-select .input-field i.clear,
.uncontainer.overview .actions form-text-input .input-field i.clear,
.uncontainer.overview .actions form-date-input .input-field i.clear {
  line-height: 2rem;
}
.uncontainer.overview .actions .form-autocomplete-select .autocomplete-content {
  margin-top: 2.5rem;
}
.uncontainer.overview .actions input,
.uncontainer.overview .actions .select-wrapper input.select-dropdown {
  height: 2rem;
  line-height: 2rem;
  min-width: 15rem;
}
.uncontainer.overview .actions label > i {
  margin-right: 5px;
}
.uncontainer.overview .actions input:not([type]) + label:after,
.uncontainer.overview .actions input[type='text'] + label:after {
  top: 46px;
}
.uncontainer.overview .actions > div {
  color: #283593;
  font-size: 0.8em;
  margin-bottom: 1em;
}
.uncontainer.overview .actions > ul {
  margin-top: 0.5em;
  margin-bottom: 1em;
}
.uncontainer.overview .actions > ul:last-child {
  margin-bottom: 0;
}
.uncontainer.overview .kpi {
  display: flex;
  justify-content: center;
}
.uncontainer.overview .kpi > div {
  text-align: center;
}
.uncontainer.overview .kpi .title {
  margin-bottom: 1em;
  margin-right: 1em;
  margin-left: 1em;
  color: #9e9e9e;
}
.uncontainer.overview .kpi .value {
  color: #283593;
}
.uncontainer.overview .overview-content {
  flex: 1;
}
.uncontainer.overview .overview-content loading-indicator {
  justify-content: center;
}
.uncontainer.overview .overview-content .info-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.uncontainer.overview .overview-content .info.empty {
  text-align: center;
  margin-top: 0.5em;
}
.uncontainer.overview .overview-content .trials-charts-export-container {
  display: flex;
  justify-content: left;
}
.uncontainer.overview .overview-content .charts > div {
  cursor: pointer;
  position: relative;
  max-width: 800px;
  margin: 0 auto 1em;
}
.uncontainer.overview .overview-content .charts > div.has-legends {
  margin-bottom: 0;
}
.uncontainer.overview .overview-content .charts > div.chart-legends {
  margin-bottom: 2em;
}
.uncontainer.overview .overview-content .charts > div:last-child {
  margin-top: 1em;
}
.uncontainer.overview .overview-content.no-actions .charts > div {
  max-width: 800px;
}
.uncontainer.overview table.overview-portfolio-status {
  table-layout: fixed;
}
.uncontainer.overview table.overview-portfolio-status td,
.uncontainer.overview table.overview-portfolio-status th {
  border: 1px solid #d0d0d0;
  border-radius: 0;
  padding: 0.5em;
  font-weight: normal;
  color: inherit;
}
.uncontainer.overview table.overview-portfolio-status .current {
  background-color: #283593;
}
.fullscreen-chart {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.3);
}
.fullscreen-chart > div {
  margin: 2em;
  height: 100%;
}
.fullscreen-chart > div > div {
  background-color: white;
  margin: auto;
  padding: 2em;
  max-height: 80%;
  max-width: 1400px;
  overflow-y: auto;
}
.fullscreen-chart > div > div flat-button {
  display: block;
  float: right;
}
.overview-table-action {
  color: #283593;
}
.overview-table-action:hover {
  cursor: pointer;
}
.overview-container {
  min-height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.overview-container > .overview {
  min-height: 0;
  flex: 1;
}
.overview-container > .overview > .overview-content {
  min-width: 0;
  display: flex;
  flex-direction: column;
}
.overview-container > .overview > .overview-content > * {
  flex-shrink: 0;
}
.overview-container > .overview > .overview-content .all-varieties-table-container {
  min-height: 0;
  flex: 1;
  overflow: auto;
}
.overview-container > .overview > .overview-content .all-varieties-table-container thead > tr {
  height: 100%;
}
.overview-container > .overview > .overview-content .all-varieties-table-container thead > tr > th {
  height: 100%;
}
.overview-container > .overview > .overview-content .all-varieties-table-container th {
  min-width: 100px;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  border-radius: 0;
  box-shadow: 2px 0 4px 0 #d0d0d0;
  clip-path: inset(0 0 -6px 0);
}
.overview-container > .overview > .overview-content .all-varieties-table-container th:first-child {
  left: 0;
  width: 150px;
  min-width: 150px;
}
.overview-container > .overview > .overview-content .all-varieties-table-container th:nth-child(2) {
  left: 150px;
  clip-path: inset(0 -6px -6px 0);
}
.overview-container > .overview > .overview-content .all-varieties-table-container th:first-child,
.overview-container > .overview > .overview-content .all-varieties-table-container th:nth-child(2) {
  z-index: 2;
}
.overview-container > .overview > .overview-content .all-varieties-table-container td:first-child {
  left: 0;
  width: 150px;
  min-width: 150px;
  position: sticky;
  background-color: white;
}
.overview-container > .overview > .overview-content .all-varieties-table-container td:nth-child(2) {
  left: 150px;
  position: sticky;
  background-color: white;
}
.overview-container > .overview > .overview-content .all-varieties-table-container td:nth-child(2) {
  box-shadow: 2px 0 4px 0 #d0d0d0;
  clip-path: inset(0 -6px 0 0);
}
.overview-container > .overview > .overview-content .all-varieties-table-container tr:nth-child(odd) > td {
  background-color: #f2f2f2;
}
.uncontainer.overview .overview-content loading-indicator.loading-indicator-small-inline {
  justify-content: flex-start;
}
.chart-legends {
  padding-left: 50px;
}
.chart-legends > div {
  display: flex;
}
.chart-legend-item {
  display: flex;
  align-items: center;
  margin-right: 1em;
}
.chart-legend-item canvas {
  height: 12px;
  width: 24px;
  margin-right: 5px;
}
.header-tabs {
  display: flex;
  justify-content: center;
  margin-top: 0;
}
.header-tabs li {
  display: inline-block;
  cursor: pointer;
  color: #283593;
  padding: 0.5em 1em;
}
.header-tabs li.active,
.header-tabs li.active.error {
  color: #283593;
  border-bottom: 2px solid #283593;
}
.header-tabs li.error {
  color: #f44336;
}
.mm-link-info {
  margin-bottom: 2em;
  color: #9e9e9e;
  display: flex;
  align-items: center;
}
.mm-link-info > i {
  margin-right: 0.5em;
}
.mm-edit-formula-suggestions {
  height: 250px;
  overflow-y: auto;
  border: 1px solid #d0d0d0;
  margin-bottom: 1em;
}
.mm-edit-formula-suggestions table {
  table-layout: fixed;
}
.mm-edit-formula-suggestions th {
  position: sticky;
  top: 0;
  background-color: #eee;
}
.mm-edit-formula-suggestions table th,
.mm-edit-formula-suggestions table td {
  padding: 5px;
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mm-edit-formula-suggestions tr:not(:last-child) {
  border-bottom: 1px solid #d0d0d0;
}
.mm-edit-formula-suggestions tbody tr:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}
.mm-edit-formula-validate {
  margin-bottom: 16px;
}
.mm-edit-formula-valid-text {
  color: #4caf50;
}
.mm-edit-formula-warning-text {
  color: darkorange;
}
.trial-activity-status .status-chart {
  max-width: 800px;
  margin: 0 auto;
}
.trial-activity-status .trial-row {
  cursor: pointer;
}
.trial-activity-status .inactive {
  color: #ff8a80;
  display: flex;
  align-items: center;
}
.trial-activity-status .inactive i {
  margin-right: 10px;
}
.trial-activity-status .expanded {
  border-bottom: none;
}
.trial-activity-status .toggle-row {
  display: flex;
  align-items: center;
  justify-content: end;
}
.trial-activity-status .toggle-row .material-icons {
  line-height: 3rem;
}
.trial-activity-status .expansion {
  border-top: none;
  padding: 0 0 2em 0;
}
.trial-activity-status .expansion .no-results {
  font-size: 1em;
  margin: 0;
}
.trial-activity-status .expansion thead {
  border-top: none;
}
.trial-activity-status .expansion tr:last-child {
  border-bottom: none;
}
.trial-activity-status .actions-cell {
  display: flex;
  justify-content: space-between;
}
.trial-field-book h2 {
  font-size: 1em;
  color: #9e9e9e;
}
.trial-field-book > form-select {
  margin-top: 2em;
  display: block;
}
.trial-field-book .trial-title {
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 1em;
}
.trial-field-book .site-name {
  text-align: center;
  margin-bottom: 3em;
}
.trial-field-book .dataset-title {
  text-align: center;
  margin-bottom: 1em;
  break-before: page;
  page-break-before: always;
}
.trial-field-book .observation-title {
  margin-top: 1em;
  font-weight: bold;
}
.trial-field-book .choice {
  margin-left: 1em;
}
.trial-field-book .help-picture {
  border: 1px solid #d0d0d0;
  padding: 1em;
  margin-top: 1em;
}
.trial-field-book .observations {
  table-layout: fixed;
  break-before: page;
  page-break-before: always;
}
.trial-field-book .observations th {
  color: inherit;
}
.trial-field-book .observations th,
.trial-field-book .observations td {
  border: 1px solid black;
}
.trial-field-book .print-result {
  display: none;
}
@media print {
  .trial-field-book > h1,
  .trial-field-book > h2,
  .trial-field-book > flat-button,
  .trial-field-book > primary-button,
  .trial-field-book > form-select,
  .trial-field-book > a {
    display: none;
  }
  .trial-field-book .print-result {
    display: block;
  }
}
.list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 0.8rem;
}
.list-header h1 {
  margin: 0 !important;
}
.list-header loading-indicator .preloader-wrapper.small {
  width: 24px;
  height: 24px;
}
.list-header loading-indicator > span {
  display: none;
}
.list-header .list-header-actions {
  display: flex;
  align-items: flex-end;
}
.list-header .list-header-actions div.actions-chip-text {
  background-color: #ff8a25;
  height: 16px;
  padding: 0px 5px;
  border-radius: 10px;
  font-size: x-small;
  text-align: center;
  position: absolute;
  margin-left: 25px;
  color: white;
}
.list-header .list-header-actions > *:hover {
  background-color: #eeeeee;
}
.list-header .list-header-actions > * {
  cursor: pointer;
  color: #283593;
  width: fit-content;
  min-width: 4rem;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.list-header .list-header-actions > *:last-child {
  margin-right: 0;
}
.list-header .list-header-actions > * i.map-icon {
  display: block;
  height: 24px;
  min-width: 19px;
  background: url(/static/6032956d3ef81e57be2f1eca5eedf37e.png) no-repeat 50%;
}
.list-header .list-header-actions > * i.icon-warning {
  color: #ffee94;
}
.list-header .list-header-actions > * i.icon-danger {
  color: #b71c1c;
}
list-filters {
  display: inline-block;
}
list-filters .tooltip {
  display: none;
}
list-filters span.with-tooltip {
  cursor: pointer;
}
list-filters span.with-tooltip:hover .tooltip {
  margin-top: 10px;
  display: block;
  position: absolute;
  background-color: white;
  z-index: 2;
  border: 1px solid #d0d0d0;
  max-width: 400px;
}
list-filters span.with-tooltip:hover .tooltip .info {
  margin: 0.5em;
}
list-filters .filters {
  border-radius: 4px;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  align-items: stretch;
  margin-bottom: 4px;
}
list-filters .filters .picker__input.picker__input--active {
  border-color: #9e9e9e;
}
list-filters .filters form-date-input .input-field {
  display: block;
}
list-filters .filters > div {
  position: relative;
  background-color: rgba(40, 53, 147, 0.1);
}
list-filters .filters > div .input-field {
  height: 100%;
  margin: 0;
}
list-filters .filters > div .input-field > input {
  cursor: pointer;
  border: none;
}
list-filters .filters > div .input-field label {
  padding: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
list-filters .filters > div .input-field label.active {
  top: 100%;
}
list-filters .filters > div .input-field input[type='text'] {
  height: 100%;
  min-height: 40px;
  margin: 0;
  padding-left: 8px;
  padding-top: 12px;
  box-sizing: border-box;
  /* Ensure border doesn't move with padding */
}
list-filters .filters > div > div {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  min-width: 80px;
  padding: 8px;
}
list-filters .filters > div > div:has(form-date-input) {
  padding: 0;
}
list-filters .filters > div > div > i {
  margin-left: 8px;
}
list-filters .filters > div > div > i[data-icon='check'] {
  color: #283593;
}
list-filters .filters .selected-count {
  background: #283593;
  color: #FFF;
  border-radius: 50%;
  height: 24px;
  min-width: 24px;
  text-align: center;
  margin-left: 8px;
}
list-filters .filters .results-popup {
  min-width: 15rem;
  position: absolute;
  background-color: white;
  z-index: 20;
  padding: 0;
  padding-top: 0.5em;
  border: 1px solid #d0d0d0;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}
list-filters .filters .results-popup loading-indicator {
  margin: 0 0.5em 0.5em;
}
list-filters .filters .results-popup loading-indicator > span {
  font-size: 1em;
}
list-filters .filters .results-popup .preloader-wrapper.small {
  height: 24px;
  width: 24px;
}
list-filters .filters .results-popup .search-input {
  display: flex;
  align-items: center;
  padding-right: 24px;
  margin: 0 0.5em;
}
list-filters .filters .results-popup .search-input > input {
  width: 100%;
  padding-right: 24px;
  margin-bottom: 1em;
  height: 2.5rem;
  border-bottom: 1px solid #757575;
  box-shadow: none;
}
list-filters .filters .results-popup .search-input > i {
  height: 2.9rem;
  margin-left: -24px;
}
list-filters .filters .results-popup .search-results {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  padding: 0 0.5em;
}
list-filters .filters .results-popup .search-results > div {
  display: flex;
  margin-bottom: 0.5em;
  align-items: center;
}
list-filters .filters .results-popup .search-results > div > i {
  margin-right: 5px;
}
list-filters .filters .results-popup .search-results > div > i[data-icon='check_box'],
list-filters .filters .results-popup .search-results > div > i[data-icon='radio_button_checked'] {
  color: #283593;
}
list-filters .filters .results-popup .search-results .more-results {
  color: #9e9e9e;
  font-style: italic;
}
list-filters .filters .results-popup .buttons-container {
  display: flex;
  width: 100%;
  padding: 1px;
}
list-filters .filters .results-popup .buttons-container > div {
  cursor: pointer;
  display: block;
  text-align: center;
  text-transform: uppercase;
  padding: 0.5em;
  margin-top: 0.5em 0;
  font-weight: 700;
  font-family: Roboto;
  font-size: 16px;
}
list-filters .filters .results-popup .reset {
  background: #D8D8D8;
  color: #404040;
  flex: 1;
}
list-filters .filters .results-popup .save {
  background-color: #283593;
  color: #FFF;
  flex: 2;
}
list-filters .filters .range-filter {
  padding-bottom: 0.5em;
}
list-filters .filters .range-filter input {
  width: 100%;
}
list-filters .filters .range-filter form-text-input input[type="text"].invalid + label::after {
  display: none;
}
list-filters .filters .range-filter .validation-error {
  color: #f44336;
  font-size: 1rem;
  margin-top: 0.5rem;
  padding: 0 0.5rem;
}
list-filters .reset-all-filters {
  background-color: #FFF !important;
  color: rgba(0, 0, 0, 0.74);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}
list-filters .reset-all-filters > div {
  justify-content: center;
}
list-filters .reset-all-filters:hover {
  background-color: #EEEEEE !important;
}
list-filters #edit-filters-button {
  border: 1px solid #e8e8e8;
  border-radius: 5%;
  display: inline-flex;
}
list-filters #edit-filters-button span.action {
  line-height: inherit;
  width: 3.5rem;
  max-width: 40px;
  margin-right: 0;
  text-align: center;
}
list-filters #edit-filters-button i {
  width: 100%;
}
list-filters #edit-filters-button:hover {
  background-color: #eeeeee;
}
.tpp-name {
  color: #9e9e9e;
  text-align: center;
}
form.tpp-edit {
  max-width: initial;
}
form.tpp-edit p.info {
  color: #9e9e9e;
  margin-bottom: 1em;
}
form.tpp-edit .driver-analysis-chart {
  max-width: 800px;
  margin: 0 auto;
}
form.tpp-edit .form-buttons .save-error {
  color: #f44336;
  margin-bottom: 1em;
  letter-spacing: initial;
}
form.tpp-edit .form-buttons .save-success {
  color: #4caf50;
  margin-bottom: 1em;
  letter-spacing: initial;
}
form.tpp-edit .tpp-stage {
  align-self: baseline;
}
form.tpp-edit .tpp-stage :first-child {
  font-weight: bold;
}
form.tpp-edit .tpp-stage :last-child {
  line-height: 2rem;
}
.tpp-profile-input-table {
  background-color: white;
}
.tpp-profile-input-table td,
.tpp-profile-input-table th {
  padding: 10px 5px;
  border: 1px solid #d0d0d0;
  min-width: 100px;
}
.tpp-profile-input-table td {
  border-bottom: none;
}
.tpp-profile-input-table .validation-errors {
  border-bottom: 1px solid #d0d0d0;
}
.tpp-profile-input-table .validation-errors td {
  padding: 0;
  border-top: none;
}
.tpp-profile-input-table .validation-errors td > div {
  padding: 0px 5px 10px 5px;
  color: #f44336;
}
.tpp-profile-input-table .input-field {
  margin-top: 0;
}
.tpp-profile-input-table .input-field label {
  top: 0;
}
.tpp-profile-input-table .form-autocomplete-select {
  margin: 0;
}
.tpp-profile-input-table .select-wrapper input.select-dropdown {
  height: 1.5em;
  margin: 0;
}
.tpp-profile-input-table .input-field input[type='text']:focus:not([readonly]),
.tpp-profile-input-table .input-field input[type='text'].valid,
.tpp-profile-input-table input[type='text'].invalid {
  border-bottom: none;
  box-shadow: none;
}
.tpp-profile-input-table .input-field input[type='text']:focus:not([readonly]) + label {
  display: none;
}
.tpp-profile-input-table input[type='text'].invalid + label::after {
  display: none;
}
.tpp-profile-input-table input[type='text'] {
  height: 1.5em;
  margin: 0;
  border: none;
}
.tpp-profile-input-table .select-wrapper + label::after {
  display: none;
}
.tpp-profile-input-table form-select-search label.active,
.tpp-profile-input-table form-text-input label.active {
  display: none;
}
.tpp-profile-input-table .form-autocomplete-select .autocomplete-content {
  margin-top: 2rem;
}
.tpp-profile-input-table .form-autocomplete-select i.clear {
  line-height: 1.5rem;
  margin-left: -24px;
}
.tpp-profile-input-table .form-autocomplete-select i.left {
  margin-top: 0;
}
.tpp-profile-input-table .required-select {
  display: block;
  width: 50px;
}
.tpp-profile-input-table .centered-column {
  text-align: center;
  vertical-align: middle;
}
.tpp-profile-input-table th .info {
  font-weight: normal;
}
.tpp-profile-input-table .checkbox-container {
  padding: 0;
}
.tpp-profile-input-table [type='checkbox'] + label {
  padding-left: 25px;
  line-height: inherit;
}
.tpp-profile-input-table .indicative-target-value {
  display: flex;
  align-items: baseline;
}
.tpp-profile-input-table .indicative-target-value > span {
  margin-left: 0.5em;
  color: #9e9e9e;
}
.tpp-profile-input-table-with-actions th:last-child {
  border: none;
  min-width: initial;
}
.tpp-profile-input-table-with-actions td:last-child {
  min-width: initial;
}
.tpp-profile-input-table th:hover .tooltip {
  width: 400px;
}
.tpp-profile-input-table .tooltip,
.cv-table .tooltip,
.tpp-profile-input-table .arrow-top,
.cv-table .arrow-top {
  display: none;
}
.tpp-profile-input-table td,
.cv-table td,
.tpp-profile-input-table th,
.cv-table th {
  position: relative;
}
.tpp-profile-input-table td:hover .tooltip,
.cv-table td:hover .tooltip,
.tpp-profile-input-table th:hover .tooltip,
.cv-table th:hover .tooltip {
  margin-top: 10px;
  display: block;
  position: absolute;
  background-color: white;
  z-index: 2;
  border: 1px solid #d0d0d0;
  max-width: 400px;
}
.tpp-profile-input-table td:hover .tooltip .info,
.cv-table td:hover .tooltip .info,
.tpp-profile-input-table th:hover .tooltip .info,
.cv-table th:hover .tooltip .info {
  margin: 0.5em;
}
.tpp-profile-input-table td:hover .arrow-top,
.cv-table td:hover .arrow-top,
.tpp-profile-input-table th:hover .arrow-top,
.cv-table th:hover .arrow-top {
  margin-top: -2px;
  margin-left: 0.5em;
  position: absolute;
  display: block;
  content: ' ';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 12px 12px;
  border-color: transparent transparent #d0d0d0 transparent;
}
.tpp-profile-input-table td:hover .arrow-top::after,
.cv-table td:hover .arrow-top::after,
.tpp-profile-input-table th:hover .arrow-top::after,
.cv-table th:hover .arrow-top::after {
  display: block;
  content: ' ';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 12px 12px;
  border-color: transparent transparent white transparent;
  position: absolute;
  left: -12px;
  top: 1px;
  z-index: 3;
}
.tpp-profile-input-table th.tooltip-leftwards .tooltip,
.cv-table th.tooltip-leftwards .tooltip {
  right: 0;
}
.tpp-profile-input-table .multi-select,
.cv-table .multi-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.tpp-profile-input-table .multi-select i,
.cv-table .multi-select i,
.tpp-profile-input-table .multi-select .empty,
.cv-table .multi-select .empty {
  color: #9e9e9e;
}
.tpp-edit .cover-input-step-list {
  display: flex;
  overflow-x: auto;
  margin-top: 2em;
}
.tpp-edit .cover-input-step-list .cover-input-step {
  flex: 1;
  min-width: 300px;
}
.tpp-edit .cover-input-step-list .cover-input-step.narrow {
  min-width: 260px;
}
.tpp-edit .cover-input-step-list .cover-input-step:nth-child(odd) .cover-input-step-title {
  background-color: #5b9bd5;
}
.tpp-edit .cover-input-step-list .cover-input-step:nth-child(even) .cover-input-step-title {
  background-color: #ffc000;
}
.tpp-edit .cover-input-step-list .cover-input-step-header {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}
.tpp-edit .cover-input-step-list .cover-input-step-header .arrow-line {
  flex: 1;
  height: 0px;
  border-top: 1px solid #283593;
}
.tpp-edit .cover-input-step-list .cover-input-step-header .spacer {
  flex: 1;
}
.tpp-edit .cover-input-step-list .cover-input-step-header .cover-input-step-title {
  border: 1px solid #d0d0d0;
  padding: 1em;
  text-align: center;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
}
.tpp-edit .cover-input-step-list .cover-input-step-header > i {
  margin-left: -10px;
  margin-right: -7px;
  color: #283593;
}
.tpp-edit .cover-input-step-list .cover-input-step-body {
  padding: 0.05px;
  margin-right: 1em;
  margin-left: 1em;
}
.tpp-edit .cover-input-step-list .cover-input-step-body .input-field label {
  font-size: 0.8rem;
}
.tpp-edit .cover-input-step-list .cover-input-step-section-title {
  font-weight: bold;
  margin: 0.5em 0 1.5em 0;
  min-height: 1.5em;
}
.tooltip-multiline {
  white-space: pre-line;
}
.target-end-users {
  border: 1px solid #d0d0d0;
  padding: 0 1em 1em;
  margin-bottom: 2em;
}
.tpp-step-list-container {
  margin-top: 2em;
  overflow-x: auto;
}
.tpp-card {
  background: #eeeeee;
  border-radius: 19px;
  padding: 40px;
  margin-top: 40px;
}
.tpp-step-list > tbody > tr > td {
  padding: 0 0.5em;
  vertical-align: top;
  min-width: 240px;
}
.tpp-step-list .input-field label {
  font-size: 0.8rem;
}
.tpp-step-list > tbody > tr.tpp-step-headers > td {
  padding: 0 0 0 0.5em;
}
.tpp-step-title-container {
  display: flex;
  align-items: center;
}
.tpp-step-title {
  flex: 1;
  height: 100px;
  padding: 1em;
  margin-right: 0.5em;
  color: white;
  font-weight: bold;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tpp-step-odd {
  background-color: #5b9bd5;
}
.tpp-step-even {
  background-color: #ffc000;
}
.tpp-step-section-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  padding-bottom: 60px;
  color: #283593;
}
.cover-input-calc {
  height: calc(4rem + 21px);
}
.cover-input-calc-label {
  font-size: 0.8em;
  color: #9e9e9e;
  padding-bottom: 0.5rem;
  white-space: nowrap;
}
.form-edit-help-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.form-edit-help-container.textarea-help {
  align-items: center;
}
.form-edit-help-container > :first-child {
  flex: 1;
  margin-right: 1em;
  margin-bottom: 0;
}
.form-edit-help-container > .material-icons {
  cursor: pointer;
  top: 7px;
  position: relative;
}
.form-edit-help-container > .material-icons.align-center {
  position: static;
}
.form-edit-help-container .form-edit-help {
  display: none;
  position: absolute;
  background-color: white;
  right: 24px;
  border: 1px solid #d0d0d0;
  max-width: 400px;
  padding: 0.5em;
  z-index: 2;
  top: 24px;
}
.form-edit-help-container .form-edit-help > p {
  margin: 1em 0;
}
.form-edit-help-container .form-edit-help-upward {
  top: auto;
  bottom: 24px;
}
.form-edit-help-container > .material-icons:hover + .form-edit-help,
.form-edit-help-container > .material-icons-outlined:hover + .form-edit-help,
.form-edit-help-container .form-edit-help:hover {
  display: block;
}
.card .card-content .form-edit-help > p {
  margin: 1em 0;
}
.tpp-edit-team {
  margin-bottom: 1em;
}
.tpp-edit-team > :first-child {
  color: #283593;
  margin-bottom: 0.5em;
}
.tpp-edit-flex-container {
  display: flex;
}
.tpp-edit-flex-container > .flex-item:not(:last-child) {
  padding-right: 3rem;
}
.tpp-edit-flex-1-item {
  flex: 0 0 100%;
}
.tpp-edit-flex-2-item {
  flex: 0 0 50%;
}
.tpp-edit-flex-3-item {
  flex: 0 0 33.33%;
}
.tpp-edit-flex-4-item {
  flex: 0 0 25%;
}
.tpp-edit-flex-seed-producers {
  flex: 0 0 67%;
}
.flex-item {
  color: inherit;
}
.flex-auto {
  flex: auto;
}
.calculated-field {
  padding-bottom: 0.75rem;
}
.help-icon {
  color: rgba(0, 0, 0, 0.26);
}
.help-icon:hover {
  color: #9e9e9e;
}
.label-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 1366px) {
  .tpp-edit-flex-seed-producers {
    flex: 0 2 50%;
  }
  .tpp-edit-flex-seed-producers .tpp-edit-flex-container {
    flex-direction: column;
  }
  .tpp-edit-flex-seed-producers .tpp-edit-flex-container > .flex-item:not(:last-child) {
    padding-right: 1rem;
  }
  .tpp-edit-flex-seed-producers + .tpp-edit-flex-3-item {
    flex: 0 2 50%;
  }
}
#tenants-subscription {
  width: 80vw;
  padding: 0px 10px 0px 10px;
  margin-left: calc(66% - 50vw);
}
#tenants-subscription .gridjs-thead .gridjs-tr th {
  padding: 6px 12px;
}
#tenants-subscription .gridjs-tbody .gridjs-tr td {
  padding: 6px 12px;
}
#tenants-subscription .right-align-column {
  text-align: right !important;
}
#tenants-subscription .left-align-column {
  text-align: left !important;
}
#display-options-form {
  width: 100px !important;
  margin-left: 20px;
  margin-right: 40px;
}
@media (max-width: 1700px) {
  .tenants-usage-container,
  #display-options-form {
    margin-left: 0 !important;
    padding-left: 5px;
  }
  #tenants-subscription {
    width: 100vw;
    padding-left: 0px;
    padding-right: 5px;
    margin: 0 auto;
  }
}
.visibility-wrapper {
  display: flex;
  align-items: center;
}
.display-chart-button,
.display-chart-button:focus {
  background: none;
  color: #283593;
  border: none;
  outline: none;
  box-shadow: none;
}
.toggle-charts-view {
  margin-left: -50px;
  width: 100px;
  white-space: nowrap;
}
.loading-holder {
  opacity: 80%;
  cursor: wait;
}
qt-popup.select-from-library-popup > .popup-content {
  display: flex;
}
qt-popup.select-from-library-popup > .popup-content > .popup-content-inner {
  flex: 1;
  display: flex;
  flex-direction: column;
}
qt-popup.select-from-library-popup > .popup-content > .popup-content-inner > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.select-from-library-popup {
  display: flex;
  flex-direction: column;
}
.select-from-library-popup.big > .popup-content {
  max-width: 1024px;
}
.select-from-library-scroll {
  overflow-y: auto;
}
.select-from-library {
  display: flex;
  flex: 1;
  min-height: 0;
  max-width: initial;
}
.select-from-library .dialog-subtitle {
  margin-top: 1em;
  margin-bottom: 0.5em;
}
.select-from-library > form {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 0;
  max-width: 100%;
}
.select-from-library > form .checkbox-container {
  padding: 0;
}
.select-from-library > form > :first-child {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.select-from-library .separator {
  border-left: 1px solid #d0d0d0;
  margin: 0 2em;
}
.select-from-library hr {
  border-width: 1px 0 0 0;
  margin: 2em 0;
}
.select-from-library-filters {
  display: flex;
  align-items: baseline;
}
.select-from-library-filters > :not(:last-child) {
  margin-right: 2em;
}
@media (max-width: 1023px) {
  .select-from-library {
    flex-direction: column;
  }
  .select-from-library .separator {
    border-bottom: 1px solid #d0d0d0;
    margin: 2em 0;
  }
}
.tpp-evaluation {
  margin-bottom: 100px;
}
.tpp-evaluation .tpp-evaluation-overview {
  margin-top: 1em;
}
.tpp-evaluation .tpp-evaluation-overview.with-chart {
  display: flex;
  position: relative;
}
.tpp-evaluation .tpp-evaluation-overview.with-chart .tpp-traits-chart {
  cursor: pointer;
}
.tpp-evaluation .tpp-evaluation-overview.with-chart .tpp-traits-chart > canvas {
  width: 500px;
  height: 250px;
}
.tpp-evaluation .tpp-evaluation-overview > div:first-child > div > span:first-child {
  display: inline-block;
  width: 180px;
  color: #757575;
  font-weight: bold;
}
.tpp-evaluation .tpp-evaluation-overview > div:first-child .cv-list {
  display: inline-flex;
  flex-direction: column;
}
.tpp-evaluation h2 {
  font-size: 1em;
  font-weight: normal;
  color: #9e9e9e;
  margin-bottom: 1em;
}
.tpp-evaluation .pipeline td > div {
  color: #283593;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.tpp-evaluation .cv-results-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tpp-evaluation .cv-results-actions .select-wrapper input.select-dropdown {
  margin: 0;
}
.tpp-evaluation .cv-table th,
.tpp-evaluation .cv-table td {
  border: 1px solid #d0d0d0;
  padding: 5px;
}
.tpp-evaluation .cv-table td.thick-line {
  border-bottom: 4px solid #d0d0d0;
}
.tpp-evaluation .cv-table td.thick-line-above {
  border-top: 4px solid #d0d0d0;
}
.tpp-evaluation .cv-table .trial-title-row-cursor {
  border-top: 2px solid #000;
  cursor: pointer;
}
.tpp-evaluation .cv-table .trial-title-row {
  border-top: 2px solid #000;
}
.tpp-evaluation .cv-table .trial-title-row .trial-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tpp-evaluation .cv-table .trial-title-row .trial-title-container .trial-title-text {
  cursor: pointer;
}
.tpp-evaluation .cv-table .trait-name {
  cursor: pointer;
}
.tpp-evaluation .cv-table .trait-name.collapsed {
  width: 50px;
  overflow: hidden;
  white-space: nowrap;
  max-width: 50px;
  text-overflow: ellipsis;
}
.tpp-evaluation .cv-table .variety-name {
  cursor: pointer;
  text-decoration: underline;
}
.tpp-evaluation .cv-table td.row-title {
  font-weight: bold;
  z-index: 10;
  width: 10%;
}
.tpp-evaluation .evaluation-section {
  color: #283593;
  margin-top: 1em;
  margin-bottom: 1em;
}
.tpp-evaluation .all-avg {
  font-weight: bold;
}
.tpp-evaluation .promote {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  margin-bottom: 2em;
  margin-top: 3em;
}
.tpp-evaluation .sub-header th {
  font-weight: normal;
}
.tpp-evaluation .cv-table span.action {
  line-height: 24px;
}
.arrow-right,
.arrow-right {
  position: absolute;
  right: -12px;
  display: block;
  content: ' ';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0 12px 12px;
  border-color: transparent transparent transparent #d0d0d0;
}
.arrow-right::after,
.arrow-right::after {
  display: block;
  content: ' ';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0 12px 12px;
  border-color: transparent transparent transparent white;
  position: absolute;
  left: -13px;
  top: -12px;
  z-index: 2;
}
.tpp-stage-change-chart {
  margin-top: 1em;
  display: block;
}
.tpp-traits-chart-large-site {
  display: inline-block;
  position: fixed;
}
.tpp-traits-chart-large-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
}
.tpp-traits-chart-large-title {
  text-align: center;
  margin-bottom: 1em;
  color: #283593;
  font-size: 1.2em;
}
.tpp-traits-chart-large {
  width: 840px;
  height: 420px;
}
.tpp-traits-chart-large-narrow {
  width: 420px;
  height: 420px;
}
.tpp-traits-chart-large-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #9e9e9e;
}
.tpp-evaluation-th-action {
  cursor: pointer;
  color: #283593;
  font-weight: normal;
  text-decoration: underline;
}
.multi-stage-item-actions {
  display: flex;
  align-items: center;
}
.multi-stage-item-actions > :not(:first-child) {
  margin-left: 1em;
}
.evaluation-stage-details-multi-columns {
  display: flex;
}
.evaluation-stage-details-multi-columns > * {
  flex: 1;
}
.evaluation-stage-details-multi-columns > :not(:last-child) {
  margin-right: 1em;
}
.evaluation-stage-details-actions {
  margin-left: auto;
}
.dialog-remove .dialog-title {
  color: #b71c1c;
}
.dialog-remove dt {
  font-weight: bold;
}
.dialog-remove dd {
  margin-bottom: 0.5em;
  margin-left: 1em;
}
.dialog-remove button.primary,
.dialog-remove button.primary.btn:hover,
.dialog-remove button.primary:focus {
  background-color: #b71c1c;
}
.dialog-remove.bulk-remove .dialog-title {
  color: #283593;
}
.dialog-remove.bulk-remove p span {
  font-weight: bold;
}
.dialog-remove.bulk-remove .primary-color-text {
  color: #283593;
}
.dialog-remove.bulk-remove button.primary {
  background-color: #283593;
}
.dialog-remove.bulk-remove button.primary:hover,
.dialog-remove.bulk-remove button.primary:focus {
  background-color: #1d276b;
}
qt-popup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.3);
}
qt-popup .popup-content {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.25s;
  padding: 20px;
  margin: 0.5rem 0 1rem 0;
  border-radius: 2px;
  background-color: #fff;
  position: fixed;
  top: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0;
  max-height: 80%;
  overflow-y: auto;
  max-width: 600px;
  width: 90%;
}
qt-popup .popup-content > .popup-content-inner {
  padding: 2em;
}
qt-popup .popup-content > .popup-content-inner > h1 {
  font-size: 2rem;
  margin: 0;
}
qt-popup .popup-content .popup-buttons {
  margin-top: 2em;
}
qt-popup .popup-content .popup-buttons secondary-button {
  margin-left: 1em;
}
qt-popup.big .popup-content {
  max-width: initial;
}
@media (max-width: 800px), (max-height: 800px) {
  body qt-popup .popup-content {
    max-height: 90%;
    width: 95%;
    top: 5%;
  }
  body qt-popup .popup-content > .popup-content-inner {
    padding: 1em;
  }
}
.trait-edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.all-or-partial-radio-group {
  margin-left: 32px;
  margin-bottom: 12px;
  display: flex;
  gap: 32px;
}
.column-select-container {
  height: 14px;
  display: inline-block;
  vertical-align: text-top;
}
.column-select-container .column-select {
  position: relative;
  padding: 6px;
  background-color: white;
  border: 1px solid #d0d0d0;
  z-index: 2;
  left: -1em;
}
.column-select-container .column-select > flat-button {
  display: block;
  text-align: center;
  cursor: pointer;
}
.changelog .changes {
  white-space: pre-wrap;
}
.review-screen {
  border: 1px solid #d0d0d0;
  padding: 1em;
  min-width: 800px;
}
.review-nav {
  display: flex;
  justify-content: space-between;
}
.review-nav .review-nav-btn {
  display: flex;
  align-items: center;
  height: 1em;
  cursor: pointer;
  color: #283593;
}
.review-nav .review-nav-position {
  text-align: center;
  color: #9e9e9e;
}
.review-nav .review-nav-go-to {
  display: flex;
}
.review-nav .review-nav-go-to > :not(:last-child) {
  margin-right: 2em;
}
.review-details {
  display: flex;
  justify-content: space-between;
  margin: 1em 0;
  background-color: #f5f5f5;
  padding: 1em 0.5em;
}
.review-detail-title {
  font-weight: bold;
}
.review-merge-table td,
.review-merge-table th {
  padding: 10px 5px;
  border: 1px solid #d0d0d0;
  min-width: 100px;
  vertical-align: top;
}
.review-merge-table img {
  width: 100px;
}
.review-btn-container {
  display: flex;
  justify-content: flex-end;
  margin: 1em 0;
}
.review-open-map {
  cursor: pointer;
  color: #283593;
}
.review-select-action {
  cursor: pointer;
}
.review-select-action div {
  display: flex;
}
.review-select-action .radio-button {
  color: #283593;
  margin-right: 5px;
}
.review-select-action img {
  width: 100px;
}
.driver-analysis-legend {
  display: flex;
  margin-left: 55px;
}
.driver-analysis-legend > div {
  margin-right: 2em;
}
.driver-analysis-legend > div:last-child {
  margin-right: 0;
}
.driver-analysis-legend > div .cat-name {
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
}
.driver-analysis-legend > div .cat-name > canvas {
  width: 12px;
  height: 12px;
  margin-right: 0.5em;
}
.driver-analysis-legend > div .index {
  color: #9e9e9e;
}
.driver-analysis-legend > div .index::after {
  content: '.';
}
.map-activities-filters {
  display: flex;
  align-items: baseline;
}
.map-activities-filters > :not(:last-child) {
  margin-right: 2em;
}
.l-subscription-weather-info-close {
  display: flex;
  justify-content: end;
}
.subscription-weather-info-close {
  cursor: pointer;
}
.subscription-weather-info-close > i {
  color: #333;
}
.subscription-weather-info-screenshot {
  background: url(/static/063a052ba566ec97491a4844be94a364.png) no-repeat 50% 50%;
  width: 800px;
  height: 442px;
  margin: 0 auto;
}
.trial-weather-site-status-status {
  display: flex;
  align-items: center;
}
.trial-weather-site-status-status > .material-icons {
  margin-right: 5px;
}
.trial-weather-site-status-status[data-status='collecting'] {
  color: #4caf50;
}
.trial-weather-site-status-status[data-status='not_active'] {
  color: #9e9e9e;
}
.trial-weather-site-status-status[data-status='quota_exceeded'] {
  color: #f44336;
}
.trial-weather-site-status-status[data-status='no_dm_attr'] {
  color: #f44336;
}
.trial-weather-site-status-status[data-status='no_dim_attr'] {
  color: #f44336;
}
.trial-weather-site-status-status[data-status='weather_disabled'] {
  color: #9e9e9e;
}
.trial-weather-site-status-status[data-status='unknown'] {
  color: #9e9e9e;
}
.project-edit-form {
  max-width: 1200px;
}
.project-edit-item-main {
  flex: 2 1 0;
}
.project-edit-item {
  flex: 1 1 0;
}
.project-edit-item-actions {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.stage-change-legend {
  display: flex;
  align-items: center;
  width: 800px;
  margin: 0.5em auto;
  color: #414141;
}
.stage-change-legend-title {
  text-transform: uppercase;
  margin-left: 90px;
}
.variety-legend-marker {
  width: 25px;
  height: 5px;
  background-color: #ffca00;
  margin-left: 40px;
  margin-right: 10px;
}
.variety-trial-marker {
  border-radius: 50%;
  border: 2px solid #ffca00;
  background-color: white;
  height: 12px;
  width: 12px;
  margin-left: 40px;
  margin-right: 10px;
}
.variety-discarded-marker {
  border: 2px solid #ffca00;
  background-color: #6e6e6e;
  height: 12px;
  width: 12px;
  margin-left: 40px;
  margin-right: 10px;
}
.stage-change-title {
  color: #283593;
  text-align: center;
}
.stage-change-chart {
  margin: 0 auto;
  width: 800px;
  overflow: hidden;
}
.form-location-map-line {
  display: flex;
  align-items: center;
}
.form-location-map-line :first-child {
  flex: 1;
}
.form-location-map-line > :not(:first-child) {
  margin-left: 1em;
}
.form-location-map-line flat-button > span {
  margin: 0;
}
.form-location-map-line span.action > i {
  margin: 0;
}
form form-location-map-point .validation-error {
  margin-top: 0;
}
.plots-preview {
  display: flex;
  overflow-x: auto;
}
.plots-preview-column-labels > div,
.plots-preview-row-labels > div {
  width: 29px;
  height: 29px;
  color: #9e9e9e;
  line-height: 29px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.plots-preview-column-labels {
  display: flex;
}
.plots-preview-row-labels {
  padding-top: 29px;
}
.plots-preview-row {
  display: flex;
}
.plots-preview-row > div {
  width: 29px;
  height: 29px;
}
.plots-preview-empty {
  background-color: white;
}
.plots-top {
  border-top: 2px solid white;
}
.plots-bottom {
  border-bottom: 2px solid white;
}
.plots-left {
  border-left: 2px solid white;
}
.plots-right {
  border-right: 2px solid white;
}
.preview-table-wrapper {
  max-height: 500px;
  overflow: scroll;
}
.table-wrapper {
  display: flex;
}
.table-wrapper .zoom-wrapper {
  display: flex;
  width: 100%;
}
.edit-grid-container {
  display: flex;
  justify-content: flex-start;
}
.dummy-container {
  width: 355px;
}
.remove-area-target {
  max-height: 150px;
  overflow: auto;
}
#preview-layout-table {
  table-layout: fixed;
  width: 100%;
}
#preview-layout-table tbody > tr > th {
  width: 25px;
}
#preview-layout-table td,
#preview-layout-table th {
  padding: 0px;
  text-align: center;
}
#preview-layout-table thead > tr > th:first-child {
  width: 2rem;
}
@-moz-document url-prefix() {
  #preview-layout-table > tbody > tr {
    height: 100% !important;
  }
  #preview-layout-table > tbody > tr > td {
    height: 100% !important;
  }
  #preview-layout-table > tbody > tr > th {
    height: 100% !important;
  }
}
.preview-plot-not-planted {
  border: 1px dotted #ccc;
  height: 100%;
}
.preview-plot-widget {
  height: 100%;
  overflow-wrap: anywhere;
  text-align: center;
}
.preview-drag-container {
  height: 1px;
}
.pl1 {
  padding-left: 1px !important;
}
.pl2 {
  padding-left: 2px !important;
}
.pl3 {
  padding-left: 3px !important;
}
.pr1 {
  padding-right: 1px !important;
}
.pr2 {
  padding-right: 2px !important;
}
.pr3 {
  padding-right: 3px !important;
}
.pt1 {
  padding-top: 1px !important;
}
.pt2 {
  padding-top: 2px !important;
}
.pt3 {
  padding-top: 3px !important;
}
.dashed-border {
  border: 1px dashed white;
}
.bold-bottom-border {
  border-bottom: 3px solid #757575;
}
.variety-stage-change-chart > .popup-content {
  max-width: 900px;
}
qt-popup.variety-stage-change-chart .popup-content > .popup-content-inner {
  padding-top: 0;
}
qt-popup.variety-stage-change-chart .popup-content .popup-buttons {
  margin-top: 0;
}
qt-popup.select-plot > .popup-content {
  display: flex;
}
qt-popup.select-plot > .popup-content > .popup-content-inner {
  flex: 1;
  display: flex;
  flex-direction: column;
}
qt-popup.select-plot > .popup-content > .popup-content-inner > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.select-plot {
  display: flex;
  flex-direction: column;
}
.select-plot-empty {
  font-style: italic;
}
.select-plot-table-container {
  flex: 1;
  overflow-y: auto;
}
.select-plot-table-container table {
  border-collapse: separate;
  border-radius: 0;
}
.select-plot-table-container th {
  font-weight: normal;
  color: #283593;
  cursor: pointer;
  border: #283593 solid 1px;
  border-right: none;
}
.select-plot-table-container thead th:last-child {
  border-right: #283593 solid 1px;
}
.select-plot-table-container thead > tr {
  border-bottom: #283593 solid 1px !important;
}
.select-plot-table-container tbody > tr {
  border-bottom: 1px solid #d0d0d0;
}
.select-plot-table-container tbody > tr:hover {
  background-color: #eee;
  cursor: pointer;
}
.select-plot-cancel {
  display: block;
  margin-top: 2em;
}
.sorting-number {
  position: absolute;
  margin-left: 0.4rem;
  background-color: #283593;
  color: white;
  width: 12px;
  text-align: center;
  border-radius: 15px;
  font-size: 8px;
  bottom: 56%;
}
.sorting-icon {
  vertical-align: middle;
  position: absolute;
}
.multilevel-header {
  padding: 5px;
  position: relative;
  text-align: center;
  min-width: 75px;
  height: 2.2rem;
}
.assign-staff-table {
  margin: 1em 0 2em;
}
.assign-staff-table > thead > tr > th,
.assign-staff-table > tbody > tr > td {
  color: inherit;
  border: 1px solid #d0d0d0;
  border-radius: 0;
  vertical-align: baseline;
}
.trial-sites-import .import-facts.card {
  box-shadow: none;
}
.trial-sites-import .import-facts.card > .card-content {
  padding: 0;
}
qt-popup.trial-sites-import .popup-content .popup-buttons secondary-button {
  margin-left: 0;
}
.trial-sites-import-loading {
  pointer-events: none;
  opacity: 0.5;
}
.measurement-library-trials-title-container {
  display: flex;
  justify-content: space-between;
  justify-items: center;
}
.measurement-library-trials-title-container > i {
  color: #9e9e9e;
  cursor: pointer;
}
.measurement-library-trials-title {
  color: #283593;
  font-size: 1.2em;
}
.measurement-library-trials-loading {
  margin-top: 2em;
}
.measurement-library-trials-no-trials {
  display: block;
  margin-top: 2em;
}
.trial-document-edit-file-container {
  margin: 1em 0;
}
.trial-document-edit-file-title {
  display: flex;
  color: #9e9e9e;
}
.trial-document-edit-file-title > i {
  margin-right: 5px;
}
.trial-document-edit-file-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.trial-document-edit-file-input > file-upload {
  flex: 1;
  margin-right: 1em;
}
.file-type-pic {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjIwcHgiIGhlaWdodD0iMjBweCIgdmlld0JveD0iMCAwIDIwIDIwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyMCAyMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZmlsbD0iIzcyNjY1OCIgZD0iTTIwLDE4LjA3NUMyMCwxOC45NTMsMTkuMTE1LDIwLDE4LjIzMSwyMGgtMTYuOEMwLjU0OCwyMCwwLDE4Ljk1MywwLDE4LjA3NVYxLjM4MkMwLDAuNTA0LDAuNTQ4LDAsMS40MzIsMAloMTYuOEMxOS4xMTUsMCwyMCwwLjUwNCwyMCwxLjM4MlYxOC4wNzV6Ii8+PHBvbHlnb24gZmlsbD0iI0ZGRkZGRiIgcG9pbnRzPSIzLDE2IDYuNjg1LDExLjM5NCA5LjQ0OCwxMy44NTEgMTMuNzQ3LDkuODU4IDE3LjEyNSwxNiAiLz48L3N2Zz4=');
}
.file-type-pdf {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjIwcHgiIGhlaWdodD0iMjBweCIgdmlld0JveD0iMCAwIDIwIDIwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyMCAyMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZmlsbD0iI0U5NTE0QSIgZD0iTTIwLDE4LjA3NUMyMCwxOC45NTMsMTkuMTE1LDIwLDE4LjIzMSwyMGgtMTYuOEMwLjU0OCwyMCwwLDE4Ljk1MywwLDE4LjA3NVYxLjM4MkMwLDAuNTA0LDAuNTQ4LDAsMS40MzIsMCBoMTYuOEMxOS4xMTUsMCwyMCwwLjUwNCwyMCwxLjM4MlYxOC4wNzV6Ii8+PHRleHQgdHJhbnNmb3JtPSJtYXRyaXgoMSAwIDAgMSAzLjMzMTUgMTIuNzY2MSkiIGZpbGw9IiNGRkZGRkYiIGZvbnQtZmFtaWx5PSInQXJpYWxNVCciIGZvbnQtc2l6ZT0iNyI+UERGPC90ZXh0Pjwvc3ZnPg==');
}
.file-type-excel {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjIwcHgiIGhlaWdodD0iMjBweCIgdmlld0JveD0iMCAwIDIwIDIwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyMCAyMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZmlsbD0iIzI0Nzg0NSIgZD0iTTIwLDE4LjA3NUMyMCwxOC45NTMsMTkuMTE1LDIwLDE4LjIzMSwyMGgtMTYuOEMwLjU0OCwyMCwwLDE4Ljk1MywwLDE4LjA3NVYxLjM4MkMwLDAuNTA0LDAuNTQ4LDAsMS40MzIsMCBoMTYuOEMxOS4xMTUsMCwyMCwwLjUwNCwyMCwxLjM4MlYxOC4wNzV6Ii8+PHRleHQgdHJhbnNmb3JtPSJtYXRyaXgoMS4wMDY0IDAgMCAxIDQuMDIyOSAxNi42NDIxKSIgZmlsbD0iI0ZGRkZGRiIgZm9udC1mYW1pbHk9IidBcmlhbE1UJyIgZm9udC1zaXplPSIxNi43MzUiPlg8L3RleHQ+PC9zdmc+');
}
.file-type-word {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjIwcHgiIGhlaWdodD0iMjBweCIgdmlld0JveD0iMCAwIDIwIDIwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyMCAyMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZmlsbD0iIzFENzVCRCIgZD0iTTIwLDE4LjM0N0MyMCwxOS4yMjUsMTkuMjg0LDIwLDE4LjQsMjBIMS42QzAuNzE2LDIwLDAsMTkuMjI1LDAsMTguMzQ3VjEuNjUzQzAsMC43NzUsMC43MTYsMCwxLjYsMGgxNi44IEMxOS4yODQsMCwyMCwwLjc3NSwyMCwxLjY1M1YxOC4zNDd6Ii8+PHRleHQgdHJhbnNmb3JtPSJtYXRyaXgoMS4wMDY0IDAgMCAxIDIuODU1IDE2LjM2OTEpIiBmaWxsPSIjRkZGRkZGIiBmb250LWZhbWlseT0iJ015cmlhZFByby1SZWd1bGFyJyIgZm9udC1zaXplPSIxNi43MzUiPlc8L3RleHQ+PC9zdmc+');
}
.file-type-powerpoint {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjIwcHgiIGhlaWdodD0iMjBweCIgdmlld0JveD0iMCAwIDIwIDIwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyMCAyMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZmlsbD0iI0E1M0UyMyIgZD0iTTIwLDE4LjM0N0MyMCwxOS4yMjUsMTkuMjgzLDIwLDE4LjQsMjBIMS42QzAuNzE2LDIwLDAsMTkuMjI1LDAsMTguMzQ3VjEuNjUzQzAsMC43NzUsMC43MTYsMCwxLjYsMGgxNi44IEMxOS4yODMsMCwyMCwwLjc3NSwyMCwxLjY1M1YxOC4zNDd6Ii8+PHRleHQgdHJhbnNmb3JtPSJtYXRyaXgoMS4wMDY0IDAgMCAxIDQuODU2NCAxNS4zNjg3KSIgZmlsbD0iI0ZGRkZGRiIgZm9udC1mYW1pbHk9IidNeXJpYWRQcm8tUmVndWxhciciIGZvbnQtc2l6ZT0iMTYuNzM1Ij5QPC90ZXh0Pjwvc3ZnPg==');
}
.file-type-other {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjIwcHgiIGhlaWdodD0iMjBweCIgdmlld0JveD0iMCAwIDIwIDIwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyMCAyMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBhdGggZmlsbD0iI0ZFRDUzNiIgZD0iTTIwLDE4LjRjMCwwLjg4NC0wLjcxNiwxLjYtMS42LDEuNkgxLjZDMC43MTYsMjAsMCwxOS4yODQsMCwxOC40VjEuNkMwLDAuNzE2LDAuNzE2LDAsMS42LDBIMTguNCBDMTkuMjg0LDAsMjAsMC43MTYsMjAsMS42VjE4LjR6Ii8+PGxpbmUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjRTdCOTM0IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHgxPSI0IiB5MT0iOS41IiB4Mj0iMTYiIHkyPSI5LjUiLz48bGluZSBmaWxsPSJub25lIiBzdHJva2U9IiNFN0I5MzQiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgeDE9IjQiIHkxPSI0LjUiIHgyPSIxNiIgeTI9IjQuNSIvPjxsaW5lIGZpbGw9Im5vbmUiIHN0cm9rZT0iI0U3QjkzNCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiB4MT0iNCIgeTE9IjEzLjUiIHgyPSIxMiIgeTI9IjEzLjUiLz48L2c+PC9zdmc+');
}
.file-type-icon {
  width: 20px;
  height: 20px;
}
.change-password {
  padding-top: 1em;
}
.change-password .email-password-sign-in {
  margin-top: 4em;
}
.bdr-edit-days-line {
  display: flex;
  align-items: baseline;
}
.bdr-edit-days-line > :first-child {
  width: 120px;
}
.bdr-edit-days-line > span {
  margin: 0 1em;
}
.bdr-edit-days-line > :last-child {
  flex: 1;
}
.data-entry-edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
flat-button.cancel-data-entry > span {
  color: #9e9e9e;
  margin-right: 0;
}
.change-data-language-container {
  margin: 0 0 0.5rem 0;
  padding: 0.2rem 1rem 0;
}
.change-data-language-search-container > .form-autocomplete-select {
  margin-bottom: 0;
}
.data-entry-bar {
  height: 70px;
  background-color: #eaeaea;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 1em;
}
.data-entry-bar > div {
  display: flex;
  align-items: center;
}
.data-entry-bar input[type='text'] {
  margin: 0;
  height: 2rem;
  width: 400px;
}
.data-entry-bar .select-wrapper input.select-dropdown {
  margin: 0;
  height: 2rem;
  padding-right: 10px;
  width: 390px;
}
.data-entry-bar .input-field .select-wrapper + label {
  top: -12px;
}
.data-entry-bar textarea {
  height: 2rem;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  resize: none;
  margin-top: 4px;
  width: 400px;
}
.data-entry-bar textarea:focus {
  outline: none;
  border-bottom: 2px solid #283593;
}
.data-entry-bar textarea:focus + label {
  color: #283593;
}
.data-entry-bar .input-field {
  margin-top: 0;
}
.data-entry-bar .input-field label {
  top: 0.2rem;
}
.data-entry-bar .input-field label.active {
  transform: translateY(-85%);
}
.data-entry-bar flat-button > span.action {
  line-height: 2rem;
}
.data-entry-bar file-upload {
  display: flex;
  align-items: center;
}
.data-entry-bar file-upload .progress {
  width: 50px;
  margin-right: 1em;
}
.data-entry-bar file-upload .file-upload-img {
  max-height: 60px;
}
.data-entry-bar file-upload .file-upload-vid {
  max-height: 60px;
  cursor: pointer;
  color: #283593;
}
.data-entry-bar file-upload img {
  cursor: pointer;
}
.data-entry-bar-contains-error {
  border: 1px solid #f44336;
}
.data-entry-bar-no-edit {
  color: #606060;
}
.data-entry-bar-error {
  color: #f44336;
  margin-left: 1em;
}
.data-entry-bar-loading-extra {
  color: #9e9e9e;
}
.data-entry-bar-pictures {
  display: flex;
  margin-right: 20px;
}
.data-entry-bar-pictures > div {
  width: 32px;
  height: 32px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.data-entry-bar-pictures > div:not(:last-child) {
  margin-right: 4px;
}
.data-entry-bar-multi-pictures {
  display: flex;
  align-items: center;
}
.data-entry-bar-multi-pictures > .picture {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 4px;
}
.data-entry-bar-more-pictures {
  background-color: #414141;
  color: white;
  border-radius: 2px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-entry-edit-container,
.data-entry-edit-container > .uncontainer {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
}
.data-entry-edit-container .overview-table-container {
  flex: 1;
  min-height: 0;
  overflow: auto;
  overflow-anchor: none;
}
.data-entry-edit-container .overview-table-container input,
.data-entry-edit-container .overview-table-container input[type='text']:focus:not([readonly]) {
  margin: 0;
  border: 2px solid #283593;
  height: 100%;
  min-width: 0;
  line-height: 100%;
  box-sizing: border-box;
  text-align: center;
  background-color: white;
  box-shadow: 0 2px 7px 0 rgba(84, 84, 84, 0.5);
}
.data-entry-edit-container .overview-table-container td.inline-validation-error {
  background-color: #f44336;
}
.data-entry-edit-container .overview-table-container td.inline-validation-error > div {
  color: white;
}
.data-entry-edit-container .overview-table-container td.inline-validation-error > input,
.data-entry-edit-container .overview-table-container td.inline-validation-error > input[type='text']:focus:not([readonly]) {
  border-color: #f44336;
}
.data-entry-edit-pictures-title {
  font-size: 25px;
  color: #283593;
  margin-bottom: 0.5em;
}
.data-entry-edit-pictures-subtitle {
  color: #9e9e9e;
  font-weight: bold;
}
.select-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 400px;
}
.language-select {
  max-width: 184px;
}
.apply-language-btn {
  margin-top: 18%;
  margin-left: 15px;
}
.data-entry-edit-pictures-item {
  display: flex;
  align-items: flex-end;
  margin-top: 1em;
}
.data-entry-edit-pictures-item form-textarea {
  flex: 1;
}
.data-entry-edit-pictures-item textarea.materialize-textarea {
  margin: 0;
  min-height: 1.5rem;
  max-height: 214px;
  height: 1.5rem;
  padding: 0.8rem 0 0.5rem 0;
}
.data-entry-edit-pictures-file-container {
  position: relative;
  margin-right: 1em;
}
.data-entry-edit-pictures-file-container > img {
  width: 250px;
  height: 250px;
  object-fit: cover;
  cursor: pointer;
}
.data-entry-edit-pictures-file-container > .data-entry-edit-pictures-file {
  width: 250px;
}
.data-entry-edit-pictures-file-container > .data-entry-edit-pictures-file > a {
  color: #283593;
  display: inline-flex;
  align-items: center;
}
.data-entry-edit-pictures-file-container > .data-entry-edit-pictures-file > a > i {
  margin-right: 10px;
}
.data-entry-edit-pictures-menu-btn {
  position: absolute;
  cursor: pointer;
  right: 8px;
  top: 0;
}
.data-entry-edit-pictures-menu-btn.data-entry-edit-pictures-menu-btn-img {
  top: 8px;
  color: white;
}
.data-entry-edit-pictures-menu {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  overflow: hidden;
  font-weight: bold;
}
.data-entry-edit-pictures-menu > *:not(:first-child) {
  border-top: 1px solid #d0d0d0;
}
.data-entry-edit-pictures-menu > *:hover {
  background-color: #eee;
}
.data-entry-edit-pictures-menu .file-field,
.data-entry-edit-pictures-menu .data-entry-edit-pictures-delete {
  padding: 0.5em 2em;
}
.data-entry-edit-pictures-upload {
  display: block;
  margin-top: 2em;
}
forms-stack form.card .card-action.data-entry-edit-pictures-form-buttons {
  padding: 20px 0 0;
}
.trait-detail-field {
  margin-bottom: 0.5em;
}
.trait-detail-field > :first-child {
  font-weight: 300;
  color: #283593;
}
.trait-detail-field > ul {
  margin: 0;
}
.trait-detail-dimensions {
  font-style: italic;
}
.trait-detail-picture-container {
  display: flex;
}
.trait-detail-picture-container > :first-child {
  margin-right: 10px;
}
.trait-detail-picture {
  width: 128px;
  height: 128px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.observation-history-container {
  position: relative;
  padding-top: 20px;
}
.observation-history-container a {
  color: #689f38;
}
.observation-history-container > .popup-buttons {
  position: sticky;
  bottom: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: white;
}
.extra-picture-row img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 0.2rem;
}
.extra-picture-row-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.2rem;
}
.extra-picture-row-even,
.extra-picture-row-odd {
  transition: background 0.2s ease-in-out;
  -webkit-transition: background 0.2s ease-in-out;
}
.extra-picture-row-even:hover {
  background-color: #e6e6e6;
}
.extra-picture-row-odd:hover {
  background-color: #fafafa;
}
.w-150 {
  width: 150px;
}
.w-300 {
  width: 300px;
}
.edit-history-switch {
  display: flex;
  flex-direction: row;
  font-weight: 500;
  margin: 30px 0px;
  cursor: pointer;
}
.edit-history-switch div {
  padding: 10px 80px;
  border: 1px solid #74796d;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 187px;
}
.edit-history-switch div i {
  display: none;
}
.edit-history-switch .active {
  background-color: #dae7c9;
  cursor: default;
}
.edit-history-switch .active i {
  display: inline-block;
}
.edit-history-switch .disabled {
  cursor: default;
  border-color: #ccc;
  color: #ccc;
}
.edit-history-switch div:first-child {
  border-radius: 100px 0px 0px 100px;
}
.edit-history-switch div:last-child {
  border-left: 0px;
  border-radius: 0px 100px 100px 0px;
}
.edit-history-switch > div::first-child {
  border: 1px solid #bebebe;
}
.extra-picture-comment {
  flex: 1;
  width: 100%;
}
.fact-plot span {
  color: #000000bd;
  font-weight: bold;
}
.fact-description > span:first-child {
  color: #000000bd;
  font-weight: bold;
}
.extra-picture-row-container > a {
  display: inline-block;
  max-width: 100px;
  max-height: 100px;
  overflow: hidden;
}
.observation-history-container > table > thead tr {
  border-bottom: 1px solid #bebebe;
}
.observation-history-container > table > thead tr th {
  position: sticky;
  top: 0px;
  border-bottom-color: transparent;
  background-color: white;
  color: #000000bd;
}
.observation-history-container > table > tbody > tr {
  border-bottom: 1px solid #bebebe;
}
.observation-history-container > table > tbody > tr td {
  color: #000000bd;
  vertical-align: top;
  padding: 8px;
}
.edit-history-menu-button {
  cursor: pointer;
}
.edit-fact-action-bar {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}
.edit-fact-action-bar .edit-fact-action-buttons-container {
  display: flex;
  flex-direction: row;
}
.edit-fact-action-bar .edit-fact-action-buttons-container .edit-fact-action-bar-navigate-button {
  border: 2px solid #f3f3f3;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  margin-left: 30px;
}
.edit-fact-action-bar .edit-fact-action-buttons-container .edit-fact-action-bar-navigate-button:hover {
  cursor: pointer;
  background-color: #f6f6f6;
}
.edit-fact-action-bar .edit-fact-action-buttons-container .edit-fact-action-bar-navigate-disabled-button {
  background-color: #f3f3f3;
}
.edit-fact-action-bar .edit-fact-action-buttons-container .edit-fact-action-bar-navigate-disabled-button:hover {
  cursor: default;
  background-color: #f3f3f3;
}
.edit-fact-action-bar .edit-fact-action-buttons-container .edit-fact-action-bar-navigate-disabled-button i {
  color: #ccc;
}
.edit-history-menu {
  position: absolute;
  right: 8px;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 4px 0px;
  overflow: hidden;
  z-index: 99;
}
.edit-history-menu > *:not(:first-child) {
  border-top: 1px solid #d0d0d0;
}
.edit-history-menu > *:hover {
  padding: 4px 4px;
  background-color: #eee;
}
.edit-history-menu .edit-history-menu-restore {
  padding: 0.5em 2em;
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.edit-history-menu .disabled {
  color: #ccc;
}
.edit-history-menu .disabled:hover {
  background-color: white;
}
.extra-file-name {
  max-width: 100px;
}
.select-container {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.observation-history-list {
  margin: 1em 0;
}
.observation-history-list > div {
  margin: 0.5em 0;
}
.extra-picture-file a {
  display: inline-flex;
  align-items: center;
}
.observation-history-validation-error {
  margin-top: 1em;
}
.observation-history-container > .popup-buttons > secondary-button {
  margin-left: 0 !important;
}
.history-gps-container {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}
.history-gps-container a {
  display: contents;
}
.history-gps-container > .material-icons {
  top: 0;
}
.qr-code-scanner .video-container {
  display: none;
}
.qr-code-scanner #second-camera {
  height: 400px;
  width: 400px;
  margin: auto;
  padding: 10px;
}
.qr-code-scanner .buttons {
  padding-top: 25px;
}
.release-notes .list {
  height: calc(100vh - 200px);
  overflow: auto;
}
.release-notes .item {
  min-height: 180px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 25px;
  position: relative;
}
.release-notes .item .timestamp {
  color: rgba(0, 0, 0, 0.74);
  letter-spacing: 0.5px;
}
.release-notes .item .title {
  font-size: 24px;
  letter-spacing: 0.18px;
}
.release-notes .item .content {
  font-size: 16px;
  letter-spacing: 0.5px;
}
.release-notes .item .content ul,
.release-notes .item .content li {
  list-style-type: inherit !important;
}
.release-notes .item .icon {
  top: -10px;
  position: absolute;
  padding: 25px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.release-notes .item .icon span.material-icons {
  font-size: xxx-large;
}
.release-notes .bottom-loading {
  display: flex;
  justify-content: center;
}
html[dir='ltr'] .icon {
  right: 10px;
}
html[dir='rtl'] .icon {
  left: 10px;
}
.hidden-container {
  display: none;
}
qt-popup.change-reason-dialog div.popup-content {
  overflow: visible;
}
.change-reason-container > div.header {
  color: #689f38;
  font-size: 2rem;
  margin-bottom: 1rem;
}
.progress-bar {
  width: 99%;
  height: 8px;
  background-color: #F0EDED;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
}
.progress-bar-progress {
  height: 100%;
  border-radius: 4px;
  background-color: #99FF3F;
}
.bulk-actions-panel {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 3rem;
  margin: 1rem 0;
}
.select-wrapper input.select-dropdown ~ .bulk-actions-panel {
  margin-top: 0;
}
.bulk-actions-panel > span {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.bulk-actions-panel .actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.bulk-actions-panel .actions flat-button span {
  display: flex;
  align-items: center;
}
.bulk-remove-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  height: 100%;
}
.bulk-remove-action button {
  padding-bottom: 0;
  height: 100%;
  display: inline-flex;
  align-items: center;
}
.bulk-remove-action span.action i {
  padding: 0.1rem;
}
.bulk-remove-action span.action:hover i {
  background-color: #f1f0f1;
  color: #414141;
  border-radius: 50%;
}
button.generate-treatments-automatically {
  padding: 0 1rem;
}
button.add-treatments {
  width: 11.25rem;
}
.treatments-table-dragging {
  border: 2px solid #d0d0d0;
  border-radius: 5px;
  background-color: #eee;
  width: fit-content !important;
  height: fit-content !important;
}
table.trial-treatments th flat-button > span,
table.trial-treatments td flat-button > span {
  margin-right: 0;
}
table.trial-treatments td {
  vertical-align: top;
}
table.trial-treatments td .checkbox-container {
  padding: 0;
}
table.trial-treatments td.drag-indicator-cell {
  width: 1rem;
}
table.trial-treatments td.drag-indicator-cell .treatments-drag-indicator-handle {
  cursor: grab;
}
table.trial-treatments td.drag-indicator-cell .drag-indicator-handle-disabled {
  cursor: not-allowed !important;
}
table.trial-treatments tr {
  border-bottom: 1px solid #d0d0d0;
}
.ft-field-line-grid {
  display: flex;
  align-items: baseline;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin-top: 1rem;
}
.ft-field-line-grid > * {
  flex: 1;
}
.ft-field-line-grid > *:not(:last-child) {
  margin-right: 2em;
}
.ft-field-line-grid > *.trial-name-prefix {
  margin-right: 0;
  flex: initial;
}
.ft-field-line-grid.ft-field-line-center {
  align-items: center;
}
.treatment-factor-attributes summary {
  display: none;
}
.treatments-toggle-buttons {
  display: flex;
  justify-content: flex-end;
}
.trial-treatments flat-button > span > i {
  color: #000000BD;
}
.expand-collapse-header {
  width: 2rem;
}
.report-container {
  margin-top: 0.5rem;
}
.report-container > .title-container > .title {
  font-size: 1.8rem;
  color: #689f38;
}
.report-container > .title-container > .delete-button {
  cursor: pointer;
  color: #689f38;
}
.report-container > .description {
  font-size: 1.2rem;
  color: #9e9e9e;
}
.report-container > .title-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.no-dashboards-message {
  font-size: 1.2rem;
  color: #9e9e9e;
}
.more-dropdown-menu-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
}
.more-dropdown-menu {
  position: absolute;
  right: 8px;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 4px 0px;
  overflow: hidden;
  z-index: 99;
}
.more-dropdown-menu > *:not(:first-child) {
  border-top: 1px solid #d0d0d0;
}
.more-dropdown-menu > *:hover {
  padding: 4px 4px;
  background-color: #eee;
}
.more-dropdown-menu .more-dropdown-menu-item {
  padding: 0.5em 2em;
}
.more-dropdown-menu .more-dropdown-menu-item-disabled {
  color: #ccc;
}
.more-dropdown-menu .more-dropdown-menu-item-disabled:hover {
  cursor: not-allowed;
  background-color: white;
}
.more-dropdown-menu .disabled {
  color: #ccc;
}
.more-dropdown-menu .disabled:hover {
  background-color: white;
}
.edit-activatable-list-dialog .dialog-subtitle {
  margin: 1.5rem 0;
}
.edit-activatable-list-dialog .item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  max-height: 48px;
  min-height: fit-content;
  padding: 0 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.edit-activatable-list-dialog .item:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.edit-activatable-list-dialog .item .switch label input[type='checkbox']:checked + .lever {
  background-color: rgba(104, 159, 56, 0.38);
}
.edit-activatable-list-dialog .item .switch span.lever {
  background-color: rgba(0, 0, 0, 0.12);
}
.edit-activatable-list-dialog button {
  width: 10rem;
  min-width: fit-content;
  max-width: 160px;
}
.edit-activatable-list-dialog secondary-button {
  margin-left: 0 !important;
  margin-right: 1rem;
}
.edit-activatable-list-dialog button.primary {
  background-color: #283593;
}
.edit-activatable-list-dialog button.primary:hover,
.edit-activatable-list-dialog button.primary:focus {
  background-color: #1d276b;
}
.treatment-edit-form {
  display: flex;
  flex-direction: column;
}
.treatment-edit-form > *:not(:nth-last-child(-n + 2)) {
  margin-bottom: 1rem;
}
.body-1 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.body-4 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
}
h5 {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.18px;
  text-align: left;
}
.email-verification {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 72px auto;
  align-items: center;
}
.email-verification .verification-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 18px;
  margin-top: 24px;
}
.email-verification .verification-success h5 {
  color: #283593;
}
.email-verification .verification-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 24px;
}
.email-verification a {
  color: #283593;
}
